import React, { useState,useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { db as FIREBASE_DB } from '../pages/firebase';
import { doc, setDoc, updateDoc, arrayUnion } from 'firebase/firestore';
const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    async function logVisit() {
      const today = format(new Date(), 'yyyy-MM-dd'); // Format date as 'YYYY-MM-DD'
      const visitDocRef = doc(FIREBASE_DB, 'visits', today);
      console.log("logging visit")
      // Check local storage for a visitor ID
      let visitorId = localStorage.getItem('visitorId');
      
      // If no visitor ID exists, generate one and store it
      if (!visitorId) {
        visitorId = uuidv4();
        localStorage.setItem('visitorId', visitorId);
      }
    
      try {
        // Try to update the document
        await updateDoc(visitDocRef, {
          users: arrayUnion(visitorId),
        });
      } catch (error) {
        //@ts-ignore
        if (error.code === 'not-found') {
          // If the document doesn't exist, create it
          await setDoc(visitDocRef, {
            date: today,
            users: [visitorId],
          });
        } else {
          console.error('Error logging visit:', error);
        }
      }
    }
    logVisit();
  }, []);

 

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 md:px-10 lg:px-16 bg-black text-gray-300 text-white z-50'>
      <div className='flex items-center'>
        <img src={Logo} alt="Logo" className="w-14" />
        <h1 className="text-xs sm:text-sm lg:text-lg font-bold ml-3 tracking-widest">
          Amarabelliana's Gowns and Events Stylist
        </h1>
      </div>

      {/* Menu */}
      <ul className='hidden md:flex'>
        <li className='mr-4 hover:text-[#ffd000]'>
          {isHomePage ? (
            <ScrollLink to="home" smooth={true} duration={500}>
              <Link to="/">Home</Link>
            </ScrollLink>
          ) : (
            <Link to="/">Home</Link>
          )}
        </li>
        <li className='mr-4 hover:text-[#ffd000]'>
          <Link to='/collections'>Collections</Link>
        </li>
        <li className='mr-4 hover:text-[#ffd000]'>
          {isHomePage ? (
            <ScrollLink to="about" smooth={true} duration={500}>
              <Link to="/">About</Link>
            </ScrollLink>
          ) : (
            <Link to="/?scrollTo=about">About</Link>
          )}
        </li>
        <li className='mr-4 hover:text-[#ffd000]'>
          {isHomePage ? (
            <ScrollLink to="contact" smooth={true} duration={500}>
              <Link to="/">Contact</Link>
            </ScrollLink>
          ) : (
            <Link to="/?scrollTo=contact">Contact</Link>
          )}
        </li>
        <li className='hover:text-[#ffd000]'>
          {isHomePage ? (
            <ScrollLink to="faqs" smooth={true} duration={500}>
              <Link to="/">FAQs</Link>
            </ScrollLink>
          ) : (
            <Link to="/?scrollTo=faqs">FAQs</Link>
          )}
        </li>
      </ul>

      {/* Hamburger Menu */}
      <div onClick={handleClick} className='md:hidden z-10 cursor-pointer' aria-label="Toggle navigation">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menu */}
      <div className={`absolute top-0 left-0 w-full h-screen bg-black flex flex-col justify-center items-center text-white transition-transform duration-300 ease-in-out ${nav ? 'translate-y-0' : '-translate-y-full'}`}>
        <ul>
          <li className='py-6 text-xl'>
            {isHomePage ? (
              <ScrollLink onClick={handleClick} to="home" smooth={true} duration={500}>
                <Link to="/">Home</Link>
              </ScrollLink>
            ) : (
              <Link to="/" onClick={handleClick}>Home</Link>
            )}
          </li>
          <li className='py-6 text-xl'>
            <Link to='/collections' onClick={handleClick}>Collections</Link>
          </li>
          <li className='py-6 text-xl'>
            {isHomePage ? (
              <ScrollLink onClick={handleClick} to="about" smooth={true} duration={500}>
                <Link to="/">About</Link>
              </ScrollLink>
            ) : (
              <Link to="/?scrollTo=about" onClick={handleClick}>About</Link>
            )}
          </li>
          <li className='py-6 text-xl'>
            {isHomePage ? (
              <ScrollLink onClick={handleClick} to="contact" smooth={true} duration={500}>
                <Link to="/">Contact</Link>
              </ScrollLink>
            ) : (
              <Link to="/?scrollTo=contact" onClick={handleClick}>Contact</Link>
            )}
          </li>
          <li className='py-6 text-xl'>
            {isHomePage ? (
              <ScrollLink onClick={handleClick} to="faqs" smooth={true} duration={500}>
                <Link to="/">FAQs</Link>
              </ScrollLink>
            ) : (
              <Link to="/?scrollTo=faqs" onClick={handleClick}>FAQs</Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
