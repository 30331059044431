import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from '../pages/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import logo from '../assets/logo.png';
import gmailIcon from '../assets/gmail.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ForgotPassword from './ForgotPassword';

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [, setLoading] = useState(false);
  const [showResetEmail, setShowResetEmail] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      const role = userData.role;

      // Redirect based on user role
      switch (role) {
        case 'user':
          window.location.href = '/user-dashboard';
          break;
        case 'staff':
          window.location.href = '/staff-dashboard';
          break;
        case 'admin':
          window.location.href = '/admin-dashboard';
          break;
        case 'super-admin':
          window.location.href = '/super-admin-dashboard';
          break;
        default:
          setError('This account role is not authorized.');
      }
    } catch (error) {
      setError('Failed to log in. Please check your credentials and try again.');
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  };

 // In your LoginModal component...

const handleGoogleSignUp = async () => {
  setLoading(true);
  setError('');

  try {
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(auth, provider);
    const user = userCredential.user;

    const userDoc = await getDoc(doc(db, 'users', user.uid));

    if (!userDoc.exists()) {
      await setDoc(doc(db, 'users', user.uid), {
        role: 'user',
        email: user.email,
        displayName: user.displayName,
        profilePicture: user.photoURL || '',
        firstName: "", 
        lastName: "", 
        middleName: "", 
        phoneNumber: "", 
        idDocument: "https://firebasestorage.googleapis.com/v0/b/authentication-88168.appspot.com/o/user_ids%2FpsQlnlHxemRkdgQpi1p1gLkUJ6G3?alt=media&token=94c0be00-3736-444f-b10b-a44353c5e8dd", 
        idImageUrl: "", 
        status: "pending",
        suffix: "", 
      });

      setError('Your account is currently pending approval. Please submit a valid ID for verification in your profile.');
    }

    const updatedUserDoc = await getDoc(doc(db, 'users', user.uid));
    const role = updatedUserDoc.data().role;

    if (role === 'user') {
      window.location.href = '/user-dashboard';
    } else if (role === 'staff') {
      window.location.href = '/staff-dashboard';
    } else if (role === 'admin') {
      window.location.href = '/admin-dashboard';
    } else if (role === 'super-admin') {
      window.location.href = '/super-admin-dashboard';
    } else {
      setError('This account cannot log in via Google sign-up.');
    }
  } catch (error) {
    setError('Failed to sign up with Google. Please try again.');
    console.error('Error signing up with Google:', error);
  } finally {
    setLoading(false);
  }
};


  if (!isOpen) return null;

  return (
    <>
      {showResetEmail ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
          <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
            <ForgotPassword onClose={() => setShowResetEmail(false)} />
            <div className="mt-4 text-center">
              <button onClick={() => setShowResetEmail(false)} className="text-gray-600 hover:underline">
                <FontAwesomeIcon icon={faTimes} className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
          <div className={`bg-white p-8 rounded-lg shadow-md w-full max-w-md sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl transform transition-transform duration-1000 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} mx-4`}>
            <div className="flex justify-center mb-6">
              <img src={logo} alt="Logo" className="w-24 md:w-32" />
            </div>
            <h2 className="text-xl md:text-2xl font-bold text-center mb-6">Login</h2>

            <form className="space-y-4 -ml-[1px]">
              <div className="mb-4">
                <input
                  type="email"
                  placeholder="Email"
                  className="text-sm md:text-base shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  placeholder="Password"
                  className="text-sm md:text-base shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="flex justify-end">
                <Link
                  type="button"
                  onClick={() => setShowResetEmail(true)}
                  className="text-xs md:text-sm text-gray-400 hover:text-gray-600 font-medium hover:underline"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4">
                <button
                  type="button"
                  onClick={handleLogin}
                  className="bg-white hover:bg-[#ffd000] border-solid border-black border-[1px] hover:border-[#bda330] text-sm md:text-base text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                 Login
                </button>
              </div>
              <button
                type="button"
                onClick={handleGoogleSignUp}
                className="bg-white border-solid border-[1px] hover:border-blue-700 border-black hover:bg-blue-700 text-black hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center mb-4 w-full"
              >
                <img src={gmailIcon} alt="Gmail Icon" className="w-5 md:w-6 h-5 md:h-6 mr-2" />
                Continue with Google
              </button>

              {error && <p className="text-red-500 text-center">{error}</p>}
            </form>

            <div className="mt-4 text-center">
              <button onClick={onClose} className="text-gray-600 hover:underline">
                <FontAwesomeIcon icon={faTimes} className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginModal;
