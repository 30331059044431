import React, { useRef, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import './Collections.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useEffect } from 'react';
import { collection, onSnapshot,getDocs } from 'firebase/firestore';
import { db } from './firebase';

const Collections = () => {
  const ballGownsRef = useRef(null);
  const eveningGownsRef = useRef(null);
  const singleBreastedRef = useRef(null);
  const doubleBreastedRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -ref.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: ref.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  const handleViewDetails = (src) => {
    setSelectedImage(src);
    setShowModal(true);
  };

  const handleRentClick = () => {
    setShowRegistrationModal(true);
  };

  const CTref = useRef(null);

  
  const [suits, setSuits] = useState([]);
  const [kids, setKids] = useState([]);
  const [evenings, setEvenings] = useState([]);
  const [cocktails, setCocktails] = useState([]);
  const [balls, setBalls] = useState([]);
  const [barongs, setBarongs] = useState([]);

 
  const fetchItems = async () => {
    console.log("Fetching items...");
    try {
      const eveningsSnapshot = await getDocs(collection(db, 'evenings'));
      setEvenings(eveningsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const cocktailsSnapshot = await getDocs(collection(db, 'cocktails'));
      setCocktails(cocktailsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const ballsSnapshot = await getDocs(collection(db, 'balls'));
      setBalls(ballsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const suitsSnapshot = await getDocs(collection(db, 'suits'));
      setSuits(suitsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const kidsSnapshot = await getDocs(collection(db, 'kids'));
      setKids(kidsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const barongsSnapshot = await getDocs(collection(db, 'barongs'));
      setBarongs(barongsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className="w-full min-h-screen bg-gray-200">
      <Navbar />
      {/* Gowns Collection Section */}
      <div className="p-8 bg-gray-100">
        <div className="bg-[#ffd000] mt-20 mb-6 w-full h-20 flex items-center justify-center relative">
          <h1 className="text-4xl font-bold">GOWNS COLLECTION</h1>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">BALL GOWNS</h2>
          <div className="relative flex items-center">
            <button 
              onClick={() => scrollLeft(ballGownsRef)} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
            <div 
              ref={ballGownsRef} 
              className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
            >
              {balls.map((src, index) => (
                <div key={index} className="relative w-72 h-96 flex-shrink-0 group">
                  <img 
                    src={src.image} 
                    alt={`Ball Gown ${index + 1}`} 
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105" 
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                    <button 
                      onClick={() => handleViewDetails(src)}
                      className="text-white text-lg font-semibold"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={() => scrollRight(ballGownsRef)} 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          </div>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">EVENING GOWNS</h2>
          <div className="relative flex items-center">
            <button 
              onClick={() => scrollLeft(eveningGownsRef)} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
            <div 
              ref={eveningGownsRef} 
              className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
            >
              {evenings.map((src, index) => (
                <div key={index} className="relative w-72 h-96 flex-shrink-0 group">
                  <img 
                    src={src.image} 
                    alt={`Evening Gown ${index + 1}`} 
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105" 
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                    <button 
                      onClick={() => handleViewDetails(src)}
                      className="text-white text-lg font-semibold"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={() => scrollRight(eveningGownsRef)} 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          </div>
        </div>
        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">COKCTAIL GOWNS</h2>
          <div className="relative flex items-center">
            <button 
              onClick={() => scrollLeft(CTref)} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
            <div 
              ref={CTref} 
              className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
            >
              {cocktails.map((src, index) => (
                <div key={index} className="relative w-72 h-96 flex-shrink-0 group">
                  <img 
                    src={src.image} 
                    alt={`Evening Gown ${index + 1}`} 
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105" 
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                    <button 
                      onClick={() => handleViewDetails(src)}
                      className="text-white text-lg font-semibold"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={() => scrollRight(CTref)} 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          </div>
        </div>

        <div className="mb-12">
          <h2 className="text-2xl font-semibold mb-4">kids section</h2>
          <div className="relative flex items-center">
            <button 
              onClick={() => scrollLeft(eveningGownsRef)} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
            <div 
              ref={eveningGownsRef} 
              className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
            >
              {kids.map((src, index) => (
                <div key={index} className="relative w-72 h-96 flex-shrink-0 group">
                  <img 
                    src={src.image} 
                    alt={`Evening Gown ${index + 1}`} 
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105" 
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                    <button 
                      onClick={() => handleViewDetails(src)}
                      className="text-white text-lg font-semibold"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={() => scrollRight(eveningGownsRef)} 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          </div>
        </div>

        {/* Suits Collection Section */}
        <div className="mb-12">
          <div className="bg-[#ffd000] mt-20 mb-6 w-full h-20 flex items-center justify-center relative">
            <h1 className="text-4xl font-bold">SUITS COLLECTION</h1>
          </div>
          <h2 className="text-2xl font-semibold mb-4 uppercase">Suits</h2>
          <div className="relative flex items-center mb-8">
            <button 
              onClick={() => scrollLeft(singleBreastedRef)} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
            <div 
              ref={singleBreastedRef} 
              className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
            >
              {suits.map((src, index) => (
                <div key={index} className="relative w-72 h-96 flex-shrink-0 group">
                  <img 
                    src={src.image} 
                    alt={`Single Breasted Suit ${index + 1}`} 
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105" 
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                    <button 
                      onClick={() => handleViewDetails(src)}
                      className="text-white text-lg font-semibold"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={() => scrollRight(singleBreastedRef)} 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          </div>

          

          <h2 className="text-2xl font-semibold mb-4 mt-4 uppercase">Barong</h2>
          <div className="relative flex items-center">
            <button 
              onClick={() => scrollLeft(doubleBreastedRef)} 
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
            <div 
              ref={doubleBreastedRef} 
              className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
            >
              {barongs.map((src, index) => (
                <div key={index} className="relative w-72 h-96 flex-shrink-0 group">
                  <img 
                    src={src.image} 
                    alt={`Double Breasted Suit ${index + 1}`} 
                    className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform group-hover:scale-105" 
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                    <button 
                      onClick={() => handleViewDetails(src)}
                      className="text-white text-lg font-semibold"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <button 
              onClick={() => scrollRight(doubleBreastedRef)} 
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          </div>



        </div>
      </div>
      <Footer />
      {showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
    <div className="bg-white p-8 rounded-lg w-1/3 relative">
      <button 
        onClick={() => setShowModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <h4 className="text-2xl font-semibold text-center">{selectedImage.name}</h4>
      <img 
        src={selectedImage.image} 
        alt="Selected Gown" 
        className="w-full h-auto max-w-md mx-auto"
      />
      <p className="mt-4 text-center">{selectedImage.description}</p>
      <div className="mt-4 flex space-x-4">
        <button 
          onClick={handleRentClick}
          className="w-full bg-yellow-500 text-white py-2 rounded-lg"
        >
          RENT
        </button>
      </div>
    </div>
  </div>
)}

      {showRegistrationModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
    <div className="bg-white p-8 rounded-lg w-2/5 relative">
      <button 
        onClick={() => setShowRegistrationModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <div className="flex items-center justify-center mb-4">
        <i className="fas fa-exclamation-triangle text-3xl text-red-500 mr-2"></i>
        <p className="text-lg text-center text-red-500">
          You need to register an account to do a transaction.
        </p>
      </div>
      <div className="flex justify-center space-x-4 mt-4">
        <button
          onClick={() => window.location.href = 'signup'}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg"
        >
          Register
        </button>
        <button
          onClick={() => window.location.href = '/'}
          className="bg-green-500 text-white py-2 px-4 rounded-lg"
        >
          Login
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};
export default Collections;