import React, { useState } from 'react';
import Logo from '../assets/logo.png';
import { FaFacebookSquare } from 'react-icons/fa';
import { BiPhone, BiCopyright } from 'react-icons/bi';

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalContent('');
  };

  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className='bg-black py-10 px-4 lg:px-24 grid grid-cols-1 lg:grid-cols-3 text-white gap-8 text-center lg:text-left'>
      <div className='my-5'>
        <div className='px-7 flex justify-center lg:justify-start'>
          <img src={Logo} alt="Logo image" style={{ width: '60px' }} />
          <h1 className='font-bold py-4 px-6 uppercase tracking-widest italic'>Amarabelliana.</h1>
        </div>
        <p className='py-4 px-8 uppercase font-semibold text-[gray] text-sm'>
          Tailored Rentals & Custom Creations: Your Perfect Fit Awaits.
        </p>
      </div>

      <div className='px-0 lg:px-52 py-4'>
        <h1 className='font-bold uppercase tracking-widest w-full lg:w-28 text-sm text-[#ffd000]'>follow us</h1>
        <div className='flex justify-center lg:justify-start items-center w-full lg:w-72 pt-3 cursor-pointer' onClick={() => handleRedirect('https://www.facebook.com/profile.php?id=100064026936333')}>
          <FaFacebookSquare size={20} />
          <p className='px-4 text-sm text-[gray]'>Amarabelliana's Gown and Event</p>
        </div>
      </div>

      <div className='px-0 lg:px-52 py-4'>
        <h1 className='font-bold uppercase tracking-widest w-full lg:w-28 text-sm text-[#ffd000]'>Contact us</h1>
        <div className='flex justify-center lg:justify-start items-center w-full lg:w-72 pt-4'>
          <BiPhone size={20} />
          <p className='px-4 text-sm text-[gray]'>TM: +63 955 260 9144</p>
        </div>
        <div className='flex justify-center lg:justify-start items-center w-full lg:w-72 pt-3'>
          <BiPhone size={20} />
          <p className='px-4 text-sm text-[gray]'>SMART: +63 961 748 1435</p>
        </div>
        <div className='flex justify-center lg:justify-start items-center w-full lg:w-72 pt-3'>
          <BiPhone size={20} />
          <p className='px-4 text-sm text-[gray]'>LANDLINE: 443068414</p>
        </div>
      </div>

      <div className='lg:col-span-3 border-b border-[gray] mt-8 lg:mt-0'></div>

      <div className='py-4 flex justify-center lg:justify-start items-center'>
        <BiCopyright className='text-[gray]' size={15} />
        <p className='italic text-sm pl-2 text-[gray]'>2024 Amarabelliana's gowns and events. All rights reserved.</p>
      </div>

      <div className='py-4 flex justify-center lg:justify-start items-center px-0 lg:px-52 text-sm text-[gray] italic underline'>
        <p className='hover:cursor-pointer hover:text-gray-400' onClick={() => handleModalOpen('privacy')}>Privacy Policy</p>
      </div>

      <div className='py-4 flex justify-center lg:justify-start items-center px-0 lg:px-52 text-sm text-[gray] italic underline'>
        <p className='hover:cursor-pointer hover:text-gray-400 whitespace-nowrap' onClick={() => handleModalOpen('terms')}>Terms of Service</p>
      </div>


      {showModal && (
        <div className='mt-20 fixed inset-0 flex text-left items-center justify-center bg-black bg-opacity-50 p-4'>
          <div className='bg-white text-black p-6 rounded-lg shadow-lg w-full max-w-[1200px] overflow-y-auto max-h-[90vh]'>
            <h2 className='text-3xl font-bold mb-4'>
              {modalContent === 'terms' ? 'Terms of Service' : 'Privacy Policy'}
            </h2>
            {modalContent === 'terms' ? (
              <div>
                <p className='mb-4'><strong>Effective Date:</strong> October 18, 2024</p>

                <p className='mb-4'>Welcome to Amarabelliana’s Gowns and Events Stylist. 
                By accessing or using our system, you agree to comply with and be bound by these Terms of Service. 
                Please read them carefully before using our services. </p>
                
                <p className='mb-4'><strong>1. Acceptance of Terms</strong><br /> 
                <p className='mb-2'></p>
                By accessing, browsing, or using the Platform, you acknowledge that you have read, 
                understood, and agree to be bound by these Terms, along with our Privacy Policy. 
                If you do not agree with any part of these Terms, you must discontinue using the Platform immediately. </p>

                <p className='mb-4'><strong>2. Services Provided</strong><br />
                <p className='mb-2'></p>
                The Platform offers a rental service for gowns, suits, and event attire with features such as:<br />
                <p className='mb-2'></p>
                  • AI-powered visual customization based on user input.<br />
                  • 3D mannequin model for virtual try-on experiences.<br />
                  • Online rental with its available payment options.<br />
                  • End-to-end messaging for customer support and styling consultations.</p>

                <p className='mb-4'><strong>3. User Accounts and Registration</strong><br />
                <p className='mb-2'></p>
                • <strong>Eligibility:</strong> You must be at least 18 years old to register and use our system. Minors may use the service only with the consent of a parent or legal guardian.<br />
                <p className='mb-2'></p>
                • <strong>Account Creation:</strong> Users must create an account by providing accurate information, including a valid email address, contact details, and proof of identity. By registering, you agree to keep your account credentials confidential.<br />
                <p className='mb-2'></p> 
                • <strong>Account Security:</strong> You are responsible for all activities conducted through your account. Notify us immediately if you suspect unauthorized access.</p>

                <p className='mb-4'><strong>4. Rental Policies</strong><br />
                <p className='mb-2'></p>  
                • <strong>Booking and Reservations:</strong> Users can reserve attire for specific dates. Confirmations are sent via email or in-platform notifications.<br />
                <p className='mb-2'></p>
                • <strong>Cancellations and Refunds:</strong> Cancellations must be made at least 72 hours before the scheduled rental date for a full refund. Refund requests after this period may incur charges or be declined.<br />
                <p className='mb-2'></p>
                • <strong>Damages and Late Returns:</strong> You are liable for any damage, loss, or late returns of rented items. A fee may be charged for repairs, replacements, or extended rental periods.</p>

                <p className='mb-4'><strong>5. AI Visual Customization and 3D Mannequin Feature</strong><br />
                <p className='mb-2'></p>
                • <strong>Accuracy Disclaimer:</strong> The AI-generated attire designs and 3D visualizations are intended for reference only. The actual attire may differ from the AI-generated images.<br />
                <p className='mb-2'></p>
                • <strong>Customization:</strong> Users can personalize their attire selections through AI-powered visual prompts and 3D mannequin customization. The Platform does not guarantee a perfect match for skin tone or body shape.</p>

                <p className='mb-4'><strong>6. Payments</strong><br/>
                <p className='mb-2'></p>
                • <strong>Payment Methods:</strong> We accept payments via GCash and on-site payments. All payments must be completed before the scheduled rental date.<br/>
                <p className='mb-2'></p>
                • <strong>Transaction Security:</strong> We use secure payment gateways to protect your financial information. However, we are not responsible for any unauthorized transactions resulting from user negligence. </p>

                <p className='mb-4'><strong>7. Privacy and Data Protection</strong><br/> 
                <p className='mb-2'></p>
                • <strong>Personal Data:</strong> By using our Platform, you agree to the collection, use, and storage of your personal data in accordance with our Privacy Policy.<br/>
                <p className='mb-2'></p>
                • <strong>Data Security:</strong> We implement reasonable security measures to protect your data. However, we cannot guarantee absolute security against data breaches. </p>

                <p className='mb-4'><strong>8. Prohibited Conduct</strong><br/> 
                <p className='mb-2'></p>
                Users are prohibited from:<br/>
                <p className='mb-2'></p>
                • Providing false information during registration.<br/>
                • Misusing the AI and 3D features for inappropriate or unlawful purposes.<br/> 
                • Attempting to disrupt the Platform’s functionality (e.g., hacking, malware).<br/>
                • Sharing or distributing any part of the Platform's content without authorization.</p>

                <p className='mb-4'><strong>9. Intellectual Property</strong><br/> 
                <p className='mb-2'></p>
                • <strong>Ownership:</strong> All content, designs, trademarks, and technologies used on the Platform are the intellectual property of Amarabelliana’s Gowns and Events Stylist or its licensors.<br/>
                <p className='mb-2'></p>
                • <strong>License:</strong> Users are granted a limited, non-exclusive license to use the Platform for personal, non-commercial purposes. </p>

                <p className='mb-4'><strong>10. Limitation of Liability </strong><br/> 
                <p className='mb-2'></p>
                • <strong>No Warranties:</strong>The Platform and its services are provided on an "as-is" basis. We do not warrant that the services will be error-free, uninterrupted, or meet all user expectations.<br/>
                <p className='mb-2'></p>
                • <strong>Indemnification:</strong> Users agree to indemnify and hold harmless Amarabelliana’s Gowns and Events Stylist from any claims, damages, or expenses arising from their use of the Platform. </p>
              
                <p className='mb-4'><strong>11. Changes to Terms</strong><br /> 
                <p className='mb-2'></p>
                We reserve the right to modify these Terms at any time. Users will be notified of significant changes via email 
                or in-platform announcements. Continued use of the Platform after changes constitute acceptance of the new Terms.  </p>

                <p className='mb-4'><strong>12. Termination</strong><br /> 
                <p className='mb-2'></p>
                We reserve the right to suspend or terminate user accounts at our discretion for violations of these Terms or any unlawful activities. 
                Users may also terminate their accounts by contacting customer support.</p>

                <p className='mb-4'><strong>13. Governing Law </strong><br /> 
                <p className='mb-2'></p>
                These Terms are governed by the laws of [Insert Jurisdiction]. Any disputes arising from these
                 Terms shall be resolved in the courts of [Insert Jurisdiction]. </p>
                
                 <p className='mb-4'><strong>14. Contact Information</strong><br/> 
                <p className='mb-2'></p>
                For any questions or concerns regarding these Terms, please contact us at: <br/>
                <p className='mb-2'></p>
                • <strong>Email:</strong> amarabelliana.customerservice@gmail.com <br/>
                • <strong>Phone:</strong> +63 955 260 9144, +63 961 748 1435 
                </p>

              
              </div>
            ) : (
              <div>
                <p className='mb-4'>Amarabelliana’s Gowns and Events Stylist values your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information through our Multiplatform Rental System, which includes AI Visual Customization and 3D Mannequin features.</p>
                
                <p className='mb-4'><strong>1. Collection of Personal Information</strong><br />
                We collect personal information from you upon registration and throughout your use of the system, including but not limited to:<br />
                • Contact Information (e.g., name, email, phone number)<br />
                • Verification Data (e.g., uploaded valid ID for account verification)<br />
                • Preferences and Usage Data (e.g., rental history, style preferences)<br />
                • Payment Information (for processing transactions)
                </p>

                <p className='mb-4'><strong>2. Use of Personal Information</strong><br />
                The personal information we collect is used for the following purposes:<br />
                • To provide and improve our rental services and personalized features, such as 3D mannequin customization and AI-generated visualizations.<br />
                • To verify your identity and manage access to the platform.<br />
                • To process payments and manage rental transactions.<br />
                • To send you notifications regarding account activity, updates, and service announcements.
                </p>

                <p className='mb-4'><strong>3. Data Sharing and Disclosure</strong><br />
                We do not disclose your personal information to third parties except as necessary for:<br />
                • Legal and regulatory compliance.<br />
                • Service providers involved in payment processing or system security, who are bound to protect your data.<br />
                • Other entities or individuals, with your explicit consent.
                </p>

                <p className='mb-4'><strong>4. Data Security and Retention</strong><br />
                We implement industry-standard security measures to safeguard your personal data. This includes encryption, secure databases, and controlled access protocols. Personal information is retained only as long as needed to fulfill service purposes, comply with legal obligations, or upon user request for deletion.
                </p>

                <p className='mb-4'><strong>5. User Rights</strong><br />
                Under the Data Privacy Act of 2012, you have the right to:<br />
                • Access, update, and correct your personal information in your account settings.<br />
                • Withdraw consent for data processing, subject to the effect on service availability.<br />
                • Request data deletion, except where retention is necessary for legal compliance.
                </p>

                <p className='mb-4'><strong>6. Changes to This Privacy Policy</strong><br />
                We reserve the right to modify this Privacy Policy at any time. Users will be notified of significant changes via email or system notifications.
                </p>

                <p className='mb-4'><strong>7. Contact Information</strong><br />
                For any questions or concerns regarding this Privacy Policy, you may contact us at:<br />
                Phone: +63 955 260 9144, +63 961 748 1435<br />
                Email: amarabelliana.customerservice@gmail.com
                </p>
              </div>
            )}
            <div className="text-right">
              <button
                className="bg-black text-white px-4 py-2 rounded ml-auto"
                onClick={handleModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Footer;
