import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png';
import Sidebar from './Sidebar'; // Import the Sidebar component
import { db } from '../pages/firebase'; // Adjust import based on your Firebase config location
import { collection, getDocs } from 'firebase/firestore';

const ProductReviews = () => {
  const [reviewsList, setReviewsList] = useState([]); // Initialize state for reviews
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(''); // Error state

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsCollection = collection(db, 'attireFeedbacks'); // Adjust to your Firestore collection
        const reviewsSnapshot = await getDocs(reviewsCollection);
        
        const reviewsData = reviewsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data() // Spread operator to include all document fields
        }));
        
        setReviewsList(reviewsData); // Set the fetched reviews to state
      } catch (err) {
        console.error('Error fetching reviews:', err);
        setError('Failed to fetch reviews.'); // Set error message
      } finally {
        setLoading(false); // Set loading to false
      }
    };

    fetchReviews(); // Call the fetch function
  }, []); // Empty dependency array to run once on mount

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content Area */}
      <div className="flex-1 p-8 ml-64"> {/* Adjust margin-left to accommodate sidebar */}
        <header className="flex items-center mb-8">
          <img src={logo} alt="Amarabelliana's Boutique Logo" className="w-16 h-16 mr-4 rounded-full border border-gray-300" />
          <h1 className="text-4xl font-bold text-gray-800">Amarabelliana's Boutique</h1>
        </header>

        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Product Reviews</h2>

        <div className="p-6 bg-white rounded-lg shadow">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">All Reviews</h3>
          {loading && <p>Loading reviews...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {!loading && !error && (
            <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
              <thead>
                <tr className="border-b border-gray-300">
                  <th className="p-4 text-left text-gray-600">Image</th> {/* New column for image */}
                  <th className="p-4 text-left text-gray-600">Product</th>
                  <th className="p-4 text-left text-gray-600">Reviewer</th>
                  <th className="p-4 text-left text-gray-600">Review</th>
                  <th className="p-4 text-left text-gray-600">Rating</th>
                  <th className="p-4 text-left text-gray-600">Attire Type</th> {/* New column for attire type */}
                </tr>
              </thead>
              <tbody>
                {reviewsList.map(item => (
                  <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-50 transition">
                    <td className="p-4">
                      {item.imageUrl ? (
                        <img src={item.imageUrl} alt={item.attireType} className="w-20 h-20 object-cover rounded-md" />
                      ) : (
                        <span className="text-gray-400">No Image</span>
                      )}
                    </td>
                    <td className="p-4 text-gray-800">{item.attireType || 'No product specified'}</td>
                    <td className="p-4 text-gray-800">{item.userName || 'Anonymous'}</td>
                    <td className="p-4 text-gray-800">{item.details || 'No review provided'}</td>
                    <td className="p-4 text-gray-800">
                      {[...Array(item.rating)].map((_, index) => (
                        <FontAwesomeIcon key={index} icon={faStar} className="text-yellow-500" />
                      ))}
                    </td>
                    <td className="p-4 text-gray-800">{item.attireType || 'Not specified'}</td> {/* Display attire type */}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductReviews;
