import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);


  const SalesChart = ({mockData}) => {
    const [chartType, setChartType] = useState('yearly'); // Default to yearly
    const [chartData, setChartData] = useState({
      labels: [],
      datasets: []
    });
  
    useEffect(() => {
      // Process data when chartType changes
      if (chartType === 'yearly') {
        generateYearlyData();
      } else if (chartType === 'monthly') {
        generateMonthlyData();
      } else if (chartType === 'weekly') {
        generateWeeklyData();
      }
    }, [chartType]);
  
    // Generate yearly data (e.g., 2022, 2023, 2024)
    const generateYearlyData = () => {
      const yearlyTotals = {};
      mockData.forEach(item => {
        const year = new Date(item.created_at).getFullYear();
        yearlyTotals[year] = (yearlyTotals[year] || 0) + item.imageUrls.reduce((sum, img) => sum + img.price, 0);
      });
  
      setChartData({
        labels: Object.keys(yearlyTotals),
        datasets: [
          {
            label: 'Yearly Sales',
            data: Object.values(yearlyTotals),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.1,
          }
        ]
      });
    };
  
    // Generate monthly data for the current year
    const generateMonthlyData = () => {
      const monthlyTotals = Array(12).fill(0);
      const currentYear = new Date().getFullYear();
      mockData.forEach(item => {
        const date = new Date(item.created_at);
        if (date.getFullYear() === currentYear) {
          monthlyTotals[date.getMonth()] += item.imageUrls.reduce((sum, img) => sum + img.price, 0);
        }
      });
  
      setChartData({
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: 'Monthly Sales',
            data: monthlyTotals,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            tension: 0.1,
          }
        ]
      });
    };
  
    // Generate weekly data for the current month
    const generateWeeklyData = () => {
      const weeklyTotals = Array(4).fill(0);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      mockData.forEach(item => {
        const date = new Date(item.created_at);
        if (date.getFullYear() === currentYear && date.getMonth() === currentMonth) {
          const week = Math.floor((date.getDate() - 1) / 7); // Determine which week of the month (0-3)
          weeklyTotals[week] += item.imageUrls.reduce((sum, img) => sum + img.price, 0);
        }
      });
  
      setChartData({
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
          {
            label: 'Weekly Sales',
            data: weeklyTotals,
            backgroundColor: 'rgba(255, 159, 64, 0.5)',
            borderColor: 'rgba(255, 159, 64, 1)',
          }
        ]
      });
    };
  
    return (
        <div className="p-6 bg-white rounded-lg shadow-md max-w-screen-md">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Sales Analysis</h2>
          <div className='flex gap-5 items-center mb-6'>
            <button
              className={`px-4 text-white py-2 rounded-lg ${chartType === 'weekly' ? 'bg-green-600' : 'bg-green-500 hover:bg-green-600'}`}
              onClick={() => setChartType('weekly')}
            >
              Weekly
            </button>
            <button
              className={`px-4 text-white py-2 rounded-lg ${chartType === 'monthly' ? 'bg-green-600' : 'bg-green-500 hover:bg-green-600'}`}
              onClick={() => setChartType('monthly')}
            >
              Monthly
            </button>
            <button
              className={`px-4 text-white py-2 rounded-lg ${chartType === 'yearly' ? 'bg-green-600' : 'bg-green-500 hover:bg-green-600'}`}
              onClick={() => setChartType('yearly')}
            >
              Yearly
            </button>
          </div>
    
          {/* Display the chart */}
          <div className="h-96 p-4 bg-white rounded-lg shadow-md">
            {chartType === 'yearly' && chartData.datasets.length > 0 && <Line data={chartData} options={{ responsive: true }} />}
            {chartType === 'monthly' && chartData.datasets.length > 0 && <Line data={chartData} options={{ responsive: true }} />}
            {chartType === 'weekly' && chartData.datasets.length > 0 && <Bar data={chartData} options={{ responsive: true }} />}
          </div>
        </div>
      );
    };

  export default SalesChart;