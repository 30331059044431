// SuperAdminDashboard.js
import React, { useState } from 'react';
import { auth } from '../pages/firebase'; // Adjust the path based on your file structure
import { signOut } from 'firebase/auth'; // Import signOut from Firebase Auth
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const SuperAdminDashboard = () => {
  const [admins, setAdmins] = useState([
    { id: 1, email: 'admin1@example.com' },
    { id: 2, email: 'admin2@example.com' },
  ]);
  const [email, setEmail] = useState('');
  const [editAdminId, setEditAdminId] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleAddAdmin = () => {
    if (email) {
      setAdmins([...admins, { id: admins.length + 1, email }]);
      setEmail('');
    }
  };

  const handleEditAdmin = (id) => {
    const adminToEdit = admins.find((admin) => admin.id === id);
    if (adminToEdit) {
      setEmail(adminToEdit.email);
      setEditAdminId(id);
    }
  };

  const handleUpdateAdmin = () => {
    setAdmins(
      admins.map((admin) => 
        admin.id === editAdminId ? { ...admin, email } : admin
      )
    );
    setEmail('');
    setEditAdminId(null);
  };

  const handleDeleteAdmin = (id) => {
    setAdmins(admins.filter((admin) => admin.id !== id));
  };

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      navigate('/'); // Redirect to home page
      console.log("Successfully logged out");
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Super Admin Dashboard</h1>

      <button
        onClick={handleLogout}
        className="mb-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
      >
        Logout
      </button>

      <div className="mb-4">
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Admin Email"
          className="border px-3 py-2 rounded-lg"
        />
        <button
          onClick={editAdminId ? handleUpdateAdmin : handleAddAdmin}
          className="ml-2 bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600"
        >
          {editAdminId ? 'Update Admin' : 'Add Admin'}
        </button>
      </div>

      <table className="min-w-full border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Admin Email</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin.id}>
              <td className="border border-gray-300 px-4 py-2">{admin.email}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleEditAdmin(admin.id)}
                  className="bg-blue-500 text-white px-2 py-1 rounded-lg hover:bg-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteAdmin(admin.id)}
                  className="ml-2 bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SuperAdminDashboard;
