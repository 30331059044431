import React, { useState, useRef, useEffect } from 'react';
import { auth, db } from '../pages/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider,signInWithPopup} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import Navbar from '../components/Navbar';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import gmailIcon from '../assets/gmail.png';
import phicon from '../assets/phicon.png';
import { Link } from 'react-router-dom';


const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    password: '',
    confirmPassword: '',
    phoneNumber: '',
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showMissingIDError, setShowMissingIDError] = useState(false);
  const [file, setFile] = useState(null);
  const [showPasswordMismatchError, setShowPasswordMismatchError] = useState(false); // New state for password mismatch error
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false); // State for phone number error
  const [isLoading, setIsLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const fileInputRef = useRef(null);
  const storage = getStorage();
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    // Load saved form data from local storage if available
    const savedFormData = JSON.parse(localStorage.getItem('formData'));
    if (savedFormData) {
      setFormData(savedFormData);
    }
  }, []);
  

  const handleChange = (e) => {
    const updatedFormData = { ...formData, [e.target.name]: e.target.value };
  
    setFormData(updatedFormData);
  
    // Temporarily save form data to local storage
    localStorage.setItem('formData', JSON.stringify(updatedFormData));
  
    if (e.target.name === 'password') {
      evaluatePasswordStrength(e.target.value);
    }
  };
  
  

  const evaluatePasswordStrength = (password) => {
    const hasCapital = /[A-Z]/.test(password);
    const hasSpecial = /[!@#$%^&*()_+=[\]{};':"\\|,.<>?]+/.test(password);
    const hasNumber = /\d/.test(password);
    const length = password.length;
  
    if (length < 6) {
      setPasswordStrength('Weak');
    } else if (length >= 8 && hasCapital && hasSpecial && hasNumber) {
      setPasswordStrength('Strong');
    } else if (length > 8 && !hasCapital && !hasSpecial) {
      setPasswordStrength('Strong');
    } else if ((length >= 6 && length <= 8 && !hasCapital && !hasSpecial) ||
              (length >= 6 && length <= 8 && (hasCapital || hasSpecial) && !hasNumber)) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Weak');
    }
  };
  

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setShowError(true);
      setFile(null);
      return;
    }

    const fileType = selectedFile.type;
    // Check if the file is an image and contains 'id' in the file name
    if (
      (fileType === 'image/jpeg' || fileType === 'image/png')
    ) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setShowError(true);
        setFile(null);
        alert('The file is too large. Please upload an ID image less than 5MB.');
        return;
      }

      // Set the file if all conditions pass
      setFile(URL.createObjectURL(selectedFile));
      setShowError(false);
    } else {
      // Invalid file type or name
      setShowError(true);
      setFile(null);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phonePattern = /^[9][0-9]{9}$/; // Starts with 9 and is 10 digits
    return phonePattern.test(phoneNumber);
  };
  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    setIsLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      await setDoc(doc(db, 'users', user.uid), {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber || '',
        role: 'user',
        status: 'pending'
      });

      setShowModal(true);
    } catch (error) {
      console.error('Error signing up with Google:', error.message);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isTermsAccepted || !isPrivacyAccepted) {
      setIsModalVisible(true); // Show modal if the terms and privacy policy are not accepted
      setIsTermsAccepted(false); // Reset the checkbox
      setIsPrivacyAccepted(false); // Reset the checkbox
      return;
    }

    if (!file || formData.password !== formData.confirmPassword || !validatePhoneNumber(formData.phoneNumber)) {
      setShowMissingIDError(!file);
      setShowPasswordMismatchError(formData.password !== formData.confirmPassword);
      setShowPhoneNumberError(!validatePhoneNumber(formData.phoneNumber));
      return;
    }

    if (!file) {
      setShowMissingIDError(true);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setShowPasswordMismatchError(true);
      return;
    }

    if (!validatePhoneNumber(formData.phoneNumber)) {
      setShowPhoneNumberError(true);
      return;
    }

    setIsLoading(true);
    try {
      // Create user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      const user = userCredential.user;

      // Upload the file to Firebase Storage
      const storageRef = ref(storage, `user_ids/${user.uid}/${file.name}`);
      const response = await fetch(file); // Get the file from URL
      const blob = await response.blob(); // Convert to blob
      await uploadBytes(storageRef, blob); // Upload the file

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // Save user data to Firestore with download URL
      await setDoc(doc(db, 'users', user.uid), {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        suffix: formData.suffix,
        email: formData.email,
        phoneNumber: `+63${formData.phoneNumber}`,
        role: 'user',
        idImageUrl: downloadURL, // Store the download URL
        status: 'pending' // Add pending status
      });
      
      await sendEmailVerification(user);
      console.log('Verification email sent to:', user.email);
      console.log('User created:', user);
      setShowModal(true);
    } catch (error) {
      console.error('Error signing up:', error.code, error.message);
      // Handle errors as before...
    } finally {
      setIsLoading(false);
    }
    localStorage.removeItem('formData');
  };

  const closeModal = (shouldResetForm) => {
    setShowModal(false);
    setShowError(false);
    setShowPasswordMismatchError(false);
    setShowMissingIDError(false);

    if (shouldResetForm) {
      setFormData({
        firstName: '',
        middleName: '',
        lastName: '',
        suffix: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
      });
      setFile(null);
      setPasswordStrength('');
    }
  };
  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };
  

  return (
    <div className="min-h-screen bg-white text-black">
      <div className="fixed top-0 left-0 w-full bg-white shadow-md z-10">
        <Navbar />
      </div>

      <div className="pt-16 min-h-screen flex items-center justify-center bg-white text-black">
        <div className="mt-14 mb-14 p-8 rounded-lg bg-gray-200 flex">
          <div>
            <h2 className="text-3xl font-bold ml-6 mb-4">REGISTER</h2>
            <form onSubmit={handleSubmit}>
              {/* Input fields for name */}
              <div className="mb-4">
                <label className="block mb-2">
                  <span className="text-red-500 text-lg mr-1">*</span> First Name:
                </label>
                <input
                  className="w-full p-3 rounded border border-gray-300 bg-white"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
                
              </div>
              <div className="mb-4">
                <label className="block mb-2">Middle Name:</label>
                <input
                  className="w-full p-3 rounded border border-gray-300 bg-white"
                  type="text"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">
                  <span className="text-red-500 text-lg mr-1">*</span> Last Name:
                </label>
                <input
                  className="w-full p-3 rounded border border-gray-300 bg-white"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Suffix:</label>
                <input
                  className="w-full p-3 rounded border border-gray-300 bg-white"
                  type="text"
                  name="suffix"
                  value={formData.suffix}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label className="block mb-2">
                  <span className="text-red-500 text-lg mr-1">*</span> Phone Number:
                </label>
                <div className="flex">
                  <span className="flex items-center pt-3 pb-3 pl-5 w-[120px] bg-gray-200 rounded-l border border-gray-300">
                  <span className="mr-1">
                    <img src={phicon} alt="PH Flag" className="w-5 h-5" />
                  </span>
                    +63
                  </span>
                  <input
                    className="w-full p-3 rounded-r border border-gray-300 bg-white"
                    type="tel"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    maxLength="10" // Limits input to 10 characters
                    pattern="[0-9]*" // Ensures only numeric input
                    inputMode="numeric" // Opens numeric keypad on mobile devices
                  />
                </div>
                {/* Phone number validation message */}
                {formData.phoneNumber && formData.phoneNumber.length === 11 && (
                  <p className="text-red-500 text-sm mt-1">Phone number should not be exactly 10 digits!</p>
                )}
              </div>



              {/* Input fields for email and passwords */}
              <div className="mb-4">
                <label className="block mb-2">
                  <span className="text-red-500 text-lg mr-1">*</span> Email Address:
                </label>
                <input
                  className="w-full p-3 rounded border border-gray-300 bg-white"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-4">
  <label className="block mb-2">
    <span className="text-red-500 text-lg mr-1">*</span> Password:
  </label>
  <div className="relative">
    <input
      className="w-full p-3 rounded border border-gray-300 bg-white"
      type={passwordVisible ? "text" : "password"} // Toggle password visibility
      name="password"
      value={formData.password}
      onChange={handleChange}
      required
    />
    <span
      className={`absolute right-2 top-3 cursor-pointer ${passwordVisible ? 'text-blue-600' : 'text-gray-500'}`}
      onClick={togglePasswordVisibility}
    >
      <i className={`fas ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i>
    </span>
  </div>
  {passwordStrength && (
    <p className={`text-sm mt-1 ${passwordStrength === 'Strong' ? 'text-green-500' : passwordStrength === 'Medium' ? 'text-yellow-500' : 'text-red-500'}`}>
      Password Strength: {passwordStrength}
    </p>
  )}
</div>
<div className="mb-4">
  <label className="block mb-2">
    <span className="text-red-500 text-lg mr-1">*</span> Confirm Password:
  </label>
  <div className="relative">
    <input
      className="w-full p-3 rounded border border-gray-300 bg-white"
      type={confirmPasswordVisible ? "text" : "password"} // Toggle confirm password visibility
      name="confirmPassword"
      value={formData.confirmPassword}
      onChange={handleChange}
      required
    />
    <span
      className={`absolute right-2 top-3 cursor-pointer ${confirmPasswordVisible ? 'text-blue-600' : 'text-gray-500'}`}
      onClick={toggleConfirmPasswordVisibility}
    >
      <i className={`fas ${confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}></i>
    </span>
  </div>
  {/* Password mismatch warning */}
  {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword && (
    <p className="text-red-500 text-sm mt-1">Passwords do not match!</p>
  )}
</div>

              {/* Upload Instructions and Accepted ID list */}
              <div className="mt-16 text-sm mb-4">
                <fieldset className="p-4 border border-gray-300 rounded-lg">
                  <legend className="font-bold">Upload Instructions:</legend>
                  <ol className="list-decimal list-inside mx-10">
                    <li>Ensure that your ID is valid and not expired.</li>
                    <li>The ID must be clear and readable. Blurry or obscured images will not be accepted.</li>
                    <li>The entire ID should be visible in the photo (all four corners must be shown).</li>
                    <li>Accepted file formats: JPEG, PNG.</li>
                    <li>Maximum file size: 5MB.</li>
                  </ol>
                  <div className="ml-4 mt-4">
                    <p className="font-bold">Accepted ID's:</p>
                    <div className="flex flex-wrap mt-2">
                      <div className="w-1/2">
                        <ul className="list-none">
                          <li>UMID</li>
                          <li>Driver's License</li>
                          <li>National ID</li>
                          <li>Passport</li>
                          <li>PhilHealth ID</li>
                        </ul>
                      </div>
                      <div className="w-1/2">
                        <ul className="list-none">
                          <li>Voter's ID</li>
                          <li>SSS ID</li>
                          <li>Postal ID</li>
                          <li>PRC ID</li>
                          <li>Paibig ID</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* Upload button and file input */}
              <div className="mb-4">
                <label className="block mb-2 font-bold">
                  <span className="text-red-500 text-lg mr-1">*</span> Upload your ID:
                </label>
                <button
                  type="button"
                  className="w-full p-3 rounded bg-blue-500 text-white hover:bg-blue-700"
                  onClick={handleUploadClick}
                >
                  Upload ID
                </button>
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                {file && (
                  <div className="mt-4">
                    <p className="font-bold">Uploaded Image Preview:</p>
                    <img src={file} alt="Uploaded ID Preview" className="mt-2 w-full max-h-64 object-contain" />
                  </div>
                )}
                {showError && (
                  <div className="text-red-500 mt-2">
                    <p>Invalid file type or size. Please upload a JPEG/PNG file under 5MB.</p>
                  </div>
                )}
              </div>
              {/* Submit button */}
              <button
                type="submit"
                className="w-full p-3 rounded bg-green-500 text-white hover:bg-green-700"
                disabled={isLoading} // Disable button during loading
              >
                {isLoading ? 'Signing Up...' : 'Sign Up'}

              </button>
              <div className="flex items-center my-4">
                <hr className="flex-grow border-gray-300" />
                <span className="px-4 text-gray-500 font-bold">OR</span>
                <hr className="flex-grow border-gray-300" />
              </div>
              <button
                type="button"
                onClick={handleGoogleSignUp}
                className="bg-white border-solid border-[1px] hover:border-blue-700 border-black hover:bg-blue-700 text-black hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center mb-4 w-full"
              >
                <img src={gmailIcon} alt="Gmail Icon" className="w-5 md:w-6 h-5 md:h-6 mr-2" />
                Continue with Google
              </button>

              {/* Accept Terms and Privacy Policy */}
              <div className="mt-10">
                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="terms"
                      checked={isTermsAccepted}
                      onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                      className="mr-2"
                    />
                    <label htmlFor="terms" className="">
                      I accept the <a href="/terms" className="text-blue-500">Terms and Conditions</a>
                    </label>
                  </div>

                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="privacy"
                      checked={isPrivacyAccepted}
                      onChange={() => setIsPrivacyAccepted(!isPrivacyAccepted)}
                      className="mr-2"
                    />
                    <label htmlFor="privacy" className="">
                      I accept the <a href="/privacy-policy" className="text-blue-500">Privacy Policy</a>
                    </label>
                  </div>
                </div>
                
            </form>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center mb-10">
        <Link 
          to="/?login=true" 
          className="text-center hover:underline text-gray-500 hover:text-black"
        >
          Go to Login
        </Link>
      </div>


        {/* Modal for password mismatch error */}
        {showPasswordMismatchError && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg">
              <h2 className="text-xl font-bold">Error</h2>
              <p>Passwords do not match. Please try again.</p>
              <button
                className="mt-4 bg-blue-500 text-white p-2 rounded"
                onClick={() => closeModal(false)} // Close the modal without resetting the form
              >
                Close
              </button>
            </div>
          </div>
        )}

            {/* Success Modal */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
            <div className="bg-white p-8 rounded shadow-lg">
              <h2 className="text-xl font-bold mb-4">Registration Successful!</h2>
              <p>You have successfully signed up. A verification email has been sent to your email address. Please verify your email to continue.</p>
              <div className="flex justify-end mt-4">
                <button
                  className="p-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                  onClick={() => closeModal(true)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}


        {/* Missing ID Modal */}
    {showMissingIDError && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
        <div className="bg-white p-8 rounded shadow-lg">
          <h2 className="text-xl font-bold mb-4">Missing ID</h2>
          <p>Please upload a valid ID before submitting the form.</p>
          <div className="flex justify-end mt-4">
            <button
              className="p-2 bg-red-500 text-white rounded hover:bg-red-700"
              onClick={() => closeModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )}

        {/* Modal for Terms and Privacy Policy Error */}
        {isModalVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
              <h2 className="text-xl font-semibold text-red-500">Error</h2>
              <p className="mt-4">Please accept the Terms and Conditions and Privacy Policy to continue.</p>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => {
                    setIsModalVisible(false);
                    setIsTermsAccepted(false); // Reset the checkbox for Terms
                    setIsPrivacyAccepted(false); // Reset the checkbox for Privacy Policy
                  }}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

    

      {/* Phone Number Error Modal */}
      {showPhoneNumberError && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-bold">Error</h2>
            <p>Phone number must start with 9 and have 10 digits.</p>
            <button
              className="mt-4 bg-blue-500 text-white p-2 rounded"
              onClick={() => setShowPhoneNumberError(false)} // Close the modal
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUp;