import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png'; // Use a different logo if required

const AdminSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative">
      {/* Sidebar Toggle Button */}
      <button 
        className="fixed top-4 left-4 z-30 text-black text-2xl bg-gold p-2 rounded-full" 
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
      </button>

      {/* Sidebar */}
      <div 
        className={`fixed top-0 left-0 z-20 w-64 bg-black text-white p-6 h-[100vh] transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}
      >
        <div className="flex items-center mb-6">
          <img src={logo} alt="Logo" className="w-12 h-12 mr-3" />
          <h2 className="text-2xl font-bold">Admin Dashboard</h2>
        </div>
        <nav>
          <ul>
            <li className="mb-4">
              <Link to="/admin-dashboard" className="text-white hover:text-gray-400 transition-colors duration-300">Home</Link>
            </li>
            <li className="mb-4">
              <Link to="/staff-management" className="text-white hover:text-gray-400 transition-colors duration-300">Staff & User Management</Link>
            </li>
            <li className="mb-4">
              <Link to="/inventory-management" className="text-white hover:text-gray-400 transition-colors duration-300">Inventory Management</Link>
            </li>
            <li className="mb-4">
              <Link to="/archive" className="text-white hover:text-gray-400 transition-colors duration-300">Archive</Link>
            </li>
            <li className="mb-4">
              <Link to="/product-reviews" className="text-white hover:text-gray-400 transition-colors duration-300">Product Reviews</Link>
            </li>
            
          </ul>
        </nav>
      </div>

      {/* Overlay to close sidebar */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black opacity-50 z-10" 
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
};

export default AdminSidebar;
