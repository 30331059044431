import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, updateProfile } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../pages/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const AdminProfile = () => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(null);
  const [preview, setPreview] = useState(null);
  const [adminName, setAdminName] = useState('');
  const [email, setEmail] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [newName, setNewName] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);
        setProfilePicture(user.photoURL);
        const name = user.displayName || 'Admin';
        setAdminName(name);
        setNewName(name);

        const adminDoc = await getDoc(doc(db, 'admins', user.uid));
        if (adminDoc.exists()) {
          const data = adminDoc.data();
          setAdminName(data.name || name);
          setNewName(data.name || name);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      if (newName !== adminName) {
        await updateProfile(user, { displayName: newName });
        await updateDoc(doc(db, 'admins', user.uid), { name: newName });
        setAdminName(newName);
      }

      if (profilePicture instanceof File) {
        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        await uploadBytes(storageRef, profilePicture);
        const photoURL = await getDownloadURL(storageRef);

        await updateProfile(user, { photoURL });
        await updateDoc(doc(db, 'admins', user.uid), { photoURL });
        setProfilePicture(photoURL);
        setPreview(null);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-gray-900 flex items-center justify-center py-10 px-4">
      <div className="w-full max-w-lg p-8 border border-gray-700 rounded-lg bg-opacity-80 bg-gray-800 shadow-xl backdrop-blur-md text-gray-300">
        <button
          onClick={() => navigate(-1)}
          className="mb-4 text-yellow-400 hover:text-yellow-500 transition-colors"
        >
          &larr; Back
        </button>
        <h1 className="text-3xl font-bold mb-6 text-center text-yellow-400">Admin Profile</h1>
        <div className="flex flex-col items-center">
          <label
            htmlFor="adminProfilePicture"
            className="relative group w-48 h-48 rounded-full overflow-hidden border-4 border-yellow-500 cursor-pointer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className={`w-full h-full flex items-center justify-center bg-gray-700 transition ${isHovered ? 'opacity-70' : ''}`}>
              {preview || profilePicture ? (
                <img src={preview || profilePicture} alt="Admin Profile Preview" className="w-full h-full object-cover" />
              ) : (
                <span className="text-6xl text-yellow-400 font-bold">{adminName.charAt(0).toUpperCase()}</span>
              )}
            </div>
            {isHovered && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-60 text-white text-sm font-medium">
                {preview ? 'Change Profile' : 'Upload Profile'}
              </div>
            )}
            <input
              type="file"
              id="adminProfilePicture"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </label>

          <div className="flex flex-col items-center mt-6 w-full">
            {editingName ? (
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className="text-xl bg-gray-700 text-yellow-300 p-2 rounded w-full text-center focus:outline-none"
              />
            ) : (
              <p className="text-xl font-semibold text-yellow-300 mt-2">Name: {adminName}</p>
            )}
            <p className="text-lg text-gray-400 mt-2">Email: {email}</p>
            <button
              onClick={() => setEditingName(!editingName)}
              className="mt-4 text-yellow-400 hover:text-yellow-500 transition"
            >
              {editingName ? 'Cancel Edit' : 'Edit Name'}
            </button>
          </div>

          {(editingName || profilePicture instanceof File) && (
            <button
              onClick={handleSave}
              className="mt-6 bg-yellow-500 hover:bg-yellow-600 text-gray-800 font-bold px-6 py-2 rounded-full shadow-lg transition duration-300"
            >
              Save Changes
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
