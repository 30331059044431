import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, addDoc, doc } from 'firebase/firestore';
import { db } from '../pages/firebase'; // Ensure your Firestore db config is correct
import Sidebar from './Sidebar';
import logo from '../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';

const Archive = () => {
  const [archivedItems, setArchivedItems] = useState([]);
  const [filters, setFilters] = useState({ date: '', type: '' });

  useEffect(() => {
    const fetchArchivedItems = async () => {
      try {
        const archiveSnapshot = await getDocs(collection(db, 'archive'));
        setArchivedItems(archiveSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.error('Error fetching archived items:', error);
      }
    };

    fetchArchivedItems();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleRecover = async (id, item) => {
    try {
      const collectionName = item.category === 'Gown' ? 'gowns' : 'suits';
      await addDoc(collection(db, collectionName), item);
      await deleteDoc(doc(db, 'archive', id));
      setArchivedItems(archivedItems.filter((archivedItem) => archivedItem.id !== id));
      alert(`Item with ID ${id} has been recovered!`);
    } catch (error) {
      console.error('Error recovering item:', error);
    }
  };

  const filteredItems = archivedItems.filter((item) => {
    return (
      (!filters.date || item.date === filters.date) &&
      (!filters.type || item.category.toLowerCase().includes(filters.type.toLowerCase()))
    );
  });

  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar />

      <div className="flex-1 p-8 ml-64">
        <header className="flex items-center mb-8">
          <img src={logo} alt="Boutique Logo" className="w-16 h-16 mr-4 rounded-full border border-gray-300" />
          <h1 className="text-4xl font-bold text-gray-800">Amarabelliana's Boutique</h1>
        </header>

        <div className="mb-8 flex items-center justify-between">
          <h2 className="text-3xl font-bold text-gray-800">Archived Items</h2>
          <div className="flex space-x-4">
            <select
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
              className="p-2 border border-gray-300 rounded"
            >
              <option value="">Filter by Type</option>
              <option value="Gown">Gown</option>
              <option value="Suit">Suit</option>
            </select>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="p-4 text-left text-gray-600">Image</th> {/* New column for image */}
                <th className="p-4 text-left text-gray-600">Name</th>
                <th className="p-4 text-left text-gray-600">Type</th>
                <th className="p-4 text-left text-gray-600">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.length === 0 ? (
                <tr>
                  <td colSpan="5" className="p-4 text-center text-gray-600">No archived items available</td>
                </tr>
              ) : (
                filteredItems.map(item => (
                  <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-50 transition">
                    <td className="p-4">
                      {item.image ? (
                        <img src={item.image} alt={item.name} className="w-20 h-20 object-cover rounded-md" />
                      ) : (
                        <span className="text-gray-400">No Image</span>
                      )}
                    </td>
                    <td className="p-4 text-gray-800">{item.name}</td>
                    <td className="p-4 text-gray-800">{item.category}</td>
                    <td className="p-4 text-gray-800">
                      <button
                        onClick={() => handleRecover(item.id, item)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <FontAwesomeIcon icon={faUndo} className="mr-1" /> Recover
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Archive;
