import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid'; // Heroicons v2 import path


const TermsAndConditions = () => {
  // Go back function
  const handleBack = () => {
    window.history.back(); // Navigates back to the previous page
  };

  return (
    <div className="bg-gray-100 text-gray-800 font-sans">
      <div className="max-w-7xl mx-auto py-16 px-6 sm:px-8 lg:px-16">
        <div className="flex items-center space-x-4 mb-8">
          {/* Stylish Back Icon */}
          <button 
            onClick={handleBack} 
            className="p-3 rounded-full bg-yellow-400 text-white hover:bg-yellow-500 focus:outline-none transition-all duration-300 transform hover:scale-105">
            <ArrowLeftIcon className="h-6 w-6" />
          </button>
          <h1 className="text-4xl font-bold text-gray-900">TERMS AND CONDITIONS</h1>
        </div>

        <div className="text-center space-y-4">
          <p className="text-lg text-gray-600">Please read the following terms and conditions carefully.</p>
        </div>
        <p className='mb-4'></p>
        <div className="text-center space-y-4">
          <p className="text-lg text-gray-600">By registering and using Amarabelliana’s Rental System, 
          you agree to comply with the following Terms & Conditions: </p>
        </div>

        <div className="mt-8">
          <div className="bg-white shadow-lg rounded-lg p-6 space-y-6">
            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">1. Account Registration and Eligibility</h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ Users must provide accurate information and a valid ID upon registration.</span><br />
                <span className="block ml-8">◉ Accounts are for personal use only and must not be shared or transferred.</span><br />
                <span className="block ml-8">◉ You are responsible for maintaining the security of your account credentials.</span>
              </p>
            </section>


            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">2. Rental Process</h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ Rental selections, appointments, and booking confirmations are completed within the system.</span><br />
                <span className="block ml-8">◉ Users must read and agree to these Terms & Conditions each time a rental transaction is initiated.</span><br />
                <span className="block ml-8">◉ Rented items must be returned in the condition they were received. Any damage or loss may result in fees as outlined by the system’s policies.</span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">3. Payments and Cancellations</h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ Payment options include GCash and on-hand payment. Users agree to provide accurate payment information and follow transaction requirements. </span><br />
                <span className="block ml-8">◉ Cancellations are permitted per system policy, and cancellation fees may apply.</span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">4. System Use and AI Features</h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ The system’s AI and 3D customization features are intended solely for the purpose of enhancing user experience in renting attire. Users agree not to <span className='ml-5'>use these features for other purposes or attempt to alter or bypass system functions.</span></span><br />
                <span className="block ml-8">◉ Users understand that AI-generated images and 3D mannequin representations may not perfectly reflect actual attire fit or appearance. </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">5. User Conduct </h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ You agree to use the system respectfully and comply with all applicable laws.</span><br />
                <span className="block ml-8">◉ You must not use the system to harm or harass others, upload inappropriate content, or violate intellectual property rights.</span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">6. Limitation of Liability</h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ Amarabelliana’s Gowns and Events Stylist shall not be liable for any indirect, incidental, or consequential damages arising from your use of the <span className='ml-5'>system.</span></span><br />
                <span className="block ml-8">◉ We are not liable for damages resulting from technical issues, data loss, or unauthorized access to user accounts. </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">7. Modifications to Terms </h2>
              <p className="text-gray-600">
                <span className="block ml-8">◉ These Terms & Conditions may be updated as necessary, and continued use of the system constitutes acceptance of any changes. </span><br />
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">8. Contact Information</h2>
              <p className="text-gray-600">
                <span className="block ml-8">
                  For questions regarding these Terms & Conditions, contact us at:&nbsp;
                  <a 
                    href="mailto:amarabelliana.customerservice@gmail.com" 
                    className="text-indigo-600 hover:text-indigo-800">
                    amarabelliana.customerservice@gmail.com
                  </a><br/>
                  Phone: +63 955 260 9144, +63 961 748 1435 
                </span><br />
              </p>
            </section>
          </div>

          <div className="mt-8 text-center">
            <p className="text-lg text-gray-600">
              If you have any questions about these terms, feel free to contact us at{' '}
              <a href="mailto:support@yourcompany.com" className="text-indigo-600 hover:text-indigo-800">
                amarabelliana.customerservice@gmail.com
              </a>.<br/>
              <p className='mb-5'></p>
              <span className="italic">
                By proceeding with registration, you confirm that you have read and agree to both the Privacy Policy and these Terms & Conditions.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
