import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

const sampleRentals = [
  { id: 1, name: 'Ball Gown', status: 'Available' },
  { id: 2, name: 'Evening Gown', status: 'Rented' },
  { id: 3, name: 'Single Breasted Suit', status: 'Available' },
  // Add more sample data as needed
];

const RentalManagement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [rentals, setRentals] = useState([]);
  const [editingRental, setEditingRental] = useState(null);
  const [name, setName] = useState('');
  const [status, setStatus] = useState('Available');

  useEffect(() => {
    setRentals(sampleRentals);

    if (id) {
      const rental = sampleRentals.find(rental => rental.id === parseInt(id));
      if (rental) {
        setEditingRental(rental);
        setName(rental.name);
        setStatus(rental.status);
      }
    }
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      console.log(`Edited rental: ${id}, ${name}, ${status}`);
    } else {
      console.log(`Added rental: ${name}, ${status}`);
    }
    navigate('/rental-management');
  };

  if (id) {
    return (
      <div className="min-h-screen bg-gray-100 p-8">
        <div className="max-w-4xl mx-auto bg-white p-8 shadow-lg rounded-lg">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">
            {editingRental ? 'Edit Rental' : 'Add New Rental'}
          </h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2">Name</label>
              <input 
                type="text" 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500" 
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2">Status</label>
              <select 
                value={status} 
                onChange={(e) => setStatus(e.target.value)} 
                className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="Available">Available</option>
                <option value="Rented">Rented</option>
              </select>
            </div>
            <button type="submit" className="bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-800">
              {editingRental ? 'Update Rental' : 'Save Rental'}
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto bg-white p-8 shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Rental Management</h1>
          <Link to="/rental-management/add" className="bg-black text-white py-3 px-6 rounded-lg hover:bg-gray-800">Add New Rental</Link>
        </div>
        
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="py-3 px-4 border-b border-gray-300">ID</th>
              <th className="py-3 px-4 border-b border-gray-300">Name</th>
              <th className="py-3 px-4 border-b border-gray-300">Status</th>
              <th className="py-3 px-4 border-b border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {rentals.map(rental => (
              <tr key={rental.id} className="hover:bg-gray-50">
                <td className="py-3 px-4 border-b border-gray-300">{rental.id}</td>
                <td className="py-3 px-4 border-b border-gray-300">{rental.name}</td>
                <td className="py-3 px-4 border-b border-gray-300">{rental.status}</td>
                <td className="py-3 px-4 border-b border-gray-300">
                  <Link to={`/rental-management/edit/${rental.id}`} className="text-blue-500 hover:underline">Edit</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RentalManagement;
