import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ImageGenerator/ImageGenerator.css';
import ai from '../../assets/ai.png';
import { FaDownload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Navbar2 from '../Navbar2';
import Footer from '../Footer';

const ImageGenerator = () => {
  const [image_url, setImageUrl] = useState(ai);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const validateInput = (description) => {
    const attireKeywords = ['dress', 'gown', 'suit', 'attire', 'outfit', 'barong', 'tuxedo', 'costume'];
    return attireKeywords.some(keyword => description.toLowerCase().includes(keyword));
  };

  const generateImage = async () => {
    const description = inputRef.current.value;

    if (description === "" || !validateInput(description)) {
      alert('Please provide a valid description of attire.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch('https://amarabelliana-back-end.vercel.app/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: description }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate image.');
      }

      const result = await response.json();
      setImageUrl(result.imageUrl);
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to generate image.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=''>
      <Navbar2 />
      <div className='header font-bold mt-40 text-center mb-3'>
        Ai<span className="text-yellow-500">Generator</span>
      </div>
      <div className='flex items-center justify-center'>
        <div className='img-loading w-full max-w-md'>
          <form className='border rounded-lg overflow-hidden shadow-md -ml-[1px]'>
            <div className='image'>
              <img src={image_url} alt='Generated' className="w-full h-auto" />
            </div>
            <div className='loading-bar-container'>
              <div className={`${loading ? 'bg-[#ffd000]' : 'bg-gray-300'} h-2 transition-all duration-300`} style={{ width: '100%' }}></div>
              {loading && <div className="loading-text text-black mt-1 text-center">Loading...</div>}
            </div>
          </form>
        </div>
      </div>

      <div className='flex items-center justify-center mt-3 mb-3'>
        <div className='search-box w-full max-w-md'>
          <input 
            type='text' 
            ref={inputRef} 
            className='search-input border rounded-lg p-2 w-full' 
            placeholder='Description...' 
          />
        </div>
      </div>

      <div className='flex items-center justify-center mb-3'>
        <div 
          className='generate-btn bg-black text-white hover:text-black font-bold py-2 px-4 rounded-lg mt-4 cursor-pointer hover:bg-[#ffd000] transition-colors'
          onClick={generateImage}
        >
          Generate
        </div>
      </div>


      <div className='flex items-center justify-center mb-52'>
        {/* Download icon for the generated image */}
        {image_url !== ai && (
          <button
            onClick={async () => {
              try {
                const response = await fetch(image_url);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = `generated_image_${Date.now()}.png`;
                link.click();

                URL.revokeObjectURL(url);
              } catch (error) {
                console.error('Download failed:', error);
              }
            }}
            className="mt-4 text-green-500 hover:text-green-600 transition-colors text-2xl"
            title="Download Image"
          >
            <FaDownload />
          </button>
        )}
      </div>


      <div className='flex items-center justify-center mb-20'>
        <div className="mt-6 text-gray-600 text-sm">
          Powered by: <a href="https://replicate.com" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Replicate</a>
        </div>
      </div>


      {/* Footer fixed at the bottom */}
      <Footer />
    </div>
  );
};

export default ImageGenerator;
