// StaffDetailModal.js
import React, { useState } from 'react';
import { auth } from '../pages/firebase'; // Import Firebase auth

const StaffDetailModal = ({ staff, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Handle password change
  const handleChangePassword = async () => {
    const user = auth.currentUser; // Get the current user

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await user.updatePassword(newPassword); // Update password in Firebase
      setSuccess('Password changed successfully!');
      setError('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setError('Failed to change password. Please try again.');
      setSuccess('');
      console.error('Error changing password:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white rounded-lg shadow-lg p-8 w-96">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 border-b pb-2">Staff Details</h2>
        <div className="mb-4">
          <p className="text-gray-600"><strong>Name:</strong> {staff.displayName}</p>
          <p className="text-gray-600"><strong>Email:</strong> {staff.email}</p>
          <p className="text-gray-600"><strong>ID:</strong> {staff.id}</p>
          <p className="text-gray-600"><strong>Role:</strong> {staff.role || 'Staff'}</p>
        </div>
        
        <h3 className="text-lg font-semibold mb-4 text-gray-800">Change Password</h3>
        <div className="mb-4">
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="p-3 border border-gray-300 rounded-md w-full mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="p-3 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {error && <p className="text-red-500 text-sm">{error}</p>}
        {success && <p className="text-green-500 text-sm">{success}</p>}
        
        <div className="flex justify-between mt-6">
          <button
            onClick={handleChangePassword}
            className="p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
          >
            Change Password
          </button>
          <button
            onClick={onClose}
            className="p-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default StaffDetailModal;
