import React from 'react';

const UserDetailModal = ({ user, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80">
      <div className="bg-white rounded-lg shadow-lg p-8 w-96 transition-transform transform hover:scale-105">
        <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b pb-2">Customer Details</h2>
        <div className="mb-6 space-y-2">
          <p className="text-gray-700">
            <strong>Name:</strong> {user.firstName} {user.middleName} {user.lastName}
          </p>
          <p className="text-gray-700">
            <strong>Email:</strong> {user.email}
          </p>
          <p className="text-gray-700">
            <strong>Phone Number:</strong> {user.phoneNumber || 'N/A'}
          </p>
          <p className="text-gray-700">
            <strong>ID Image:</strong>
            <a href={user.idImageUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              View ID Image
            </a>
          </p>
        </div>
        
        <div className="flex justify-end mt-6">
          <button
            onClick={onClose}
            className="p-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetailModal;
