import React, { useState } from 'react';
import logo from '../assets/logo.png';
import { jsPDF } from 'jspdf';
import { Link } from 'react-router-dom';

const Invoice = () => {
  const [items, setItems] = useState([{ description: '', quantity: 1, rate: 0 }]);
  const [companyDetails, setCompanyDetails] = useState({
    yourName: '',
    address: '',
    cityStateZip: '',
  });
  const [billToDetails, setBillToDetails] = useState({
    clientName: '',
    clientAddress: '',
    clientCityStateZip: '',
  });
  const [invoiceNumber, setInvoiceNumber] = useState('OR-1');
  const [invoiceDate, setInvoiceDate] = useState('2024-10-28');
  const [notes, setNotes] = useState(''); // State for notes
  const vatRate = 0.12; // 12% VAT

  const handleItemChange = (index, key, value) => {
    const newItems = [...items];
    newItems[index][key] = value;
    setItems(newItems);
  };

  const handleCompanyDetailChange = (key, value) => {
    setCompanyDetails((prev) => ({ ...prev, [key]: value }));
  };

  const handleBillToDetailChange = (key, value) => {
    setBillToDetails((prev) => ({ ...prev, [key]: value }));
  };

  const addItem = () => {
    setItems([...items, { description: '', quantity: 1, rate: 0 }]);
  };

  const deleteItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const subtotal = items.reduce((sum, item) => sum + item.quantity * item.rate, 0);
  const vat = subtotal * vatRate;
  const total = subtotal + vat;

  const downloadPDF = () => {
    const doc = new jsPDF();
    const logoWidth = 40; // Logo width
    const logoHeight = 40; // Logo height

    // Add logo to PDF
    doc.addImage(logo, 'PNG', 10, 10, logoWidth, logoHeight);

    // Company Details
    doc.setFontSize(12);
    doc.text("Amarabelliana's Gowns & Events", 60, 20);
    doc.text(companyDetails.yourName, 60, 30);
    doc.text(companyDetails.address, 60, 35);
    doc.text(companyDetails.cityStateZip, 60, 40);
    doc.text("Philippines", 60, 45);

    // Invoice Details
    doc.setFontSize(14);
    doc.text('OFFICIAL E-RECEIPT', 14, 55);
    doc.text(`OR No.: ${invoiceNumber}`, 14, 65);
    doc.text(`Date: ${invoiceDate}`, 14, 70);

    // Bill To Details
    doc.text('Bill To:', 14, 80);
    doc.text(billToDetails.clientName, 14, 85);
    doc.text(billToDetails.clientAddress, 14, 90);
    doc.text(billToDetails.clientCityStateZip, 14, 95);
    doc.text("Philippines", 14, 100);

    // Table Header
    doc.text('Item Description', 14, 110);
    doc.text('Qty', 120, 110);
    doc.text('Rate', 140, 110);
    doc.text('Amount', 160, 110);

    // Table Rows
    items.forEach((item, index) => {
      const y = 120 + (index * 10);
      doc.text(item.description, 14, y);
      doc.text(item.quantity.toString(), 120, y);
      doc.text(item.rate.toFixed(2), 140, y);
      doc.text((item.quantity * item.rate).toFixed(2), 160, y);
    });

    // Totals
    const totalY = 120 + (items.length * 10) + 10;
    doc.text(`Subtotal: ${subtotal.toFixed(2)}`, 14, totalY);
    doc.text(`VAT (12%): ${vat.toFixed(2)}`, 14, totalY + 10);
    doc.text(`Total: ${total.toFixed(2)}`, 14, totalY + 20);

    doc.text('Notes:', 14, totalY + 40);
    doc.text(notes, 14, totalY + 50); // Use the state variable for notes

    // Save PDF with the OR number as the filename
    doc.save(`${invoiceNumber}.pdf`);

    // Reset all fields after downloading the PDF
    resetFields();
  };

  const resetFields = () => {
    setItems([{ description: '', quantity: 1, rate: 0 }]);
    setCompanyDetails({
      yourName: '',
      address: '',
      cityStateZip: '',
    });
    setBillToDetails({
      clientName: '',
      clientAddress: '',
      clientCityStateZip: '',
    });
    setInvoiceNumber('OR-1');
    setInvoiceDate('2024-10-28');
    setNotes('');
  };

  return (
    <div className="max-w-2xl mx-auto p-8 bg-white shadow-lg space-y-8">
    <Link to="/staff-dashboard" className="absolute top-4 left-4 text-black font-semibold hover:underline"> {/* Back button */}
      Back
    </Link>
      <h1 className="text-2xl font-bold mb-4 text-center">OFFICIAL E-RECEIPT</h1>

      <div className="gap-8 mb-8">
        <div className="space-y-4">
          <div className="flex items-start space-x-4">
            <img src={logo} alt="Company Logo" className="h-16 w-16" />
            <div className="space-y-2">
              <p className="font-bold text-lg">Amarabelliana's Gowns & Events</p>
              <input
                type="text"
                className="border w-full px-2 py-1 mb-1"
                placeholder="Your Name"
                value={companyDetails.yourName}
                onChange={(e) => handleCompanyDetailChange('yourName', e.target.value)}
              />
              <input
                type="text"
                className="border w-full px-2 py-1 mb-1"
                placeholder="Address"
                value={companyDetails.address}
                onChange={(e) => handleCompanyDetailChange('address', e.target.value)}
              />
              <input
                type="text"
                className="border w-full px-2 py-1 mb-1"
                placeholder="City, State Zip"
                value={companyDetails.cityStateZip}
                onChange={(e) => handleCompanyDetailChange('cityStateZip', e.target.value)}
              />
              <p className="text-gray-600">Philippines</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <div className="text-left">
          <p className="font-bold text-xl">RECEIPT</p>
          <div className="flex flex-col mt-2 space-y-2">
            <div className="flex justify-between">
              <span>OR No.:</span>
              <input
                type="text"
                className="border w-[450px] px-2 py-1"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            </div>
            <div className="flex justify-between">
              <span>Date:</span>
              <input
                type="date"
                className="border w-[450px] px-2 py-1"
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className="font-bold">Bill To:</p>
        <input
          type="text"
          className="border w-full px-2 py-1 mb-1"
          placeholder="Client's Name"
          value={billToDetails.clientName}
          onChange={(e) => handleBillToDetailChange('clientName', e.target.value)}
        />
        <input
          type="text"
          className="border w-full px-2 py-1 mb-1"
          placeholder="Address"
          value={billToDetails.clientAddress}
          onChange={(e) => handleBillToDetailChange('clientAddress', e.target.value)}
        />
        <input
          type="text"
          className="border w-full px-2 py-1 mb-1"
          placeholder="City, State Zip"
          value={billToDetails.clientCityStateZip}
          onChange={(e) => handleBillToDetailChange('clientCityStateZip', e.target.value)}
        />
        <p className="text-gray-600">Philippines</p>
      </div>

      <table className="w-full border-collapse mb-6">
        <thead>
          <tr>
            <th className="border px-4 py-2 text-left">Item Description</th>
            <th className="border px-4 py-2 text-center">Qty</th>
            <th className="border px-4 py-2 text-center">Rate</th>
            <th className="border px-4 py-2 text-right">Amount</th>
            <th className="border px-4 py-2 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">
                <input
                  type="text"
                  className="border w-full px-2 py-1"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                  placeholder="Enter item name/description"
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  type="number"
                  className="border w-full px-2 py-1"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value))}
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <input
                  type="number"
                  className="border w-full px-2 py-1"
                  value={item.rate}
                  onChange={(e) => handleItemChange(index, 'rate', parseFloat(e.target.value))}
                />
              </td>
              <td className="border px-4 py-2 text-right">
                {(item.quantity * item.rate).toFixed(2)}
              </td>
              <td className="border px-4 py-2 text-center">
                <button
                  onClick={() => deleteItem(index)}
                  className="text-red-500"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        onClick={addItem}
        className="text-green-500 font-bold mb-6 flex items-center"
      >
        + Add Line Item
      </button>

      <div className="flex justify-end mb-4">
        <div className="w-1/3 space-y-2">
          <div className="flex justify-between">
            <span>Sub Total</span>
            <span>{subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span>VAT (12%)</span>
            <span>{vat.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-bold border-t border-gray-200 pt-2">
            <span>Total</span>
            <span>${total.toFixed(2)}</span>
          </div>
        </div>
      </div>

      <div>
        <label className="font-bold">Notes:</label>
        <textarea
          className="border border-gray-300 w-full h-24 p-2"
          value={notes}
          onChange={(e) => setNotes(e.target.value)} // Update notes state
        />
      </div>

      <div className="mb-6">
        <p className="text-center italic text-xs underline">THIS OFFICIAL RECEIPT SHALL BE VALID FOR 5 YEARS FROM THE DATE OF ATP</p>
      </div>

      <div className="mt-8 text-center">
        <button
          className="bg-green-500 text-white px-4 py-2"
          onClick={downloadPDF}
        >
          Download Receipt as PDF
        </button>
      </div>
    </div>
  );
};  

export default Invoice;