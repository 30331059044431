import React, { useEffect, useState } from 'react';
import { db } from '../pages/firebase'; // Adjust the import according to your firebase configuration
import {  getDocs, updateDoc, } from 'firebase/firestore';
import RentedAttireModal from './RentedAttireModal';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { doc, getDoc, getFirestore, addDoc, collection } from 'firebase/firestore'; // Import Firestore functions



const RentedAttires = () => {
  const [rentedAttires, setRentedAttires] = useState([]);
  const [selectedAttire, setSelectedAttire] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notifications, setNotifications] = useState([]); // State for notifications
  const [newNotification, setNewNotification] = useState(false); // Track new notifications

  const [filter, setFilter] = useState('all'); // Filter state

  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchRentedAttires = async () => {
      try {
        const rentedAttiresCollection = collection(db, 'rentals'); // Use your collection name
        const rentedAttiresSnapshot = await getDocs(rentedAttiresCollection);
        const rentedAttiresList = rentedAttiresSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRentedAttires(rentedAttiresList);
      } catch (error) {
        console.error("Error fetching rented attires: ", error);
      }
    };

    fetchRentedAttires();
  }, []);

  // Function to update the status of the rented attire in Firestore
  const updateStatus = async (attireId, newStatus) => {
    try {
      const attireRef = doc(db, 'rentals', attireId);
      await updateDoc(attireRef, {
        status: newStatus,
      });

      //getDoc attireRef
      const attireDoc = await getDoc(attireRef);
      const attireData = attireDoc.data();
      console.log(attireData);

      // Update the local state to reflect the change immediately
      setRentedAttires(prevAttires =>
        prevAttires.map(attire =>
          attire.id === attireId ? { ...attire, status: newStatus } : attire
        )
      );

      // If approved, add a new notification
      if (newStatus === 'approved') {
        const newNotification = {
          created_at: new Date().toISOString(),
          title: 'Rental Request Approved',
          message:  `Your rental request for ${attireData.imageUrls[0].attireName} has been approved.`,
          status: 'approved',
          isSeen: false,
          user: 'staff',
          userFrom: attireData.userId,
        }
  
        await addDoc(collection(getFirestore(), "notifications"), newNotification)
      }else if (newStatus === 'denied') {
        const newNotification = {
          created_at: new Date().toISOString(),
          title: 'Rental Request Denied',
          message:  `Your rental request for ${attireData.imageUrls[0].attireName} has been denied.`,
          status: 'denied',
          isSeen: false,
          user: 'staff',
          userFrom: attireData.userId,
        }
  
        await addDoc(collection(getFirestore(), "notifications"), newNotification)

        const collectionName = attireData.imageUrls[0].category.includes('Evening') ? 'evenings' :
        attireData.imageUrls[0].category.includes('Cocktail') ? 'cocktails' :
        attireData.imageUrls[0].category.includes('Ball') ? 'balls' :
        attireData.imageUrls[0].category.includes('Suit') ? 'suits' :
        attireData.imageUrls[0].category.includes('Kids') ? 'kids' :
              'barongs';

          updateDoc(doc(getFirestore(), collectionName, attireData.imageUrls[0].id), {
            isRented: false,
          });
      }else if(newStatus === 'returned'){
        const collectionName = attireData.imageUrls[0].category.includes('Evening') ? 'evenings' :
        attireData.imageUrls[0].category.includes
        ('Cocktail') ? 'cocktails' :
        attireData.imageUrls[0].category.includes('Ball') ? 'balls' :
        attireData.imageUrls[0].category.includes('Suit') ? 'suits' :
        attireData.imageUrls[0].category.includes('Kids') ? 'kids' :
              'barongs';

          updateDoc(doc(getFirestore(), collectionName, attireData.imageUrls[0].id), {
            isRented: false,
          });


      }
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };

  const openModal = (attire) => {
    setSelectedAttire(attire);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAttire(null);
  };

  const handleNotificationClick = () => {
    setNewNotification(false); // Reset new notification indicator
  };

  return (
    <div className="container mx-auto p-8">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)} // Go back to the previous page
        className="px-4 py-2 mb-6 bg-gray-300 hover:bg-gray-400 text-black rounded"
      >
        Back
      </button>
      
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">Rented Attires</h1>
      <select className="mb-6 px-4 py-2 bg-gray-200 rounded" value={filter} onChange={(e) => setFilter(e.target.value)}>
        <option value="all">All</option>
        <option value="pending">Pending</option>
        <option value="approved">Approved</option>
        <option value="denied">Denied</option>
        <option value="returned">Returned</option>
      </select>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {rentedAttires
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by created_at in descending order
        .filter(attire => filter === 'all' || attire.status === filter)
        .map(attire => (
          <div key={attire.id} className="bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 overflow-hidden">
            <div className="relative">
              {/* Render Images */}
              {attire.imageUrls && attire.imageUrls.length > 0 && (
                <div className="mb-4">
                 
                  <div className="grid grid-cols-2 gap-2">
                    {attire.imageUrls.map((image, index) => (
                      <img key={index} src={image.src ? image.src : image.image} alt={`Rented Attire ${index + 1}`} className="w-full h-auto rounded" />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="p-6">
              <h2 className="text-2xl font-semibold text-gray-800">{attire.attireName}</h2>
              <p className="text-gray-600 mt-2">Renter: <span className="font-medium">{attire.name}</span></p>
              <p className="text-gray-600 mt-2">Application Date: <span className="font-medium">{new Date(attire.created_at).toLocaleDateString().split('T')[0]}</span></p>
              <p className="text-gray-600 mt-2">
                Rent Date: <span className="font-medium">{new Date(attire.date).toLocaleDateString().split('T')[0]}</span>
              </p>
              <p className="text-gray-600 mt-2">
                Pick-up Date: <span className="font-medium">{new Date(new Date(attire.date).setDate(new Date(attire.date).getDate() - 2)).toLocaleDateString().split('T')[0]}</span>
              </p>
              <p className="text-gray-600 mt-2">
                Return Date: <span className="font-medium">{new Date(new Date(attire.date).setDate(new Date(attire.date).getDate() + 2)).toLocaleDateString().split('T')[0]}</span>
              </p>
              {/* Display Approval Status */}
              {attire.status && (
                <p className={`mt-2 font-medium ${attire.status === 'approved' ? 'text-green-500' : 'text-red-500'}`}>
                  Status: {attire.status.charAt(0).toUpperCase() + attire.status.slice(1)}
                </p>
              )}
              <div className="mt-4 flex space-x-4">
                {/* Approve Button */}
                {(attire.status === 'pending') && (
                  <button
                    onClick={() => updateStatus(attire.id, 'approved')}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 ease-in-out"
                  >
                    Approve
                  </button>
                )}
                {/* Deny Button */}
                {(attire.status === 'pending') && (
                  <button
                    onClick={() => updateStatus(attire.id, 'denied')}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300 ease-in-out"
                  >
                    Deny
                  </button>
                )}
              </div>
              <button
                onClick={() => openModal(attire)}
                className="mt-4 px-6 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded transition duration-300 ease-in-out"
              >
                View Details
              </button>
              {attire.status === 'approved' && (
                <button
                  onClick={() => updateStatus(attire.id, 'returned')}
                  className="mt-4 px-6 py-2 bg-green-600 hover:bg-green-700 text-white rounded transition duration-300 ease-in-out ms-5"
                >
                  Mark as Returned
                </button>
              )}

            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <RentedAttireModal attire={selectedAttire} onClose={closeModal} />
      )}

      {/* Notification Pop-up */}
      {newNotification && (
        <div className="fixed top-4 right-4 bg-green-500 text-white p-4 rounded shadow-lg">
          <p>{notifications[notifications.length - 1]?.message}</p>
          <button className="mt-2 text-white underline" onClick={handleNotificationClick}>
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default RentedAttires;
