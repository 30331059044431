import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../pages/firebase';
import { useNavigate } from 'react-router-dom';

const StaffProfile = () => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(null);
  const [preview, setPreview] = useState(null);
  const [staffName, setStaffName] = useState('');
  const [email, setEmail] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formValues, setFormValues] = useState({ name: '', profilePicture: null });

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);
        setProfilePicture(user.photoURL);
        const name = user.displayName || 'Staff';
        setStaffName(name);

        const staffDoc = await getDoc(doc(db, 'staffProfiles', user.uid));
        if (staffDoc.exists()) {
          const data = staffDoc.data();
          setStaffName(data.name || name);
          setFormValues({
            name: data.name || name,
            profilePicture: user.photoURL || null
          });
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const staffRef = doc(db, 'staffProfiles', user.uid);
      try {
        await updateDoc(staffRef, {
          name: formValues.name,
          profilePicture: preview || profilePicture
        });
        setStaffName(formValues.name);
        setProfilePicture(preview || profilePicture);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-700 flex items-center justify-center py-10">
      <div className="container mx-auto p-8 bg-gray-800 rounded-lg shadow-xl border border-gray-600 max-w-md">
        <button
          onClick={() => navigate(-1)}
          className="mb-6 text-gray-300 hover:text-gray-100"
        >
          &larr; Back
        </button>
        <h1 className="text-3xl font-semibold mb-8 text-center text-yellow-300">Staff Profile</h1>
        <div className="flex flex-col items-center space-y-6">
          <label
            htmlFor="staffProfilePicture"
            className="relative group cursor-pointer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="relative w-32 h-32 rounded-full overflow-hidden border-4 border-yellow-300">
              {preview || profilePicture ? (
                <img
                  src={preview || profilePicture}
                  alt="Staff Profile Preview"
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full text-3xl font-bold text-yellow-300">
                  {staffName.charAt(0).toUpperCase()}
                </div>
              )}
              {isHovered && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center text-white text-sm font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {preview ? 'Change Profile' : 'Upload Profile'}
                </div>
              )}
            </div>
            <input
              type="file"
              id="staffProfilePicture"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </label>
          <div className="w-full space-y-4">
            {isEditing ? (
              <>
                <div className="mb-4">
                  <label className="block text-gray-300">Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                    className="block w-full p-3 border border-gray-500 rounded bg-gray-700 text-gray-200 focus:outline-none focus:ring focus:ring-yellow-500"
                  />
                </div>
                <button
                  onClick={handleSave}
                  className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="w-full py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <div className="text-center">
                  <p className="text-xl font-semibold text-gray-100">{staffName}</p>
                  <p className="text-sm text-gray-400">Email: {email}</p>
                </div>
                <button
                  onClick={() => setIsEditing(true)}
                  className="w-full mt-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                >
                  Edit Profile
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffProfile;
