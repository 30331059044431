import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Chatbot from './ChatBot';

// Import the Navbar2 component
import Navbar2 from './Navbar2';

import one from '../assets/one.jpg';
import two from '../assets/two.jpg';
import three from '../assets/three.jpg';
import four from '../assets/Four.jpg';
import five from '../assets/five.jpg';

import n1 from '../assets/n1.jpg';
import n2 from '../assets/n2.jpg';
import n3 from '../assets/n3.jpg';
import n4 from '../assets/n4.jpg';
import n5 from '../assets/n5.jpg';
import n6 from '../assets/n6.jpg';

import p1 from '../assets/p1.jpg';
import p2 from '../assets/p2.jpg';
import p3 from '../assets/p3.jpg';
import p4 from '../assets/p4.jpg';
import p5 from '../assets/p5.jpg';
import p6 from '../assets/p6.jpg'; 

import ctg from '../assets/ctg.png';
import category2 from '../assets/category2.jpg';
import category3 from '../assets/category3.jpg';
import category4 from '../assets/category4.jpg';
import barong1 from '../assets/barong1.png';
import kids1 from '../assets/kids1.png';

import Footer from '../components/Footer';

const UserDashboard = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userInitial, setUserInitial] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        const initial = user.email.charAt(0).toUpperCase();
        setUserInitial(initial);
      }
    });

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogoutModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      unsubscribe();
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Logout error: ', error);
      });
  };

  const newArrivals = [
    { image: n1, title: 'Mens Suit #MS0015' },
    { image: n2, title: 'Barong Tagalog #BT0004' },
    { image: n3, title: 'Cocktail Gown #CG0008' },
    { image: n4, title: 'Ball Gown #BG0013' },
    { image: n5, title: 'Ball Gown #BG0014' },
    { image: n6, title: 'Mens Suit #MS0016' },
  ];

  const popularChoices = [
    { image: p1, title: 'Evening Gown #EG0005' },
    { image: p2, title: 'Evening Gown #EG0006' },
    { image: p3, title: 'Mens Suit #MS0012' },
    { image: p4, title: 'Ball Gown #BG0012' },
    { image: p5, title: 'Ball Gown #BG0011' },
    { image: p6, title: 'Mens Suit #MS0009' },
  ];

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Navbar2 Component */}
      <Navbar2 />

      {/* Main Content */}
      <div className="mt-20">
        {/* Full-size images section */}
        <div className="relative overflow-hidden mt-2">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0">
            <img src={one} alt="Image 1" className="w-full h-auto object-cover" />
            <img src={two} alt="Image 2" className="w-full h-auto object-cover" />
            <img src={three} alt="Image 3" className="w-full h-auto object-cover" />
            <img src={five} alt="Image 4" className="w-full h-auto object-cover" />
          </div>
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 p-4">
            <h2
              className="text-white text-[40px] md:text-[60px] lg:text-[80px] font-bold -tracking-tighter text-center"
              style={{
                fontFamily: '"Playfair Display", serif',
                textShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)',
              }}
            >
              WELCOME TO THE<br />FASHION WORLD.
            </h2>
          </div>
        </div>

        {/* Chatbot */}
        <Chatbot />

        {/* New Arrivals Section */}
        <div className="flex items-center justify-center mt-14 mb-14">
          <div className="h-[1px] w-1/4 bg-black"></div>
          <h1 className="text-black tracking-wider font-bold text-[25px] mx-4">NEW ARRIVALS</h1>
          <div className="h-[1px] w-1/4 bg-black"></div>
        </div>

        <div className="w-3/4 m-auto">
          <Slider {...settings}>
            {newArrivals.map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <img src={item.image} className="w-80 h-60 object-contain block m-auto" />
                <div className="p-6 bg-[#ffd000]">
                  <p className="text-black font-semibold text-center">{item.title}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="text-center mt-14">
          <Link
            to="/collections2"
            className="text-[13px] text-black px-8 py-2 border border-black hover:bg-black hover:text-white transition"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} // Scroll to top
          >
            SEE MORE
          </Link>
        </div>


        {/* Popular Choices Section */}
        <div className="flex items-center justify-center mt-28 mb-14">
          <div className="h-[1px] w-1/4 bg-black"></div>
          <h1 className="text-black tracking-wider font-bold text-[25px] mx-4">POPULAR CHOICES</h1>
          <div className="h-[1px] w-1/4 bg-black"></div>
        </div>

        {/* Popular Choices Images */}
        <div className="w-3/4 m-auto">
          <Slider {...settings}>
            {popularChoices.map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <img src={item.image} className="w-80 h-60 object-contain block m-auto" />
                <div className="p-6 bg-[#ffd000]">
                  <p className="text-black font-semibold text-center">{item.title}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="text-center mt-14">
          <Link
            to="/collections2"
            className="text-[13px] text-black px-8 py-2 border border-black hover:bg-black hover:text-white transition"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} // Scroll to top
          >
            SEE MORE
          </Link>
        </div>


        {/* Categories Section */}
        <div className="flex items-center justify-center mt-14 mb-14">
          <div className="h-[1px] w-1/4 bg-black"></div>
          <h1 className="text-black tracking-wider font-bold text-[25px] mx-4">CATEGORIES</h1>
          <div className="h-[1px] w-1/4 bg-black"></div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-10 w-3/4 m-auto mb-20">
          <Link to="/collections2#cocktail-gown-section" className="flex flex-col items-center">
            <div className="w-32 h-32 bg-white shadow-md rounded-full overflow-hidden">
              <img src={ctg} alt="Category 1" className="w-full h-full object-cover" />
            </div>
            <p className="font-semibold text-[14px] text-center mt-4">COCKTAIL GOWN</p>
          </Link>

          {/* Other Categories */}
          <Link to="/collections2#suit-section" className="flex flex-col items-center">
            <div className="w-32 h-32 bg-white shadow-md rounded-full overflow-hidden">
              <img src={category2} alt="Category 2" className="w-full h-full object-cover" />
            </div>
            <p className="font-semibold text-[14px] text-center mt-4">SUITS</p>
          </Link>

          <Link to="/collections2#ball-gown-section" className="flex flex-col items-center">
            <div className="w-32 h-32 bg-white shadow-md rounded-full overflow-hidden">
              <img src={category3} alt="Category 3" className="w-full h-full object-cover" />
            </div>
            <p className="font-semibold text-[14px] text-center mt-4">BALL GOWN</p>
          </Link>

          <Link to="/collections2#evening-gown-section" className="flex flex-col items-center">
            <div className="w-32 h-32 bg-white shadow-md rounded-full overflow-hidden">
              <img src={category4} alt="Category 4" className="w-full h-full object-cover" />
            </div>
            <p className="font-semibold text-[14px] text-center mt-4">EVENING GOWN</p>
          </Link>

          <Link to="/collections2#barong-section" className="flex flex-col items-center">
            <div className="w-32 h-32 bg-white shadow-md rounded-full overflow-hidden">
              <img src={barong1} alt="Barong" className="w-full h-full object-cover" />
            </div>
            <p className="font-semibold text-[14px] text-center mt-4">BARONG TAGALOG</p>
          </Link>

          <Link to="/collections2#kids-section" className="flex flex-col items-center">
            <div className="w-32 h-32 bg-white shadow-md rounded-full overflow-hidden">
              <img src={kids1} alt="Kids" className="w-full h-full object-cover" />
            </div>
            <p className="font-semibold text-[14px] text-center mt-4">KIDS WEAR</p>
          </Link>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UserDashboard;
