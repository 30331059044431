import React, { useState, useEffect } from 'react';
import { db } from '../pages/firebase'; // Adjust import based on your Firebase config location
import { collection, addDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { auth } from '../pages/firebase'; // Adjust this import based on your Firebase Auth location
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon
import Footer from './Footer';
import Navbar2 from './Navbar2';

const AttireFeedback = () => {
  const [rating, setRating] = useState(0); // Star rating
  const [details, setDetails] = useState('');
  const [ideas, setIdeas] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [userName, setUserName] = useState(''); // State for storing user's name
  const [attireType, setAttireType] = useState(''); // State for storing selected attire type

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserName(user.displayName || user.email); // Fallback to email if displayName is not available
    }
  }, []);

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rating === 0 || !details || !attireType) return;

    console.log({ rating, details, ideas, userName, attireType }); // Log feedback details

    setLoading(true); // Set loading state to true

    try {
      // Add feedback without attireId
      await addDoc(collection(db, 'attireFeedbacks'), { // Changed to only 'attireFeedbacks'
        rating,
        details,
        ideas,
        userName, // Include user's name in the feedback
        createdAt: new Date(),
        attireType, // Include the selected attire type
      });
      setSuccessMessage('Feedback submitted successfully!');
      setRating(0);
      setDetails('');
      setIdeas('');
      setAttireType(''); // Reset attire type after submission
      setError('');
    } catch (error) {
      console.error('Error adding feedback:', error);
      setError('Failed to submit feedback. Please try again.');
      setSuccessMessage('');
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
  <Navbar2 />
  <div className="max-w-lg mx-auto p-6 bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 mt-[200px] mb-[200px]">
    <div className="p-8 bg-white shadow-lg rounded-lg max-w-lg w-full">
      <h3 className="text-2xl font-semibold mb-6 text-center">Feedback Form</h3>

      {error && <div className="text-red-600 mb-4 text-center font-medium">{error}</div>}
      {successMessage && <div className="text-green-600 mb-4 text-center font-medium">{successMessage}</div>}

      <form onSubmit={handleSubmit} className="space-y-4 -ml-[1px]">
        <div className="flex justify-center mb-4">
          {[...Array(5)].map((_, index) => (
            <Star
              key={index}
              filled={index < rating}
              onClick={() => handleStarClick(index)}
            />
          ))}
        </div>

        <textarea
          value={ideas}
          onChange={(e) => setIdeas(e.target.value)}
          className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
          placeholder="Description or ideas for improvement?"
          rows="3"
        />

        {/* Attire Type Selection */}
        <div>
          <label className="block mb-2 font-semibold">Select Attire Type</label>
          <select
            value={attireType}
            onChange={(e) => setAttireType(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
            required
          >
            <option value="" disabled>Select an attire type</option>
            <option value="Gown">Gown</option>
            <option value="Suit">Suit</option>
            <option value="Barong">Barong</option>
          </select>
        </div>

        <button
          type="submit"
          className={`w-full mt-4 px-6 py-3 ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'} text-white rounded-lg transition duration-200 shadow-md hover:shadow-lg`}
          disabled={loading} // Disable button while loading
        >
          {loading ? 'Submitting...' : 'Submit Feedback'}
        </button>
      </form>
    </div>
  </div>
  <Footer />
</div>

  );
};

// Star component to display the star rating
const Star = ({ filled, onClick }) => (
  <span 
    onClick={onClick}
    className={`cursor-pointer text-4xl transition duration-200 ${filled ? 'text-yellow-500 hover:text-yellow-600' : 'text-gray-300 hover:text-yellow-400'}`}
  >
    ★
  </span>
);

export default AttireFeedback;
