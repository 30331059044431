import React, { useState, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { from: 'bot', text: 'Hello! How can I help you today?' },
  ]);
  const [showQuestions, setShowQuestions] = useState(true);
  const [loading, setLoading] = useState(false);
  const messagesRef = useRef(null);

  const questions = [
    'What types of gowns do you have available for rent?',
    'What sizes do your suits come in?',
    'Do you offer alterations for rented items?',
    'How far in advance should I book my rental?',
    'What is your cancellation policy?',
    'Are there any discounts for bulk rentals?',
    'What payment methods do you accept?',
    'Can I try on the gowns before renting?',
  ];

  const responses = {
    'What types of gowns do you have available for rent?': 'We have a wide variety of gowns, including evening gowns, cocktail dresses, and ball gowns. You can check our collections online or visit our store for more options.',
    'What sizes do your suits come in?': 'Our suits are available in sizes ranging from small to XXL. We also offer custom fittings for a perfect fit.',
    'Do you offer alterations for rented items?': 'Yes, we do provide alteration services to ensure the perfect fit for your rented items. Additional charges may apply.',
    'How far in advance should I book my rental?': 'We recommend booking your rental at least 2-4 weeks in advance to ensure availability, especially for popular styles.',
    'What is your cancellation policy?': 'You can cancel your rental up to 48 hours before your pickup date for a full refund. Cancellations made less than 48 hours will incur a cancellation fee.',
    'Are there any discounts for bulk rentals?': 'Yes, we offer discounts for bulk rentals. Please contact us for more details about your specific needs.',
    'What payment methods do you accept?': 'We accept cash, credit/debit cards, and mobile payment options. You can choose what works best for you.',
    'Can I try on the gowns before renting?': 'Absolutely! We encourage you to try on the gowns in our store. Please schedule an appointment for a fitting.',
  };

  const handleToggleChat = () => setIsOpen(!isOpen);

  const handleSelectQuestion = (question) => {
    setMessages((prev) => [
      ...prev,
      { from: 'user', text: question },
    ]);
    setShowQuestions(false);

    setLoading(true);
    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { from: 'bot', text: responses[question] },
      ]);
      setLoading(false);

      setTimeout(() => {
        if (messagesRef.current) {
          messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
      }, 100);
    }, 1000);
  };

  const handleResetChat = () => {
    setMessages([{ from: 'bot', text: 'Hello! How can I help you today?' }]);
    setShowQuestions(true);
  };

  return (
    <div className="fixed bottom-6 right-6 z-[40]"> {/* Lower z-index for chatbot */}
      {isOpen ? (
        <div className="w-[28rem] h-[36rem] bg-white shadow-lg rounded-lg overflow-hidden flex flex-col">
          <header className="p-4 bg-gray-500 text-white flex justify-between items-center">
            <h2 className="text-lg">Chat with us</h2>
            <button onClick={handleToggleChat} className="text-xl">
              <AiOutlineClose />
            </button>
          </header>
          <div className="p-4 flex-grow overflow-y-auto" ref={messagesRef}>
            {messages.map((msg, index) => (
              <div key={index} className={`mb-2 flex ${msg.from === 'bot' ? 'justify-start' : 'justify-end'}`}>
                <div className={`p-2 rounded-lg max-w-[70%] break-words ${msg.from === 'bot' ? 'bg-gray-200' : 'bg-[#ffd000] text-white'}`}>
                  {msg.text}
                </div>
              </div>
            ))}
            {loading && (
              <div className="mt-4 flex justify-center">
                <span className="text-gray-500">Loading...</span>
              </div>
            )}
            {showQuestions && !loading && (
              <div className="mt-4">
                {questions.map((question, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelectQuestion(question)}
                    className="w-full p-2 my-1 bg-[#ffd000] text-black rounded-full hover:bg-[#f7de70] transition"
                  >
                    {question}
                  </button>
                ))}
              </div>
            )}
            {!showQuestions && !loading && (
              <button onClick={handleResetChat} className="mt-4 w-full p-2 bg-gray-300 text-black rounded-full hover:bg-gray-400 transition">
                Back to Questions
              </button>
            )}
          </div>
        </div>
      ) : (
        <button
          onClick={handleToggleChat}
          className="p-4 bg-gray-500 text-white rounded-full shadow-lg"
        >
          💬
        </button>
      )}
    </div>
  );
};

export default Chatbot;
