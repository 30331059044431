import React, { useEffect, useState } from 'react';
import { db } from '../pages/firebase'; // Adjust the import based on your Firebase config
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon
import { onSnapshot } from 'firebase/firestore'; // Import onSnapshot function
import toast, { Toaster } from 'react-hot-toast';

const GcashPayments = ({ paymentId, onClose }) => {
    const [data, setData] = useState(null); // State to hold payment data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(''); // Error state for handling errors

    useEffect(() => {
        if (!paymentId) {
          setError('Payment ID is not provided.');
          setLoading(false);
          return;
        }
    
        setLoading(true); // Set loading state
        setError(''); // Reset error state
    
        // Set up the onSnapshot listener
        const docRef = doc(db, 'payments', paymentId);
        const unsubscribe = onSnapshot(
          docRef,
          (docSnap) => {
            if (docSnap.exists()) {
              setData(docSnap.data()); // Update data in real time
              setError(''); // Clear any existing error
            } else {
              setError('No such payment document!');
              setData(null);
            }
            setLoading(false);
          },
          (error) => {
            console.error('Error fetching payment data: ', error);
            setError('Failed to fetch payment data. Please try again.');
            setLoading(false);
          }
        );
    
        // Clean up the listener when component unmounts or paymentId changes
        return () => unsubscribe();
      }, [paymentId]);

    if (loading) return <div className="text-center">Loading...</div>; // Display loading state
    if (error) return <div className="text-red-500 text-center">{error}</div>; // Display error message
    if (!data) return null; // Exit if no data is available

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96 max-w-lg relative"> {/* Add relative class */}
                <h3 className="text-xl font-semibold text-center text-blue-600">Payment Details</h3>
                <div className="mt-4 space-y-2">
                    <p><strong>Full Name:</strong> {data.fullName}</p>
                    <p><strong>GCash Number:</strong> {data.gcashNumber}</p>
                    <p><strong>GCash Account Name:</strong> {data.gcashAccountName}</p>
                    <p><strong>Amount Sent:</strong> {data.paymentAmount}</p>
                    <p><strong>Payment Date:</strong> {data.paymentDate}</p>
                    <p><strong>Payment Time:</strong> {data.paymentTime}</p>
                    <p><strong>Reference Number:</strong> {data.referenceNumber}</p>
                    {data.proofOfPayment && (
                        <div>
                            <strong>Proof of Payment:</strong>
                            <a href={data.proofOfPayment} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                                View Proof
                            </a>
                        </div>
                    )}
                </div>
                <div className="flex justify-between mt-4">
                    <button
                        onClick={onClose}
                        className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
                    >
                        Close
                    </button>
                   {data.status === 'pending' && <button className='py-2 px-4 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-200' 
                        onClick={() => {
                            //update payment status to 'confirmed' in Firestore
                            const docRef = doc(db, 'payments', paymentId);
                            updateDoc(docRef, {
                                status: 'confirmed'
                            }).then(() => {
                                toast.success('Payment confirmed!'); // Display success message
                                onClose(); // Close modal
                            }).catch((error) => {
                                console.error('Error updating payment status: ', error);
                                toast.error('An error occurred. Please try again.'); // Display error message                                                                       
                            });
                        }}>
                        Confirm
                    </button>}
                    {data.status === 'pending' && <button className='py-2 px-4 bg-red-600 text-white rounded-md hover:bg-red-700 transition duration-200' onClick={() => {
                        //update payment status to 'denied' in Firestore
                        const docRef = doc(db, 'payments', paymentId);
                        updateDoc(docRef, {
                            status: 'denied'
                        }).then(() => {
                            toast.success('Payment denied!'); // Display success message
                            onClose(); // Close modal
                        }).catch((error) => {
                            console.error('Error updating payment status: ', error);
                            toast.error('An error occurred. Please try again.'); // Display error message
                        });
                    }}>
                        Deny
                    </button>}
                </div>
            </div>
           
        </div>
    );
};

const PaymentList = () => {
    const [payments, setPayments] = useState([]); // State to hold all payment data
    const [selectedPaymentId, setSelectedPaymentId] = useState(null); // State for selected payment ID
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [error, setError] = useState(''); // State for error messages
    const [loading, setLoading] = useState(true); // Loading state
    const [filter, setFilter] = useState('all'); // Filter state
    useEffect(() => {
        setLoading(true); // Start loading
        setError(''); // Reset error state
    
        // Set up the onSnapshot listener for real-time updates
        const unsubscribe = onSnapshot(
          collection(db, 'payments'),
          (querySnapshot) => {
            const paymentsData = [];
            querySnapshot.forEach((doc) => {
              paymentsData.push({ id: doc.id, ...doc.data() }); // Push each payment document to the array
            });
            setPayments(paymentsData); // Set payments state with new data
            setLoading(false); // End loading
          },
          (error) => {
            console.error('Error fetching payments: ', error);
            setError('Failed to fetch payments. Please try again.'); // Set error message
            setLoading(false); // End loading
          }
        );
    
        // Clean up the listener when the component unmounts
        return () => unsubscribe();
      }, []); // Empty dependency array to run once on mount

    const handleShowPaymentDetails = (paymentId) => {
        setSelectedPaymentId(paymentId); // Set selected payment ID
        setShowModal(true); // Show modal
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close modal
        setSelectedPaymentId(null); // Reset selected payment ID
    };

    if (loading) return <div className="text-center">Loading payments...</div>; // Loading state
    if (error) return <div className="text-red-500 text-center">{error}</div>; // Display error message

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-center text-3xl font-bold mb-6">Payments</h1>
            <Link to="/staff-dashboard" className="absolute top-4 left-4 text-black hover:text-gray-600">
                <FaArrowLeft className="w-6 h-6" /> {/* Back icon */}
            </Link>
            <div className="flex justify-center mb-6">
                <button
                    onClick={() => setFilter('all')}
                    className={`py-2 px-4 rounded-md ${filter === 'all' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                >
                    All
                </button>
                <button
                    onClick={() => setFilter('pending')}
                    className={`py-2 px-4 rounded-md ${filter === 'pending' ? 'bg-blue-600 text-white' : 'bg-gray-200'} ml-2`}
                >
                    Pending
                </button>
                <button
                    onClick={() => setFilter('confirmed')}
                    className={`py-2 px-4 rounded-md ${filter === 'confirmed' ? 'bg-blue-600 text-white' : 'bg-gray-200'} ml-2`}
                >
                    Confirmed
                </button>
                <button
                    onClick={() => setFilter('denied')}
                    className={`py-2 px-4 rounded-md ${filter === 'denied' ? 'bg-blue-600 text-white' : 'bg-gray-200'} ml-2`}
                >
                    Denied
                </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {payments.filter((payment) => filter === 'all' || payment.status === filter).map((payment) => (
                    <div key={payment.id} className="border p-4 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-200">
                        <div className="flex w-full justify-between items-baseline">
                        <h2 className="font-semibold text-lg">{payment.fullName}</h2>
                        
                        <div className="mb-3">
                            <span className={`mb-auto px-2 py-1 text-xs font-semibold rounded-md ${payment.status === 'pending' ? 'bg-yellow-200 text-yellow-800' : payment.status === 'confirmed' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                                {payment.status}
                            </span>
                            </div>
                        </div>
                        <button
                            onClick={() => handleShowPaymentDetails(payment.id)}
                            className="mt-2 w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-200"
                        >
                            View Payment Details
                        </button>
                    </div>
                ))}
            </div>

            {showModal && (
                <GcashPayments paymentId={selectedPaymentId} onClose={handleCloseModal} />
            )}
            <Toaster/>
        </div>
    );
};

export default PaymentList;
