import React from 'react';
import PropTypes from 'prop-types';

const style = {
  message: 'shadow-lg p-4 rounded-2xl max-w-[70%] transition-transform transform hover:scale-105',
  sent: 'bg-gradient-to-r from-blue-500 to-indigo-600 text-white ml-auto rounded-br-3xl rounded-tl-3xl flex items-center',
  received: 'bg-gray-200 text-gray-800 mr-auto rounded-bl-3xl rounded-tr-3xl flex items-center',
  profilePicture: 'w-10 h-10 rounded-full object-cover shadow-md',
  messageContainer: 'flex items-end mb-6', // Larger margin for more visual separation
  sentContainer: 'justify-end ml-4', // Adds more margin-left for sent messages
  receivedContainer: 'justify-start mr-4', // Adds more margin-right for received messages
  link: 'text-blue-300 hover:text-blue-400 underline',
};

function Message({ message, currentUserId }) {
  const isSentByUser = message.uid === currentUserId;

  const renderMessageContent = () => {
    if (message.isImage) {
      return (
        <img
          src={message.text}
          alt="User sent content"
          className="w-48 h-48 object-cover rounded-xl shadow-md"
        />
      );
    }
    if (message.isFile) {
      return (
        <a href={message.text} target="_blank" rel="noreferrer" className={style.link}>
          <span>Attachment file</span>
        </a>
      );
    }
    return <p>{message.text}</p>;
  };

  return (
    <div className={`${style.messageContainer} ${isSentByUser ? style.sentContainer : style.receivedContainer}`}>
      {!isSentByUser && message.photoURL && (
        <img src={message.photoURL} alt="User profile" className={`${style.profilePicture} mr-3`} />
      )}
      <div className={`${isSentByUser ? style.sent : style.received} ${style.message}`}>
        {renderMessageContent()}
      </div>
      {isSentByUser && message.photoURL && (
        <img src={message.photoURL} alt="Your profile" className={`${style.profilePicture} ml-3`} />
      )}
    </div>
  );
}

Message.propTypes = {
  message: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isImage: PropTypes.bool,
    isFile: PropTypes.bool,
    photoURL: PropTypes.string,
  }).isRequired,
  currentUserId: PropTypes.string.isRequired,
};

export default Message;
