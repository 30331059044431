import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import instapayImage from '../assets/instapay.jpg';
import { db } from '../pages/firebase'; // Adjust the import based on your Firebase config
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Storage functions
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon
import Navbar2 from './Navbar2';
import Footer from './Footer';

const PaymentForm = () => {
  const [fullName, setFullName] = useState('');
  const [gcashNumber, setGcashNumber] = useState('');
  const [gcashAccountName, setGcashAccountName] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(''); // New payment amount state
  const [paymentType, setPaymentType] = useState('full payment');
  const [paymentDate, setPaymentDate] = useState('');
  const [paymentTime, setPaymentTime] = useState('');
  const [referenceNumber, setReferenceNumber] = useState('');
  const [proofOfPayment, setProofOfPayment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [submittedData, setSubmittedData] = useState(null); // Store submitted data
  const [error, setError] = useState(''); // Error state for handling errors

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true
    setError(''); // Reset error state

    try {
      // Upload proof of payment if provided
      let proofOfPaymentUrl = '';
      if (proofOfPayment) {
        const storage = getStorage();
        const storageRef = ref(storage, `proofs/${proofOfPayment.name}`);
        await uploadBytes(storageRef, proofOfPayment);
        proofOfPaymentUrl = await getDownloadURL(storageRef); // Get the download URL
      }

      // Prepare form data
      const formData = {
        fullName,
        gcashNumber: `+63${gcashNumber}`,
        gcashAccountName,
        paymentAmount, // Include the payment amount
        paymentType,
        paymentDate,
        paymentTime,
        referenceNumber,
        proofOfPayment: proofOfPaymentUrl, // Store the URL of the proof of payment
        status: 'pending', // Set the status to pending
      };

      // Save the payment data to Firestore
      const docRef = await addDoc(collection(db, 'payments'), formData); // Adjust the collection name as needed
      console.log('Document written with ID: ', docRef.id);

      setSubmittedData(formData); // Store submitted data
      setShowModal(true); // Show success modal
    } catch (e) {
      console.error('Error adding document: ', e);
      setError('Failed to submit payment. Please try again.'); // Set error message
    } finally {
      setIsLoading(false); // Reset loading state
      resetForm();
    }
  };

  const resetForm = () => {
    setFullName('');
    setGcashNumber('');
    setGcashAccountName('');
    setPaymentAmount(''); // Reset payment amount
    setPaymentType('full payment');
    setPaymentDate('');
    setPaymentTime('');
    setReferenceNumber('');
    setProofOfPayment(null); // Reset file input
    document.getElementById('proofOfPayment').value = ''; // Reset file input element
  };

  const handleGcashNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value) || value === '') {
      setGcashNumber(value);
    }
  };

  const handlePaymentAmountChange = (e) => {
    let value = e.target.value.replace(/,/g, ''); // Remove existing commas
    
    // Ensure the value is a valid number
    if (isNaN(value) || value === '') {
      value = ''; // If not a valid number, reset the value
    } else {
      // Format the value with commas if it's a valid number
      value = formatNumber(value);
    }
    
    setPaymentAmount(value);
  };
  
  const formatNumber = (value) => {
    if (!value) return ''; // Return empty string if no value
    return new Intl.NumberFormat().format(value); // Format with commas
  };
  

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
  <Navbar2 />
  
  <div className="max-w-lg w-full bg-white border border-gray-300 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 mt-[150px] mb-[150px] mx-auto">
    <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">GCash Payment Form</h2>
    <div className="mt-6 flex justify-center">
      <img src={instapayImage} alt="Instapay" className="w-1/2 h-auto rounded-md shadow-lg" />
    </div>
    <form onSubmit={handleSubmit} className="space-y-4 -ml-[1px]">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="fullName">
          Full Name
        </label>
        <input
          type="text"
          id="fullName"
          className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="gcashNumber">
          GCash Number
        </label>
        <div className="flex items-center">
          <span className="bg-gray-200 text-gray-700 p-3 rounded-l-md">+63</span>
          <input
            type="text"
            id="gcashNumber"
            className="block w-full p-3 border border-gray-300 rounded-md rounded-l-none focus:outline-none focus:ring focus:ring-blue-200"
            value={gcashNumber}
            onChange={handleGcashNumberChange}
            placeholder="e.g. 09123456789"
            required
            maxLength={10}
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="gcashAccountName">
          GCash Account Name
        </label>
        <input
          type="text"
          id="gcashAccountName"
          className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          value={gcashAccountName}
          onChange={(e) => setGcashAccountName(e.target.value)}
          required
        />
      </div>

      {/* New Payment Amount Field */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="paymentAmount">
          Payment Sent
        </label>
        <input
          type="text"
          id="paymentAmount"
          className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          value={paymentAmount}
          onChange={handlePaymentAmountChange}
          placeholder="e.g. 1,000"
          required
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="paymentDate">
            Date of Payment
          </label>
          <input
            type="date"
            id="paymentDate"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="paymentTime">
            Time of Payment
          </label>
          <input
            type="time"
            id="paymentTime"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
            value={paymentTime}
            onChange={(e) => setPaymentTime(e.target.value)}
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="referenceNumber">
          Reference Number
        </label>
        <input
          type="text"
          id="referenceNumber"
          className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          value={referenceNumber}
          onChange={(e) => setReferenceNumber(e.target.value)}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="proofOfPayment">
          Upload Proof of Payment
        </label>
        <input
          type="file"
          id="proofOfPayment"
          className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
          onChange={(e) => setProofOfPayment(e.target.files[0])}
        />
      </div>

      <div className="flex justify-center mt-4">
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit Payment'}
        </button>
      </div>
    </form>

    {showModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-80">
          <h3 className="text-lg font-semibold text-center mb-4">Payment Submitted Successfully!</h3>
          <p className="text-gray-600 text-center">Your payment form was sent successfully.</p>
          <button
            onClick={closeModal}
            className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-200"
          >
            Close
          </button>
        </div>
      </div>
    )}

    {error && (
      <div className="text-red-500 text-center mt-4">
        {error}
      </div>
    )}
  </div>
  
  {/* Fix the Footer */}
  <Footer className="mt-auto" />
</div>


  );
};

export default PaymentForm;
