import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { collection, addDoc, onSnapshot, deleteDoc, doc, updateDoc,getFirestore,getDoc,getDocs } from 'firebase/firestore';
import { db } from '../pages/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import toast, { Toaster } from 'react-hot-toast';
import { BiSolidImageAdd,BiSolidTrash } from "react-icons/bi";
import { FaEdit } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';


const InventoryManagement = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState(null);
  const [suits, setSuits] = useState([]);
  const [kids, setKids] = useState([]);
  const [evenings, setEvenings] = useState([]);
  const [cocktails, setCocktails] = useState([]);
  const [balls, setBalls] = useState([]);
  const [barongs, setBarongs] = useState([]);
  const [archivedItems, setArchivedItems] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newItem, setNewItem] = useState({ name: '', category: '', image: '',description:'',price: 0,isRented:false });
  const [imageFile, setImageFile] = useState(null);

  const [currentCategory, setCurrentCategory] = useState('');
 
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [newDescription, setNewDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [newPrice, setNewPrice] = useState('');
  const [newName, setNewName] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const [showNameModal, setShowNameModal] = useState(false);
  const [filter, setFilter] = useState('all');
  const storage = getStorage();

  useEffect(() => {
    const auth = getAuth();
    const fetchUserStatus = async (userId) => {
      const userDocRef = doc(getFirestore(), 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        setUser(userDocSnap.data());
        console.log("User role:", userDocSnap.data().role);
      } else {
        console.log("No such document!");
      }
    };
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If a user is logged in, fetch the user data
        fetchUserStatus(user.uid);
      } else {
        // If no user is logged in, handle accordingly
        console.error("No user is logged in.");
        setUser(null); // Clear user state if logged out
      }
    });
  
    return () => unsubscribe(); // Clean up the subscription on component unmount
  }, []);


  const fetchItems = async () => {
    console.log("Fetching items...");
    try {
      const eveningsSnapshot = await getDocs(collection(db, 'evenings'));
      setEvenings(eveningsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const cocktailsSnapshot = await getDocs(collection(db, 'cocktails'));
      setCocktails(cocktailsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const ballsSnapshot = await getDocs(collection(db, 'balls'));
      setBalls(ballsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const suitsSnapshot = await getDocs(collection(db, 'suits'));
      setSuits(suitsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const kidsSnapshot = await getDocs(collection(db, 'kids'));
      setKids(kidsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const barongsSnapshot = await getDocs(collection(db, 'barongs'));
      setBarongs(barongsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  };

  const handleAddItem = async () => {
    
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }

    if (!imageFile) {
      alert('Please upload an image before adding the item.');
      return;
    }

    if (!newItem.name || !newItem.category || !newItem.price || !newItem.description) {
      alert('Item data is incomplete. Please fill out all fields.');
      return
    }

    try {
      const imageRef = ref(storage, `images/${newItem.name}-${Date.now()}`);
      await uploadBytes(imageRef, imageFile);
      const imageUrl = await getDownloadURL(imageRef);

      const collectionName = newItem.category.includes('Evening') ? 'evenings' :
                              newItem.category.includes('Cocktail') ? 'cocktails' :
                              newItem.category.includes('Ball') ? 'balls' :
                             newItem.category.includes('Suit') ? 'suits' :
                             newItem.category.includes('Kids') ? 'kids' :
                             'barongs';

      const newItemWithImage = { ...newItem, image: imageUrl, price: parseFloat(newItem.price),created_at: new Date().toISOString() };
      await addDoc(collection(db, collectionName), newItemWithImage);

      setNewItem({ name: '', category: '', image: '',price: 0 ,description:'',isRented:false});
      setImageFile(null);
      setIsAdding(false);

      if (collectionName === 'evenings') {
        setEvenings([...evenings, newItemWithImage]);
      } else if (collectionName === 'cocktails') {
        setCocktails([...cocktails, newItemWithImage]);
      } else if (collectionName === 'balls') {
        setBalls([...balls, newItemWithImage]);
      } else if (collectionName === 'suits') {
        setSuits([...suits, newItemWithImage]);
      } else if (collectionName === 'kids') {
        setKids([...kids, newItemWithImage]);
      } else {
        setBarongs([...barongs, newItemWithImage]);
      }

      toast.success('Item added successfully!');
    } catch (error) {
      console.error('Error adding item:', error);
      alert('Error adding item. Please try again.');
    }
  };

  const handleRemove = async (item) => {
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }
    try {
      const archivedItem = { ...item, id: item.id };
      await addDoc(collection(db, 'archive'), archivedItem);

      const collectionName = item.category.includes('Evening') ? 'evenings' :
                              item.category.includes('Cocktail') ? 'cocktails' :
                              item.category.includes('Ball') ? 'balls' :
                             item.category.includes('Suit') ? 'suits' :
                             item.category.includes('Kids') ? 'kids' :
                             'barongs';

      const itemDoc = doc(db, collectionName, item.id);
      await deleteDoc(itemDoc);

      toast.success('Item removed successfully!');
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  const handleRecover = async (item) => {
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }
    if (!item.name || !item.category) {
      alert('Item data is incomplete. Cannot recover.');
      return;
    }

    await deleteDoc(doc(db, 'archive', item.id));
    const collectionName = item.category.includes('Evening') ? 'evenings' :
                            item.category.includes('Cocktail') ? 'cocktails' :
                            item.category.includes('Ball') ? 'balls' :
                           item.category.includes('Suit') ? 'suits' :
                           item.category.includes('Kids') ? 'kids' :
                           'barongs';

    const newItemToRecover = {
      name: item.name,
      category: item.category,
      image: item.image || '',
      price: item.price || 0,
      description: item.description || '',
      isRented: item.isRented || false
    };

    try {
      await addDoc(collection(db, collectionName), newItemToRecover);
      setArchivedItems(archivedItems.filter(archivedItem => archivedItem.id !== item.id));
      alert(`Item ${item.name} has been recovered and added back to the inventory!`);
    } catch (error) {
      console.error('Error recovering item:', error);
      alert('Error recovering item. Please try again.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        alert('Please upload a valid image (JPEG, PNG, or GIF).');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        alert('File size exceeds 5MB.');
        return;
      }

      setImageFile(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddItem();
  };

  const handleAdd = () => {
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }
    setIsAdding(true);
    setNewItem({ name: '', category: '', image: '',price: 0 ,description:'',isRented:false});
  };

   const handleUpdatePrice = async () => {
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }
    if (newPrice && selectedItem) {
      const itemDoc = doc(db, currentCategory, selectedItem.id);
      await updateDoc(itemDoc, { price: parseFloat(newPrice) });
      toast.success('Price updated successfully!');
      setShowModal(false);
      setNewPrice('');
      setSelectedItem(null);
    }
  };

  const handleUpdateName = async () => {
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }
    if (newName && selectedItem) {
      const itemDoc = doc(db, currentCategory, selectedItem.id);
      await updateDoc(itemDoc, { name: newName });
      toast.success('Name updated successfully!');
      setShowNameModal(false);
      setNewName('');
      setSelectedItem(null);
    }
  };

  const handleUpdateDescription = async () => {
    if(!user || user.role !== 'admin') {
      toast.error('You are not authorized to add items.');
      return;
    }
    if (newDescription && selectedItem) {
      const itemDoc = doc(db, currentCategory, selectedItem.id);
      await updateDoc(itemDoc, { description: newDescription });
      toast.success('Description updated successfully!');
      setShowDescriptionModal(false);
      setNewDescription('');
      setSelectedItem(null);
    }
  }


  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 p-6">
        <h1 className="text-3xl font-bold mb-4 ml-20">Inventory Management</h1>
        <div className="flex justify-between mb-6">
          <button onClick={handleAdd} className="flex items-center p-2 bg-green-500 text-white rounded">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Item
          </button>

          <div className="flex space-x-2">
            {['all', 'evenings', 'cocktails', 'balls', 'suits', 'kids', 'barongs'].map((category) => (
              <button
                key={category}
                className={`p-2 rounded ${
                  filter === category
                    ? 'bg-blue-700 text-white' // Darker background for selected button
                    : 'bg-blue-500 text-white' // Default background for unselected buttons
                }`}
                onClick={() => setFilter(category)}
              >
                {category === 'all'
                ? 'All'
                : category === 'evenings'
                ? 'Evening Gowns'
                : category === 'cocktails'
                ? 'Cocktail Gowns'
                : category === 'balls'
                ? 'Ball Gowns'
                : category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {isAdding && (
          <form onSubmit={handleSubmit} className="mb-6">
            <div>
              <label className="block">Name:</label>
              <input 
                type="text" 
                name="name" 
                value={newItem.name} 
                onChange={handleChange} 
                className="border p-2 w-full"
                required 
              />
            </div>
            <div>
              <label className="block">Category:</label>
              <select 
                name="category" 
                value={newItem.category} 
                onChange={handleChange} 
                className="border p-2 w-full"
                required
              >
                <option value="">Select Category</option>
                <option value="Cocktail Gown">Cocktail Gown</option>
                <option value="Evening Gown">Evening Gown</option>
                <option value="Ball Gown">Ball Gown</option>
                <option value="Suit">Suit</option>
                <option value="Kids">Kids</option>
                <option value="Barong">Barong</option>
              </select>
            </div>
            <div>
              <label className="block">Description:</label>
              <input
                type="text"
                name="description"
                value={newItem.description}
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </div>
            <div>
              <label className="block">Image:</label>
              <input 
                type="file" 
                accept="image/*" 
                onChange={handleImageChange} 
                className="border p-2 w-full" 
                required 
              />
            </div>
            <div>
              <label className="block">Price:</label>
              <input 
                type="number"
                name="price"
                value={newItem.price}
                onChange={handleChange}
                className="border p-2 w-full"
                required
              />
            </div>
            <button type="submit" className="mt-4 bg-blue-500 text-white p-2 rounded">
              Add Item
            </button>
            <button type="button" onClick={() => setIsAdding(false)} className="mt-4 bg-red-500 text-white p-2 rounded">
              Cancel
            </button>
          </form>
        )}

        <input 
          type="text" 
          placeholder="Search..." 
          value={searchTerm} 
          onChange={handleSearch} 
          className="border p-2 mb-4 w-full" 
        />

       {(filter === 'all' || filter === 'evenings') && <>
        <h2 className="text-2xl font-bold mb-2">Evening Gowns: {evenings.length}</h2>
        <table className="min-w-full border-collapse mb-4 bg-white shadow-md rounded-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="border-b border-gray-300 p-4 text-left">Image</th>
              <th className="border-b border-gray-300 p-4 text-left">Name</th>
              <th className="border-b border-gray-300 p-4 text-left">Description</th>
              <th className="border-b border-gray-300 p-4 text-left">Action</th>
              <th className="border-b border-gray-300 p-4 text-left">Price</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {evenings
              .filter(item => item.name.toLowerCase().includes(searchTerm))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(item => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <td className="p-4 text-left">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 object-cover rounded-md border border-gray-200 shadow-sm"
                    />
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowNameModal(true);
                          setCurrentCategory('evenings');
                        }}          
                      />
                      {item.name}
                    </div>
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setSelectedItem(item);
                            setShowDescriptionModal(true);
                            setCurrentCategory('evenings');
                          }}
                        />
                        {item.description}
                    </div>
                    
                  </td>
                  
                  <td className="p-4 text-left space-x-2">
                    <button
                      onClick={() => handleRemove(item)}
                      className="text-red-500 p-2 rounded-full hover:bg-red-50 transition-colors"
                      title="Remove"
                    >
                      <BiSolidTrash size={24}/>
                    </button>
                    <button
                      onClick={() => {
                        const input = document.getElementById('imageUpdate');
                        input.click();
                        input.onchange = async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                            if (!validTypes.includes(file.type)) {
                              alert('Please upload a valid image (JPEG, PNG, or GIF).');
                              return;
                            }

                            if (file.size > 5 * 1024 * 1024) {
                              alert('File size exceeds 5MB.');
                              return;
                            }

                            const imageRef = ref(storage, `images/${item.name}-${Date.now()}`);
                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const itemDoc = doc(db, 'evenings', item.id);

                            await deleteDoc(itemDoc);
                            await addDoc(collection(db, 'evenings'), { ...item, image: imageUrl });

                            toast.success('Item image updated successfully!');
                          }
                        }
                      }}
                      className="text-blue-500 p-2 rounded-full hover:bg-blue-50 transition-colors"
                      title="Update Image"
                    >
                      <BiSolidImageAdd size={24}/>
                    </button>
                  </td>
                  <td className="p-4 text-left">
                    <div className="flex items-center">
                      <span className="mr-4 text-lg font-semibold">₱{item.price}</span>
                      <button
                        className="ml-auto px-4 py-2 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                          setCurrentCategory('evenings');
                        }}
                      >
                        Update Price
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        </>}

        {(filter === 'all' || filter === 'balls') && <>     
        <h2 className="text-2xl font-bold mb-2">Ball Gowns: {balls.length}</h2>
        <table className="min-w-full border-collapse mb-4 bg-white shadow-md rounded-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="border-b border-gray-300 p-4 text-left">Image</th>
              <th className="border-b border-gray-300 p-4 text-left">Name</th>
              <th className="border-b border-gray-300 p-4 text-left">Description</th>
              
              <th className="border-b border-gray-300 p-4 text-left">Action</th>
              <th className="border-b border-gray-300 p-4 text-left">Price</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {balls
              .filter(item => item.name.toLowerCase().includes(searchTerm))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(item => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <td className="p-4 text-left">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 object-cover rounded-md border border-gray-200 shadow-sm"
                    />
                  </td>

                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowNameModal(true);
                          setCurrentCategory('balls');
                        }}          
                      />
                      {item.name}
                    </div>
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setSelectedItem(item);
                            setShowDescriptionModal(true);
                            setCurrentCategory('balls');
                          }}
                        />
                        {item.description}
                    </div>
                    
                  </td>
                  
                  <td className="p-4 text-left space-x-2">
                    <button
                      onClick={() => handleRemove(item)}
                      className="text-red-500 p-2 rounded-full hover:bg-red-50 transition-colors"
                      title="Remove"
                    >
                      <BiSolidTrash size={24}/>
                    </button>
                    <button
                      onClick={() => {
                        const input = document.getElementById('imageUpdate');
                        input.click();
                        input.onchange = async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                            if (!validTypes.includes(file.type)) {
                              alert('Please upload a valid image (JPEG, PNG, or GIF).');
                              return;
                            }

                            if (file.size > 5 * 1024 * 1024) {
                              alert('File size exceeds 5MB.');
                              return;
                            }

                            const imageRef = ref(storage, `images/${item.name}-${Date.now()}`);
                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const itemDoc = doc(db, 'balls', item.id);

                            await deleteDoc(itemDoc);
                            await addDoc(collection(db, 'balls'), { ...item, image: imageUrl });

                            toast.success('Item image updated successfully!');
                          }
                        }
                      }}
                      className="text-blue-500 p-2 rounded-full hover:bg-blue-50 transition-colors"
                      title="Update Image"
                    >
                      <BiSolidImageAdd size={24}/>
                    </button>
                  </td>
                  <td className="p-4 text-left">
                    <div className="flex items-center">
                      <span className="mr-4 text-lg font-semibold">₱{item.price}</span>
                      <button
                        className="ml-auto px-4 py-2 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                          setCurrentCategory('balls');
                        }}
                      >
                        Update Price
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table></>}
        {(filter === 'all' || filter === 'cocktails') && <>   
        <h2 className="text-2xl font-bold mb-2">Cocktail Gowns: {cocktails.length}</h2>
        <table className="min-w-full border-collapse mb-4 bg-white shadow-md rounded-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="border-b border-gray-300 p-4 text-left">Image</th>
              <th className="border-b border-gray-300 p-4 text-left">Name</th>
              <th className="border-b border-gray-300 p-4 text-left">Description</th>
              
              <th className="border-b border-gray-300 p-4 text-left">Action</th>
              <th className="border-b border-gray-300 p-4 text-left">Price</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {cocktails
              .filter(item => item.name.toLowerCase().includes(searchTerm))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(item => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <td className="p-4 text-left">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 object-cover rounded-md border border-gray-200 shadow-sm"
                    />
                  </td>

                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowNameModal(true);
                          setCurrentCategory('cocktails');
                        }}          
                      />
                      {item.name}
                    </div>
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setSelectedItem(item);
                            setShowDescriptionModal(true);
                            setCurrentCategory('cocktails');
                          }}
                        />
                        {item.description}
                    </div>
                    
                  </td>
                  
                  <td className="p-4 text-left space-x-2">
                    <button
                      onClick={() => handleRemove(item)}
                      className="text-red-500 p-2 rounded-full hover:bg-red-50 transition-colors"
                      title="Remove"
                    >
                      <BiSolidTrash size={24}/>
                    </button>
                    <button
                      onClick={() => {
                        const input = document.getElementById('imageUpdate');
                        input.click();
                        input.onchange = async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                            if (!validTypes.includes(file.type)) {
                              alert('Please upload a valid image (JPEG, PNG, or GIF).');
                              return;
                            }

                            if (file.size > 5 * 1024 * 1024) {
                              alert('File size exceeds 5MB.');
                              return;
                            }

                            const imageRef = ref(storage, `images/${item.name}-${Date.now()}`);
                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const itemDoc = doc(db, 'cocktails', item.id);

                            await deleteDoc(itemDoc);
                            await addDoc(collection(db, 'cocktails'), { ...item, image: imageUrl });

                            toast.success('Item image updated successfully!');
                          }
                        }
                      }}
                      className="text-blue-500 p-2 rounded-full hover:bg-blue-50 transition-colors"
                      title="Update Image"
                    >
                      <BiSolidImageAdd size={24}/>
                    </button>
                  </td>
                  <td className="p-4 text-left">
                    <div className="flex items-center">
                      <span className="mr-4 text-lg font-semibold">₱{item.price}</span>
                      <button
                        className="ml-auto px-4 py-2 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                          setCurrentCategory('cocktails');
                        }}
                      >
                        Update Price
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table></>}
        {(filter === 'all' || filter === 'suits') && <>  
        <h2 className="text-2xl font-bold mb-2">Suits: {suits.length}</h2>
        <table className="min-w-full border-collapse mb-4 bg-white shadow-md rounded-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="border-b border-gray-300 p-4 text-left">Image</th>
              <th className="border-b border-gray-300 p-4 text-left">Name</th>
              <th className="border-b border-gray-300 p-4 text-left">Description</th>
              <th className="border-b border-gray-300 p-4 text-left">Action</th>
              <th className="border-b border-gray-300 p-4 text-left">Price</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {suits
              .filter(item => item.name.toLowerCase().includes(searchTerm))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(item => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <td className="p-4 text-left">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 object-cover rounded-md border border-gray-200 shadow-sm"
                    />
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowNameModal(true);
                          setCurrentCategory('suits');
                        }}          
                      />
                      {item.name}
                    </div>
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setSelectedItem(item);
                            setShowDescriptionModal(true);
                            setCurrentCategory('suits');
                          }}
                        />
                        {item.description}
                    </div>
                    
                  </td>
                  
                  <td className="p-4 text-left space-x-2">
                    <button
                      onClick={() => handleRemove(item)}
                      className="text-red-500 p-2 rounded-full hover:bg-red-50 transition-colors"
                      title="Remove"
                    >
                      <BiSolidTrash size={24}/>
                    </button>
                    <button
                      onClick={() => {
                        const input = document.getElementById('imageUpdate');
                        input.click();
                        input.onchange = async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                            if (!validTypes.includes(file.type)) {
                              alert('Please upload a valid image (JPEG, PNG, or GIF).');
                              return;
                            }

                            if (file.size > 5 * 1024 * 1024) {
                              alert('File size exceeds 5MB.');
                              return;
                            }

                            const imageRef = ref(storage, `images/${item.name}-${Date.now()}`);
                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const itemDoc = doc(db, 'suits', item.id);

                            await deleteDoc(itemDoc);
                            await addDoc(collection(db, 'suits'), { ...item, image: imageUrl });

                            toast.success('Item image updated successfully!');
                          }
                        }
                      }}
                      className="text-blue-500 p-2 rounded-full hover:bg-blue-50 transition-colors"
                      title="Update Image"
                    >
                      <BiSolidImageAdd size={24}/>
                    </button>
                  </td>
                  <td className="p-4 text-left">
                    <div className="flex items-center">
                      <span className="mr-4 text-lg font-semibold">₱{item.price}</span>
                      <button
                        className="ml-auto px-4 py-2 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                          setCurrentCategory('suits');
                        }}
                      >
                        Update Price
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table></>}
        {(filter === 'all' || filter === 'kids') && <>
        <h2 className="text-2xl font-bold mb-2">Kids: {kids.length}</h2>
        <table className="min-w-full border-collapse mb-4 bg-white shadow-md rounded-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="border-b border-gray-300 p-4 text-left">Image</th>
              <th className="border-b border-gray-300 p-4 text-left">Name</th>
              <th className="border-b border-gray-300 p-4 text-left">Description</th>
              <th className="border-b border-gray-300 p-4 text-left">Action</th>
              <th className="border-b border-gray-300 p-4 text-left">Price</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {kids
              .filter(item => item.name.toLowerCase().includes(searchTerm))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(item => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <td className="p-4 text-left">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 object-cover rounded-md border border-gray-200 shadow-sm"
                    />
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowNameModal(true);
                          setCurrentCategory('kids');
                        }}          
                      />
                      {item.name}
                    </div>
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setSelectedItem(item);
                            setShowDescriptionModal(true);
                            setCurrentCategory('kids');
                          }}
                        />
                        {item.description}
                    </div>
                    
                  </td>
                  
                  <td className="p-4 text-left space-x-2">
                    <button
                      onClick={() => handleRemove(item)}
                      className="text-red-500 p-2 rounded-full hover:bg-red-50 transition-colors"
                      title="Remove"
                    >
                      <BiSolidTrash size={24}/>
                    </button>
                    <button
                      onClick={() => {
                        const input = document.getElementById('imageUpdate');
                        input.click();
                        input.onchange = async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                            if (!validTypes.includes(file.type)) {
                              alert('Please upload a valid image (JPEG, PNG, or GIF).');
                              return;
                            }

                            if (file.size > 5 * 1024 * 1024) {
                              alert('File size exceeds 5MB.');
                              return;
                            }

                            const imageRef = ref(storage, `images/${item.name}-${Date.now()}`);
                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const itemDoc = doc(db, 'kids', item.id);

                            await deleteDoc(itemDoc);
                            await addDoc(collection(db, 'kids'), { ...item, image: imageUrl });

                            toast.success('Item image updated successfully!');
                          }
                        }
                      }}
                      className="text-blue-500 p-2 rounded-full hover:bg-blue-50 transition-colors"
                      title="Update Image"
                    >
                      <BiSolidImageAdd size={24}/>
                    </button>
                  </td>
                  <td className="p-4 text-left">
                    <div className="flex items-center">
                      <span className="mr-4 text-lg font-semibold">₱{item.price}</span>
                      <button
                        className="ml-auto px-4 py-2 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                          setCurrentCategory('kids');
                        }}
                      >
                        Update Price
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table></>}
        {(filter === 'all' || filter === 'barongs') && <>
        <h2 className="text-2xl font-bold mb-2">Barong Tagalog: {barongs.length}</h2>
        <table className="min-w-full border-collapse mb-4 bg-white shadow-md rounded-md overflow-hidden">
          <thead className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
            <tr>
              <th className="border-b border-gray-300 p-4 text-left">Image</th>
              <th className="border-b border-gray-300 p-4 text-left">Name</th>
              <th className="border-b border-gray-300 p-4 text-left">Description</th>
              <th className="border-b border-gray-300 p-4 text-left">Action</th>
              <th className="border-b border-gray-300 p-4 text-left">Price</th>
            </tr>
          </thead>
          <tbody className="text-gray-700 text-sm font-light">
            {barongs
              .filter(item => item.name.toLowerCase().includes(searchTerm))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map(item => (
                <tr
                  key={item.id}
                  className="border-b border-gray-200 hover:bg-gray-50 transition-colors"
                >
                  <td className="p-4 text-left">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="h-16 w-16 object-cover rounded-md border border-gray-200 shadow-sm"
                    />
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowNameModal(true);
                          setCurrentCategory('barongs');
                        }}          
                      />
                      {item.name}
                    </div>
                  </td>
                  <td className="p-4 text-left">
                    <div className='flex items-center'>
                      <FaEdit size={24} className="mr-2 hover:text-blue-500 cursor-pointer"
                          onClick={() => {
                            setSelectedItem(item);
                            setShowDescriptionModal(true);
                            setCurrentCategory('barongs');
                          }}
                        />
                        {item.description}
                    </div>
                    
                  </td>
                  
                  <td className="p-4 text-left space-x-2">
                    <button
                      onClick={() => handleRemove(item)}
                      className="text-red-500 p-2 rounded-full hover:bg-red-50 transition-colors"
                      title="Remove"
                    >
                      <BiSolidTrash size={24}/>
                    </button>
                    <button
                      onClick={() => {
                        const input = document.getElementById('imageUpdate');
                        input.click();
                        input.onchange = async (e) => {
                          const file = e.target.files[0];
                          if (file) {
                            const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
                            if (!validTypes.includes(file.type)) {
                              alert('Please upload a valid image (JPEG, PNG, or GIF).');
                              return;
                            }

                            if (file.size > 5 * 1024 * 1024) {
                              alert('File size exceeds 5MB.');
                              return;
                            }

                            const imageRef = ref(storage, `images/${item.name}-${Date.now()}`);
                            await uploadBytes(imageRef, file);
                            const imageUrl = await getDownloadURL(imageRef);

                            const itemDoc = doc(db, 'barongs', item.id);

                            await deleteDoc(itemDoc);
                            await addDoc(collection(db, 'barongs'), { ...item, image: imageUrl });

                            toast.success('Item image updated successfully!');
                          }
                        }
                      }}
                      className="text-blue-500 p-2 rounded-full hover:bg-blue-50 transition-colors"
                      title="Update Image"
                    >
                      <BiSolidImageAdd size={24}/>
                    </button>
                  </td>
                  <td className="p-4 text-left">
                    <div className="flex items-center">
                      <span className="mr-4 text-lg font-semibold">₱{item.price}</span>
                      <button
                        className="ml-auto px-4 py-2 rounded-lg border border-blue-500 text-blue-500 hover:bg-blue-50 transition-colors"
                        onClick={() => {
                          setSelectedItem(item);
                          setShowModal(true);
                          setCurrentCategory('barongs');
                        }}
                      >
                        Update Price
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table></>}

      </div>
      <input type="file" accept="image/*" className="hidden" id='imageUpdate' />
      {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <div className="bg-white p-6 rounded shadow-lg w-80">
                        <h2 className="text-lg font-semibold mb-4">Update Price</h2>
                        <input
                          type="number"
                          placeholder="Enter new price"
                          className="border border-gray-300 p-2 w-full rounded mb-4"
                          value={newPrice}
                          onChange={(e) => setNewPrice(e.target.value)}
                        />
                        <div className="flex justify-end">
                          <button
                            onClick={() => setShowModal(false)}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleUpdatePrice}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {showNameModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <div className="bg-white p-6 rounded shadow-lg w-80">
                        <h2 className="text-lg font-semibold mb-4">Update Name</h2>
                        <input
                          type="text"
                          placeholder="Enter new name"
                          className="border border-gray-300 p-2 w-full rounded mb-4"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                        />
                        <div className="flex justify-end">
                          <button
                            onClick={() => setShowNameModal(false)}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleUpdateName}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {showDescriptionModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <div className="bg-white p-6 rounded shadow-lg w-80">
                        <h2 className="text-lg font-semibold mb-4">Update Description</h2>
                        <input
                          type="text"
                          placeholder="Enter new description"
                          className="border border-gray-300 p-2 w-full rounded mb-4"
                          value={newDescription}
                          onChange={(e) => setNewDescription(e.target.value)}
                        />
                        <div className="flex justify-end">
                          <button
                            onClick={() => setShowDescriptionModal(false)}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleUpdateDescription}
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

      <Toaster />
    </div>
  );
};

export default InventoryManagement;
