import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import test from '../assets/test.png';
import LoginModal from './LoginModal';
import SuperAdminLoginModal from './SuperAdminLoginModal';
import { FaUserShield } from 'react-icons/fa';

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const loginParam = searchParams.get('login');
    if (loginParam === 'true') {
      setModalOpen(true);
    }

    if (location.search) {
      const section = new URLSearchParams(location.search).get('scrollTo');
      if (section) {
        scroller.scrollTo(section, {
          duration: 500,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
      }
    }
  }, [location]);

  const handleLoginClick = (superAdmin = false) => {
    setIsSuperAdmin(superAdmin);
    setModalOpen(true);
  };

  const closeModal = (isLoggedIn = false) => {
    setModalOpen(false);
    if (isLoggedIn && isSuperAdmin) {
      navigate('/super-admin-dashboard');
    }
  };

  return (
    <div
      name="home"
      className="w-full h-screen relative bg-cover bg-center"
      style={{ backgroundImage: `url(${test})` }}
    >
      {/* Blurred overlay */}
      <div className="absolute inset-0 bg-black/50 backdrop-blur-0"></div>

      {/* Super Admin Icon at the bottom-right */}
      <div className="fixed bottom-4 right-4 z-10">
        <FaUserShield
          size={30}
          className="text-white hover:text-yellow-500 cursor-pointer"
          onClick={() => handleLoginClick(true)}
        />
      </div>

      <div className="relative z-10 max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full items-center pt-5">
        <h2
          className="text-2xl w-[1000px] sm:text-3xl md:text-4xl font-bold text-center mt-24 mb-5 text-white"
          style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}
        >
          YOUR ONE-STOP SHOP FOR ALL YOUR RENTAL NEEDS
        </h2>
        <h1
          className="glittery-text text-4xl sm:text-6xl md:text-7xl font-bold text-center py-5 mb-10"
          style={{
            fontFamily: '"Merriweather", serif', // Impactful font style
            color: '#F2CA46', // Gold color for the text
          }}
        >
          Tailored Rentals & Custom Creations: Your Perfect Fit Awaits
        </h1>

        <style>
        {`
          .glittery-text {
            position: relative; /* Needed for pseudo-elements */
            font-family: "Merriweather", serif;
            text-shadow: 0 0 1px rgba(242, 202, 70, 0.2), 0 0 2px rgba(242, 202, 70, 0.1); /* Very subtle glow */
            animation: glitter 3s infinite ease-in-out; /* Ultra-light shimmering animation */
          }

          @keyframes glitter {
            0% {
              text-shadow: 0 0 1px rgba(242, 202, 70, 0.2), 0 0 2px rgba(242, 202, 70, 0.1);
            }
            50% {
              text-shadow: 0 0 2px rgba(242, 202, 70, 0.3), 0 0 3px rgba(242, 202, 70, 0.2); /* Slightly more subtle shine */
            }
            100% {
              text-shadow: 0 0 1px rgba(242, 202, 70, 0.2), 0 0 2px rgba(242, 202, 70, 0.1);
            }
          }
        `}
        </style>

        <p
          className="text-base sm:text-lg md:text-xl text-center max-w-[800px] pb-10 text-white"
          style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}
        >
          From weddings to parties, we offer a wide range of high-quality rental items to elevate any event. Browse through our collection. Explore our catalog today and let us help you make your next event unforgettable!
        </p>

        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <button
            className="text-black bg-white border-white border-2 px-10 sm:px-14 py-3 my-2 hover:bg-[#ffd000] hover:border-[#ffd000] rounded-lg hover:text-white"
            onClick={() => navigate('/signup')}
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}
          >
            REGISTER
          </button>
          <button
            className="text-black bg-white border-white border-2 px-10 sm:px-16 py-3 my-2 hover:bg-[#ffd000] hover:border-[#ffd000] rounded-lg hover:text-white"
            onClick={() => handleLoginClick()}
            style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)' }}
          >
            LOGIN
          </button>
        </div>
      </div>

      {/* Render the appropriate modal based on user type */}
      {isSuperAdmin ? (
        <SuperAdminLoginModal isOpen={modalOpen} onClose={closeModal} />
      ) : (
        <LoginModal isOpen={modalOpen} onClose={closeModal} />
      )}
    </div>
  );
};

export default Home;
