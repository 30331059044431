import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faBell, faSignOutAlt, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getFirestore, onSnapshot, collection } from 'firebase/firestore';
import SalesReport from './SalesReport';

const AdminDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [newRegistrations, setNewRegistrations] = useState([]); // State to track new user registrations
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
  const location = useLocation();
  const navigate = useNavigate();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    // Your logout logic here (e.g., clearing session, redirecting)
    // For now, let's just close the modal
    setShowLogoutModal(false);
    // Redirect to home after logout
    window.location.href = '/';
  };

  const handleProfileClick = () => {
    navigate('/admin-profile');
  };

  // Open sidebar automatically when the route changes
  useEffect(() => {
    setIsSidebarOpen(true);
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle new user registration notifications
  useEffect(() => {
    const db = getFirestore();
    const unsubscribe = onSnapshot(collection(db, 'users'), (snapshot) => {
      const newUsers = snapshot.docs.filter((doc) => doc.data().status === 'pending');
      setNewRegistrations(newUsers); // Update the array of new registrations
    });

    return () => unsubscribe();
  }, []);

  const handleNotificationClick = () => {
    setIsPopupOpen(!isPopupOpen); // Toggle popup visibility
  };

  return (
    <div className="min-h-screen bg-gray-100 flex">
      
      {/* Overlay to close sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={toggleSidebar}></div>
      )}

      {/* Main Content Area */}
      <div className={`flex-1 transition-transform duration-300 ease-in-out transform ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        {/* Top Bar with Icons and Admin Dashboard Text */}
        <div className="flex flex-col">
          <div className="flex justify-end items-center space-x-6 mb-4 p-4 bg-white shadow-md rounded-b-lg">
            <div className="relative" onClick={handleNotificationClick}>
              <FontAwesomeIcon icon={faBell} className="text-gold text-xl cursor-pointer" title="Notifications" />
              {newRegistrations.length > 0 && (
                <span className="absolute top-0 right-0 inline-block w-3 h-3 bg-red-500 rounded-full"></span>
              )}
            </div>
            <FontAwesomeIcon icon={faUser} className="text-gold text-xl cursor-pointer" title="Profile" onClick={handleProfileClick} />
            <Link
              to="/"
              className="text-gold text-xl cursor-pointer"
              title="Logout"
              onClick={(e) => {
                e.preventDefault();
                setShowLogoutModal(true); // Show the logout confirmation modal
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
            </Link>
          </div>
          <h1 className="text-3xl font-bold text-gold mb-4 text-center">Admin Dashboard</h1>
        </div>

        {/* Modal */}
        {showLogoutModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
              <h3 className="text-2xl font-semibold text-center text-gray-700 mb-6">Are you sure you want to logout?</h3>

              {/* Modal Buttons */}
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleLogout} // Trigger logout and redirection
                  className="bg-red-500 text-white py-3 px-8 rounded-lg text-lg font-medium hover:bg-red-600 transition duration-300"
                >
                  Yes
                </button>
                <button
                  onClick={() => setShowLogoutModal(false)} // Close the modal without logging out
                  className="bg-gray-500 text-white py-3 px-8 rounded-lg text-lg font-medium hover:bg-gray-600 transition duration-300"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}


        {/* New Registrations Popup */}
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-30">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-2xl font-semibold mb-4 text-center border-b pb-2 border-gray-300">New Registrations</h2>
              <ul className="space-y-3">
                {newRegistrations.map((user) => (
                  <li key={user.id} className="flex justify-between py-2 border-b border-gray-200">
                    <span className="font-medium">{user.data().firstName} {user.data().lastName}</span>
                    <span className="text-gray-600">{user.data().email}</span>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setIsPopupOpen(false)}
                className="mt-4 w-full bg-gold text-black font-semibold py-2 rounded-lg hover:bg-blue-600 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        )}

       <SalesReport />
      </div>
    </div>
  );
};

export default AdminDashboard;
