import React from 'react';

const RentedAttireModal = ({ attire, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-6">
        <h2 className="text-2xl font-bold mb-4">Rented Attire Details</h2>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Name:</strong> {attire.name}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Email:</strong> {attire.email}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Branch:</strong> {attire.branch}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Rented Date:</strong> {new Date(attire.created_at).toLocaleDateString()}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600">
            <strong>Rented Time:</strong> {new Date(`1970-01-01T${attire.time}:00`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
          </p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Payment Method:</strong> {attire.paymentMethod}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Status:</strong> {attire.status}</p>
        </div>

        <div className="mb-4">
          <p className="text-gray-600"><strong>Attire:</strong> {attire.imageUrls[0].name}</p>
        </div>

        {attire.price && (
          <div className="mb-4">
            <p className="text-gray-600">
              <strong>Price:</strong> <span className="font-medium">₱{new Intl.NumberFormat('en-PH').format(attire.price)}</span>
            </p>
          </div>
        )}

        {/* Render Images */}
        {attire.imageUrls && attire.imageUrls.length > 0 && (
          <div className="mb-4">
            <p className="text-gray-600"><strong>Attire Images:</strong></p>
            <div className="grid grid-cols-2 gap-2">
              {attire.imageUrls.map((image, index) => (
                <img key={index} src={image.src ? image.src : image.image} alt={`Rented Attire ${index + 1}`} className="w-full h-auto rounded" />
              ))}
            </div>
          </div>
        )}

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded text-black"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentedAttireModal;
