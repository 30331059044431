import React, { useState, useEffect } from 'react';
import Navbar2 from './Navbar2';
import { getAuth, onAuthStateChanged, updateProfile, signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../pages/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Swal from 'sweetalert2';

const MyProfile = () => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [userInitial, setUserInitial] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [idDocument, setIdDocument] = useState(null); // New state for ID document

  // Change password states
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);

  // Edit profile mode state
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);
        
        // Get user profile from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        
        if (userDoc.exists()) {
          const data = userDoc.data();
          setFirstName(data.firstName || '');
          setLastName(data.lastName || '');
          setPhoneNumber(data.phoneNumber || '');

          // Set profile picture from Firestore or Firebase Auth if available
          if (data.profilePicture) {
            setProfilePicture(data.profilePicture);
          } else if (user.photoURL) {
            setProfilePicture(user.photoURL);
          }
        } else {
          console.log("No such document!");
        }

        // Set user initial
        const initial = user.displayName?.charAt(0).toUpperCase();
        setUserInitial(initial);
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const auth = getAuth();
      const storage = getStorage();
      const user = auth.currentUser;
      const storageRef = ref(storage, `profile_pictures/${user.uid}`);

      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Update Firestore with the new image URL
      await updateDoc(doc(db, 'users', user.uid), {
        profilePicture: downloadURL
      });

      // Optionally, update Firebase Auth profile picture
      await updateProfile(user, { photoURL: downloadURL });

      setProfilePicture(downloadURL); // Update state with new profile picture
    }
  };

  const handleIdChange = async (e) => {
    const file = e.target.files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith('image/')) {
        const auth = getAuth();
        const storage = getStorage();
        const user = auth.currentUser;
        const storageRef = ref(storage, `user_ids/${user.uid}/${file.name}`);

        try {
            // Upload the ID image to Firebase Storage
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);

            // Update Firestore with the new ID image URL under 'idImageUrl'
            await updateDoc(doc(db, 'users', user.uid), {
                idImageUrl: downloadURL // Store the image URL with the specified field name
            });

            setIdDocument(downloadURL); // Update state with the new ID image URL
            alert("ID image uploaded successfully!"); // Optional: Feedback to user
        } catch (error) {
            console.error("Error uploading ID image: ", error);
        }
    } else {
        alert("Please upload a valid image file.");
    }
};


  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setPasswordError("New password and confirmation do not match.");
      return;
    }

    const auth = getAuth();
    try {
      // Re-authenticate the user with the email and current password
      await signInWithEmailAndPassword(auth, email, currentPassword);
      
      // Update password
      await updatePassword(auth.currentUser, newPassword);
      alert("Password changed successfully!");
      
      // Reset fields
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setShowChangePassword(false);
    } catch (error) {
      setPasswordError(error.message);
    }
  };

  const handleProfileUpdate = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    try {
      // Update Firestore with the updated user information
      await updateDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        phoneNumber
      });
  
      // Display success message using SweetAlert2
      await Swal.fire({
        icon: 'success',
        title: 'Profile updated successfully!',
        text: 'Your profile information has been successfully updated.',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#4CAF50' // Green color for success
      });
  
      setIsEditMode(false); // Exit edit mode
    } catch (error) {
      console.error("Error updating profile: ", error);
  
      // Display error message using SweetAlert2
      await Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'There was an error updating your profile. Please try again later.',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#f44336' // Red color for error
      });
    }
  };

  return (
    <div>
      <Navbar2 />
      <div className="mt-44 container mx-auto p-8 border border-gray-300 shadow-md rounded-lg bg-white max-w-xl md:max-w-2xl lg:max-w-3xl">
        <h1 className="text-4xl font-semibold mb-8 text-center text-gray-800">My Profile</h1>
        <div className="flex flex-col md:flex-row items-center space-y-8 md:space-y-0 md:space-x-8">
          {/* Profile Picture Section */}
          <div className="relative">
            <label
              htmlFor="profilePicture"
              className="relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div
                className={`w-32 h-32 md:w-40 md:h-40 bg-[#f7f7f7] border border-gray-300 rounded-full flex items-center justify-center transition-all duration-300 shadow-lg hover:shadow-xl cursor-pointer ${isHovered ? 'blur-sm' : ''}`}
              >
                {profilePicture ? (
                  <img
                    src={profilePicture}
                    alt="Profile"
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <span className="text-6xl text-gray-500">{userInitial}</span>
                )}
              </div>

              {isHovered && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 text-white font-medium text-sm rounded-full">
                  Upload Profile
                </div>
              )}
            </label>

            {/* Hidden File Input */}
            <input
              type="file"
              id="profilePicture"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
            />
          </div>

          {/* Profile Information Section */}
          <div className="flex flex-col space-y-4 w-full">
            {isEditMode ? (
              <>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  className="border border-gray-400 p-2 rounded w-full"
                />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  className="border border-gray-400 p-2 rounded w-full"
                />
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                  className="border border-gray-400 p-2 rounded w-full"
                />
                <button
                  onClick={handleProfileUpdate}
                  className="mt-4 bg-green-500 text-white p-2 rounded w-full hover:bg-green-600 transition"
                >
                  Save Changes
                </button>
                <button
                  onClick={() => setIsEditMode(false)}
                  className="mt-2 text-red-600 hover:underline"
                >
                  Cancel
                </button>
              </>
            ) : (
              <>
                <p className="text-md font-semibold text-gray-800">First Name: <span className="text-gray-600">{firstName}</span></p>
                <p className="text-md font-semibold text-gray-800">Last Name: <span className="text-gray-600">{lastName}</span></p>
                <p className="text-md font-semibold text-gray-800">Phone Number: <span className="text-gray-600">{phoneNumber}</span></p>
                <p className="text-md text-gray-600">Email: <span className="font-semibold">{email}</span></p>

                <button
                  onClick={() => setIsEditMode(true)}
                  className="mt-4 bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 transition"
                >
                  Edit Profile
                </button>
              </>
            )}

            {/* Change Password Section */}
            <button
              onClick={() => setShowChangePassword(!showChangePassword)}
              className="mt-4 text-blue-600 hover:underline"
            >
              Change Password
            </button>

            {showChangePassword && (
              <form onSubmit={handleChangePassword} className="mt-4 p-4 border -ml-[1px] border-gray-300 rounded">
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="border border-gray-400 p-2 rounded w-full mb-2"
                />
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="border border-gray-400 p-2 rounded w-full mb-2"
                />
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="border border-gray-400 p-2 rounded w-full mb-2"
                />
                {passwordError && <p className="text-red-500">{passwordError}</p>}

                <button
                  type="submit"
                  className="bg-blue-500 text-white p-2 rounded w-full hover:bg-blue-600 transition"
                >
                  Save Password
                </button>
              </form>
            )}
          </div>
        </div>

        {/* ID Document Upload Section */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Upload Valid ID</h2>
          <input
            type="file"
            accept="image/*,.pdf"
            onChange={handleIdChange}
            className="border border-gray-400 p-2 rounded w-full mb-2"
          />
          {idDocument && <p className="text-green-600">ID uploaded successfully!</p>}
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
