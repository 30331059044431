import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid'; // Heroicons v2 import path

const PrivacyPolicy = () => {
  // Go back function
  const handleBack = () => {
    window.history.back(); // Navigates back to the previous page
  };

  return (
    <div className="bg-gray-100 text-gray-800 font-sans">
      <div className="max-w-7xl mx-auto py-16 px-6 sm:px-8 lg:px-16">
        <div className="flex items-center space-x-4 mb-8">
          {/* Stylish Back Icon */}
          <button 
            onClick={handleBack} 
            className="p-3 rounded-full bg-yellow-400 text-white hover:bg-yellow-500 focus:outline-none transition-all duration-300 transform hover:scale-105">
            <ArrowLeftIcon className="h-6 w-6" />
          </button>
          <h1 className="text-4xl font-bold text-gray-900">PRIVACY POLICY</h1>
        </div>

        <div className="text-center space-y-4">
          <p className="text-lg text-gray-600">Please read the following privacy policy carefully.</p>
        </div>
        <p className='mb-4'></p>
        <div className="text-center space-y-4">
          <p className="text-lg text-gray-600">Amarabelliana’s Gowns and Events Stylist values your privacy. 
          This Privacy Policy explains how we collect, use, disclose, and protect your personal information through our Multiplatform Rental System, 
          which includes AI Visual Customization and 3D Mannequin features.</p>
        </div>

        <div className="mt-8">
          <div className="bg-white shadow-lg rounded-lg p-6 space-y-6">
            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">1. Collection of Personal Information</h2>
              <p className="text-gray-600">
                <span className="block ml-8 mb-2">We collect personal information from you upon registration and throughout your use of the system, including but not limited to: </span>
                <span className="block ml-14">◉ Contact Information (e.g., name, email, phone number)</span>
                <span className="block ml-14">◉ Verification Data (e.g., uploaded valid ID for account verification) </span>
                <span className="block ml-14">◉ Preferences and Usage Data (e.g., rental history, style preferences) </span>
                <span className="block ml-14">◉ Payment Information (for processing transactions) </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">2. Use of Personal Information </h2>
              <p className="text-gray-600">
                <span className="block ml-8 mb-2">The personal information we collect is used for the following purposes:</span>
                <span className="block ml-14">◉ To provide and improve our rental services and personalized features, such as 3D mannequin customization and AI-generated visualizations. </span>
                <span className="block ml-14">◉ To verify your identity and manage access to the platform. </span>
                <span className="block ml-14">◉ To process payments and manage rental transactions. </span>
                <span className="block ml-14">◉ To send you notifications regarding account activity, updates, and service announcements. </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">3. Data Sharing and Disclosure</h2>
              <p className="text-gray-600">
                <span className="block ml-8 mb-2">We do not disclose your personal information to third parties except as necessary for:</span>
                <span className="block ml-14">◉ Legal and regulatory compliance. </span>
                <span className="block ml-14">◉ Service providers involved in payment processing or system security, who are bound to protect your data. </span>
                <span className="block ml-14">◉ Other entities or individuals, with your explicit consent. </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">4. Data Security and Retention</h2>
              <p className="text-gray-600">
                <span className="block ml-8 mb-2">We implement industry-standard security measures to safeguard your personal data. 
                This includes encryption, secure databases, and controlled access protocols. Personal information is retained only as 
                long as needed to fulfill service purposes, comply with legal obligations, or upon user request for deletion. </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">5. User Rights </h2>
              <p className="text-gray-600">
                <span className="block ml-8 mb-2">Under the Data Privacy Act of 2012, you have the right to: </span>
                <span className="block ml-14">◉ Access, update, and correct your personal information in your account settings. </span>
                <span className="block ml-14">◉ Withdraw consent for data processing, subject to the effect on service availability.</span>
                <span className="block ml-14">◉ Request data deletion, except where retention is necessary for legal compliance. </span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">6. Changes to This Privacy Policy</h2>
              <p className="text-gray-600">
                <span className="block ml-8 mb-2">We reserve the right to modify this Privacy Policy at any time. Users will be notified of significant changes via email or system notifications.</span>
              </p>
            </section>

            <section className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800">7. Contact Information </h2>
              <p className="text-gray-600">
                <span className="block ml-8">
                For any questions or concerns regarding this Privacy Policy, you may contact us at: &nbsp;
                  <a 
                    href="mailto:amarabelliana.customerservice@gmail.com" 
                    className="text-indigo-600 hover:text-indigo-800">
                    amarabelliana.customerservice@gmail.com
                  </a><br/>
                  Phone: +63 955 260 9144, +63 961 748 1435 
                </span><br />
              </p>
            </section>
          </div>

          <div className="mt-8 text-center">
            <p className="text-lg text-gray-600">
              If you have any questions about this privacy policy, feel free to contact us at{' '}
              <a href="mailto:support@yourcompany.com" className="text-indigo-600 hover:text-indigo-800">
                amarabelliana.customerservice@gmail.com
              </a>.<br/>
              <p className='mb-5'></p>
              <span className="italic">
                By using the system, you confirm that you have read and agree to the terms outlined in this Privacy Policy.
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
