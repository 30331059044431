import React, { useRef, useState, useEffect } from 'react';
import Navbar2 from '../components/Navbar2';
import Footer from '../components/Footer';
import { doc, getDoc, getFirestore, addDoc, collection, updateDoc,getDocs } from 'firebase/firestore'; // Import Firestore functions
import { getAuth } from 'firebase/auth';
import { db } from '../pages/firebase';
import { onSnapshot } from 'firebase/firestore';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';

import '../pages/Collections.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Collections = () => {
  const [isHoveredKids, setIsHoveredKids] = useState(false);
  const [isHoveredBarong, setIsHoveredBarong] = useState(false);
  const [isHoveredSuits, setIsHoveredSuits] = useState(false);
  const [isHoveredEvening, setIsHoveredEvening] = useState(false);
  const [isHoveredBall, setIsHoveredBall] = useState(false);
  const [isHoveredCocktail, setIsHoveredCocktail] = useState(false);
  const barongSectionRef = useRef(null);
  const location = useLocation();
  const user = getAuth().currentUser;
  const ballGownsRef = useRef(null);
  const eveningGownsRef = useRef(null);
  const kidsRef = useRef(null);
  const singleBreastedRef = useRef(null);
  const doubleBreastedRef = useRef(null);
  const CTRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRentModal, setShowRentModal] = useState(false);
  const [showRentalInfoModal, setShowRentalInfoModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [userStatus, setUserStatus] = useState(null); // Update state to use 'status'
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedAttireIds, setSelectedAttireIds] = useState([]);

  const [selectedAttire, setSelectedAttire] = useState(null);

  const [suits, setSuits] = useState([]);
  const [kids, setKids] = useState([]);
  const [evenings, setEvenings] = useState([]);
  const [cocktails, setCocktails] = useState([]);
  const [balls, setBalls] = useState([]);
  const [barongs, setBarongs] = useState([]);

  const fetchItems = async () => {
    console.log("Fetching items...");
    try {
      const eveningsSnapshot = await getDocs(collection(db, 'evenings'));
      setEvenings(eveningsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const cocktailsSnapshot = await getDocs(collection(db, 'cocktails'));
      setCocktails(cocktailsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const ballsSnapshot = await getDocs(collection(db, 'balls'));
      setBalls(ballsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const suitsSnapshot = await getDocs(collection(db, 'suits'));
      setSuits(suitsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const kidsSnapshot = await getDocs(collection(db, 'kids'));
      setKids(kidsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const barongsSnapshot = await getDocs(collection(db, 'barongs'));
      setBarongs(barongsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.getElementById(location.hash.substring(1));
      if (targetElement) {
        // Get the position of the target element
        const yOffset = -90; // Offset in pixels to scroll slightly above the target
        const yPosition = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

        // Scroll to the calculated position
        window.scrollTo({ top: yPosition, behavior: 'smooth' });
      }
    }
  }, [location]);
  
  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    const fetchUserStatus = async () => {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        console.error("No user is logged in.");
        return;
      }

      const userDocRef = doc(getFirestore(), 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const status = userDocSnap.data().status; // Fetch the 'status' field
        console.log("User Status:", status); // Log the status
        setUserStatus(status);
      } else {
        console.log("No such document!");
      }
    };

    fetchUserStatus();
  }, []);

  async function verifyIsRented(attire){
    const collectionName = attire.category.includes('Evening') ? 'evenings' :
      attire.category.includes('Cocktail') ? 'cocktails' :
      attire.category.includes('Ball') ? 'balls' :
      attire.category.includes('Suit') ? 'suits' :
      attire.category.includes('Kids') ? 'kids' :
          'barongs';

    const attireDocRef = doc(getFirestore(), collectionName, attire.id);
    const attireDocSnap = await getDoc(attireDocRef);
    const isRented = attireDocSnap.data().isRented;
    if (isRented) {
      toast.error('This attire is already rented. Please select another one.');
      return true;
    }else{
      return false;
    }
  }

  const handleAttireSelect = (attireId) => {
    setSelectedAttireIds(prevIds => {
      if (prevIds.includes(attireId)) {
        return prevIds.filter(id => id !== attireId); // Deselect if already selected
      } else {
        return [...prevIds, attireId]; // Select if not selected
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const attire = selectedAttire; // Get the selected attire from state

    console.log("Selected Attire:", attire); // Log the selected attire
    if (attire.isRented) {
      toast.error('This attire is already rented. Please select another one.');
    }

    let isRented = await verifyIsRented(attire);
    if (isRented) {
      return;
    }else{
      console.log("Attire is not rented. Proceeding to submit rental information.");
    }
    

    const currentDate = new Date();
    const selectedDate = new Date(e.target.date.value);
    const selectedTime = e.target.time.value;

    setSubmitAttempted(true);

    if (!termsAccepted) {
      return; // Do not proceed if terms are not accepted
    }

    if (selectedDate < currentDate) {
      setErrorMessage('Please select a date that is today or in the future.');
      return;
    }

    const [selectedHours, selectedMinutes] = selectedTime.split(':');
    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(selectedHours);
    selectedDateTime.setMinutes(selectedMinutes);

    if (selectedDateTime <= currentDate) {
      setErrorMessage('Please select a time that is in the future.');
      return;
    }

    setErrorMessage(''); // Clear previous errors
    setLoading(true); // Show loading spinner

    


    const rentalInfo = {
      name: e.target.name.value,
      email: e.target.email.value,
      date: selectedDate.toISOString(),
      time: selectedTime,
      branch: e.target.branch.value,
      paymentMethod: e.target.paymentMethod.value,
      userId: getAuth().currentUser?.uid,
      status: 'pending', // Set initial status as pending
      imageUrls: [attire], // Filter out any undefined values
      price: selectedPrice, // Include the selected price from state here
      created_at: new Date().toISOString(),
    };

    // Save rentalInfo to Firestore
    try {
      await addDoc(collection(getFirestore(), "rentals"), rentalInfo);
      // Handle success (e.g., show a success message or redirect)

      setTimeout(() => {
        setLoading(false); // Hide loading spinner
        Swal.fire({
          title: 'Success!',
          text: 'Your rental information has been submitted.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: 'green',
        });
        setShowRentalInfoModal(false);
      }, 1000);
      

      const newNotification = {
        created_at: new Date().toISOString(),
        title: 'New Rental Request for ' + attire.name,
        message:  e.target.name.value + ' has requested to rent an attire.',
        status: 'pending',
        isSeen: false,
        user: user.uid,
        userFrom: user.uid,
      }

      await addDoc(collection(getFirestore(), "notifications"), newNotification)

      const collectionName = attire.category.includes('Evening') ? 'evenings' :
      attire.category.includes('Cocktail') ? 'cocktails' :
      attire.category.includes('Ball') ? 'balls' :
      attire.category.includes('Suit') ? 'suits' :
      attire.category.includes('Kids') ? 'kids' :
          'barongs';

      updateDoc(doc(getFirestore(), collectionName, attire.id), {
        isRented: true,
      });

      setShowRentalInfoModal(false);
      setShowModal(false);
      

    } catch (error) {
      console.error("Error adding rental: ", error);
      setErrorMessage('There was an error submitting your rental information. Please try again later.');
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const scrollLeft = (ref) => {
    ref.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = (ref) => {
    ref.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  const handleViewDetails = (src, price, id) => {
    handleAttireSelect(id); // Call this function to select the attire
    setSelectedImage(src);
    setSelectedPrice(price); // Store the selected price
    setShowModal(true);
  };

  const handleRentClick = () => {
    console.log("Current User Status:", userStatus); // Log current user status

    if (userStatus === 'approved') {
      setShowRentModal(true); // Open rent modal if approved
    } else if (userStatus === 'pending') {
      Swal.fire({
        title: 'Renting Not Allowed',
        text: 'Your account is currently pending approval. Please check back later or contact support.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red',
      });
    } else {
      Swal.fire({
        title: 'Renting Not Allowed',
        text: 'Your account is not approved for rentals. Please contact support.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red',
      });
    }
  };

      


  return (
    <div className="w-full min-h-screen bg-gray-200">
    <Navbar2 />
    <div className="p-8 bg-gray-100">
    <section id="evening-gown-section">
      <div className="bg-[#ffd000] mt-20 mb-6 w-full h-20 flex items-center justify-center relative">
        <h1 className="text-4xl font-bold">GOWNS COLLECTION</h1>
      </div>
      </section>
      <div className="mb-12">
      <h2 className="text-2xl font-semibold mb-4 uppercase">Evening Gowns</h2>
      <div 
        className="relative flex items-center" 
        onMouseEnter={() => setIsHoveredEvening(true)} 
        onMouseLeave={() => setIsHoveredEvening(false)}
      >
        {/* Scroll Left Button */}
        {isHoveredEvening && (
          <button 
            onClick={() => scrollLeft(kidsRef)}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg opacity-100 transition-opacity duration-300"
          >
            &lt;
          </button>
        )}
        
        {/* Gown Items */}
        <div 
          ref={kidsRef} 
          className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
        >
          {evenings.map((item, index) => (
            <div key={index} className="relative w-72 h-96 flex-shrink-0">
              <img 
                src={item.image} 
                alt={`Ball Gown ${index + 1}`} 
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105" 
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                <button 
                  onClick={() => {
                    handleViewDetails(item.src, item.price, item.id);
                    setSelectedAttire(item);
                  }}
                  className="text-white text-lg font-semibold"
                >
                  View Details
                </button>
              </div>
              <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
                Price: ₱{new Intl.NumberFormat('en-PH').format(item.price)} 
              </div>

            </div>
          ))}
        </div>
        
        {/* Scroll Right Button */}
        {isHoveredEvening && (
          <button 
            onClick={() => scrollRight(kidsRef)} 
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg opacity-100 transition-opacity duration-300"
          >
            &gt;
          </button>
        )}
      </div>
    </div>


    <div className="mb-12">
      <section id='ball-gown-section'>
        <h2 className="text-2xl font-semibold mb-4 uppercase">Ball Gowns</h2>
      </section>
      <div 
        className="relative flex items-center" 
        onMouseEnter={() => setIsHoveredBall(true)} 
        onMouseLeave={() => setIsHoveredBall(false)}
      >
        {/* Scroll Left Button */}
        {isHoveredBall && (
          <button 
            onClick={() => scrollLeft(ballGownsRef)} 
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg opacity-100 transition-opacity duration-300"
          >
            &lt;
          </button>
        )}
        
        {/* Ball Gown Items */}
        <div 
          ref={ballGownsRef} 
          className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
        >
          {balls.map((item, index) => (
            <div key={index} className="relative w-72 h-96 flex-shrink-0">
              <img 
                src={item.image} 
                alt={`Ball Gown ${index + 1}`} 
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105" 
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                <button 
                  onClick={() => {
                    handleViewDetails(item.src, item.price, item.id);
                    setSelectedAttire(item);
                  }}
                  className="text-white text-lg font-semibold"
                >
                  View Details
                </button>
              </div>
              <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
                Price: ₱{new Intl.NumberFormat('en-PH').format(item.price)} 
              </div>
            </div>
          ))}
        </div>
        
        {/* Scroll Right Button */}
        {isHoveredBall && (
          <button 
            onClick={() => scrollRight(ballGownsRef)} 
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg opacity-100 transition-opacity duration-300"
          >
            &gt;
          </button>
        )}
      </div>
    </div>

    <div className="mb-12">
      {/* Cocktail Gowns Section */}
      <section id='cocktail-gown-section'>
        <h2 className="text-2xl font-semibold mb-4 uppercase">Cocktail Gowns</h2>
      </section>
      <div 
        className="relative flex items-center" 
        onMouseEnter={() => setIsHoveredCocktail(true)} 
        onMouseLeave={() => setIsHoveredCocktail(false)}
      >
        {/* Scroll Left Button for Cocktail Gowns */}
        {isHoveredCocktail && (
          <button 
            onClick={() => scrollLeft(CTRef)} 
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg opacity-100 transition-opacity duration-300"
          >
            &lt;
          </button>
        )}
        
        <div 
          ref={CTRef} 
          className="flex space-x-14 overflow-x-auto bg-gray-300 p-6"
        >
          {cocktails.map((item, index) => (
            <div key={index} className="relative w-72 h-96 flex-shrink-0">
              <img 
                src={item.image} 
                alt={`Cocktail Gown ${index + 1}`} 
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105" 
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                <button 
                  onClick={() => {
                    handleViewDetails(item.src, item.price, item.id);
                    setSelectedAttire(item);
                  }}
                  className="text-white text-lg font-semibold"
                >
                  View Details
                </button>
              </div>
              <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
                Price: ₱{new Intl.NumberFormat('en-PH').format(item.price)} 
              </div>
            </div>
          ))}
        </div>

        {/* Scroll Right Button for Cocktail Gowns */}
        {isHoveredCocktail && (
          <button 
            onClick={() => scrollRight(CTRef)} 
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg opacity-100 transition-opacity duration-300"
          >
            &gt;
          </button>
        )}
      </div>
    </div>
        

    <div className="mb-12">
      {/* Suits Collection Section */}
      <section id="suit-section" className="mb-12">
        <div className="bg-[#ffd000] mt-20 mb-6 w-full h-20 flex items-center justify-center relative">
          <h1 className="text-4xl font-bold">MEN'S SECTION</h1>
        </div>
        <h2 className="text-2xl font-semibold mb-4 uppercase">Suits</h2>
        
        <div 
          className="relative flex items-center mb-8" 
          onMouseEnter={() => setIsHoveredSuits(true)} 
          onMouseLeave={() => setIsHoveredSuits(false)}
        >
          {isHoveredSuits && (
            <button
              onClick={() => scrollLeft(singleBreastedRef)}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
          )}
          
          <div ref={singleBreastedRef} className="flex space-x-14 overflow-x-auto bg-gray-300 p-6">
            {suits.map((item, index) => (
              <div key={index} className="relative w-72 h-96 flex-shrink-0">
                <img
                  src={item.image}
                  alt={`Single Breasted Suit ${index + 1}`}
                  className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                  <button
                    onClick={() => {
                      handleViewDetails(item.src, item.price, item.id);
                      setSelectedAttire(item);
                    }}
                    className="text-white text-lg font-semibold"
                  >
                    View Details
                  </button>
                </div>
                <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
                Price: ₱{new Intl.NumberFormat('en-PH').format(item.price)} 
              </div>
              </div>
            ))}
          </div>

          {isHoveredSuits && (
            <button
              onClick={() => scrollRight(singleBreastedRef)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          )}
        </div>
      </section>
    </div>

    <div className="mb-12">
      {/* Barong Collection Section */}
      <section id="barong-section" className="mb-12">
        <h2 className="text-2xl font-semibold mb-4 uppercase">Barong</h2>
        
        <div
          className="relative flex items-center"
          onMouseEnter={() => setIsHoveredBarong(true)}
          onMouseLeave={() => setIsHoveredBarong(false)}
        >
          {isHoveredBarong && (
            <button
              onClick={() => scrollLeft(doubleBreastedRef)}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &lt;
            </button>
          )}

          <div ref={doubleBreastedRef} className="flex space-x-14 overflow-x-auto bg-gray-300 p-6">
            {barongs.map((item, index) => (
              <div key={index} className="relative w-72 h-96 flex-shrink-0">
                <img
                  src={item.image}
                  alt={`Barong ${index + 1}`}
                  className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                  <button
                    onClick={() => {
                      handleViewDetails(item.src, item.price, item.id);
                      setSelectedAttire(item);
                    }}
                    className="text-white text-lg font-semibold"
                  >
                    View Details
                  </button>
                </div>
                <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
                Price: ₱{new Intl.NumberFormat('en-PH').format(item.price)} 
              </div>
              </div>
            ))}
          </div>

          {isHoveredBarong && (
            <button
              onClick={() => scrollRight(doubleBreastedRef)}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
            >
              &gt;
            </button>
          )}
        </div>
      </section>
    </div>

    <div className="mb-12">
      {/* Kids Section */}
      <div className="bg-[#ffd000] mt-20 mb-6 w-full h-20 flex items-center justify-center relative">
        <section id="kids-section">
          <h1 className="text-4xl font-bold">KIDS SECTION</h1>
        </section>
      </div>
      
      <div
        className="relative flex items-center"
        onMouseEnter={() => setIsHoveredKids(true)}
        onMouseLeave={() => setIsHoveredKids(false)}
      >
        {isHoveredKids && (
          <button
            onClick={() => scrollLeft(eveningGownsRef)}
            className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
          >
            &lt;
          </button>
        )}

        <div ref={eveningGownsRef} className="flex space-x-14 overflow-x-auto bg-gray-300 p-6">
          {kids.map((item, index) => (
            <div key={index} className="relative w-72 h-96 flex-shrink-0 ">
              <img
                src={item.image}
                alt={`Kids Outfit ${index + 1}`}
                className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
                <button
                  onClick={() => {
                    handleViewDetails(item.src, item.price, item.id);
                    setSelectedAttire(item);
                  }}
                  className="text-white text-lg font-semibold"
                >
                  View Details
                </button>
              </div>
              <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
                Price: ₱{new Intl.NumberFormat('en-PH').format(item.price)} 
              </div>
            </div>
          ))}
        </div>

        {isHoveredKids && (
          <button
            onClick={() => scrollRight(eveningGownsRef)}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
          >
            &gt;
          </button>
        )}
      </div>
    </div>

      </div>
      <Footer />
      {showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
    <div className="bg-white p-8 rounded-lg w-full max-w-lg relative">
      {/* Close button */}
      <button 
        onClick={() => setShowModal(false)} 
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-3xl font-bold"
      >
        &times;
      </button>
      
      {/* Title and Details */}
      <h2 className="text-2xl text-center font-semibold mb-4">Rental Information</h2>
      <h4 className="text-lg text-center mb-4 text-gray-700">{selectedAttire?.name}</h4>
      
      {/* Image */}
      <div className="flex justify-center mb-4">
        <img 
          src={selectedAttire?.image} 
          alt="Selected Gown" 
          className="w-full max-w-xs rounded-lg shadow-md object-cover"
        />
      </div>
      
      {/* Price and Description */}
      <p className="text-center mt-4 text-xl font-semibold text-gray-800">Price: {selectedPrice} PHP</p>
      <p className="text-center mt-2 text-gray-600">{selectedAttire?.description}</p>
      
      {/* Rent Button */}
      <div className="mt-6 flex justify-center">
        <button
          disabled={selectedAttire?.isRented} // Disable button if attire is already rented
          onClick={handleRentClick}
          className={`w-full max-w-xs py-2 px-4 rounded-lg text-lg font-semibold transition
            ${selectedAttire?.isRented 
              ? "bg-gray-300 text-gray-500 cursor-not-allowed" 
              : "bg-transparent text-black border border-black hover:bg-[#ffd000] hover:text-black"
            }`}
        >
          {selectedAttire?.isRented ? "RENTED" : "RENT"}
        </button>
      </div>
    </div>
  </div>
)}


{showRentModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
    <div className="bg-white p-8 rounded-lg w-2/5 relative">
      <button 
        onClick={() => setShowRentModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <div className="flex items-center justify-center mb-4">
        <i className="fas fa-question-circle text-3xl text-blue-500 mr-2"></i>
        <p className="text-lg text-center">
          Would you like to rent this gown?
        </p>
      </div>
      <div className="flex justify-center space-x-4 mt-4">
        <button
          onClick={() => {/* Add functionality to reserve the gown */}}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg"
        >
          Rent Now!
        </button>
      </div>
    </div>
  </div>
)}

{showRentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
          <div className="bg-white p-8 rounded-lg w-2/5 relative">
            <button 
              onClick={() => setShowRentModal(false)} 
              className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
            >
              &times;
            </button>
            <div className="flex items-center justify-center mb-4">
              <i className="fas fa-question-circle text-3xl text-blue-500 mr-2"></i>
              <p className="text-lg text-center">
                Would you like to rent this gown?
              </p>
            </div>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={() => {
                  setShowRentModal(false);
                  setShowRentalInfoModal(true); // Show the rental info modal
                }}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg"
              >
                Rent Now!
              </button>
            </div>
          </div>
        </div>
      )}
{showRentalInfoModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30 mt-20">
    <div className="bg-white p-8 rounded-lg w-2/5 relative">
      <button 
        onClick={() => setShowRentalInfoModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <h2 className="text-lg text-center mb-4">Rental Information</h2>
      {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}
      <form className='ml-12' onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium">Name:</label>
          <input type="text" name="name" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Email:</label>
          <input type="email" name="email" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Date:</label>
          <input type="date" name="date" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Time:</label>
          <input type="time" name="time" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Branch:</label>
          <select name="branch" className="border p-2 w-full" required>
            <option value="">Select Branch</option>
            <option value="B6 Lot 27 P1 Northgate Park Exclusive Subdivision barangay Santo Cristo">B6 Lot 27 P1 Northgate Park Exclusive Subdivision barangay Santo Cristo</option>
            <option value="B3 Lot 6 Pillar Village HOA Barangay Santo Cristo">B3 Lot 6 Pillar Village HOA Barangay Santo Cristo</option>
          </select>
        </div>
        <div className="mb-8">
          <label className="block text-sm font-medium">Payment Method:</label>
          <select name="paymentMethod" className="border p-2 w-full" required>
            <option value="">Select payment method</option>
            <option value="gcash">Gcash</option>
            <option value="on-site">On site</option>
          </select>
        </div>
        {/* Terms and Conditions */}
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              className="mr-2"
            />
            I accept the <span className="text-blue-500 cursor-pointer ml-1" onClick={() => setShowTermsModal(true)}>Terms and Conditions</span>.
          </label>
          {submitAttempted && !termsAccepted && <p className="text-red-500 text-sm mt-2">You must accept the terms and conditions to proceed.</p>}
        </div>
        <div className="flex justify-center">
          <button type="submit" className="bg-[#ffd000] text-white py-2 px-4 rounded-lg flex items-center justify-center" disabled={loading}>
            {loading ? (
              <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
)}


{/* Terms and Conditions Modal */}
{showTermsModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
    <div className="bg-white p-8 rounded-lg w-3/5 relative">
      <button 
        onClick={() => setShowTermsModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <h2 className="text-2xl text-center mb-6">Terms and Conditions</h2>
<div className="overflow-y-auto max-h-96 mb-4">
  <p className="text-lg mb-2">
    1. The rented attire should be picked up two (2) days before use and should be returned within two (2) days after use.
  </p>
  <p className="text-lg mb-2">
    2. The rental period is for three (3) days ONLY for non-wedding gowns and five (5) days ONLY for wedding gowns, including the day it was taken.
  </p>
  <p className="text-lg mb-2">
    3. Late returns will be charged a penalty on a per day basis. This charge will continue until the gown is returned.
    <ul className="list-disc list-inside ml-5">
      <li>Five Hundred Pesos (P500.00) for Wedding Gown</li>
      <li>Four Hundred Pesos (P400.00) for Long Gown</li>
      <li>Three Hundred Pesos (P300.00) for Cocktail Dress and others</li>
    </ul>
  </p>
  <p className="text-lg mb-2">
    4. The reservation fee for the attires is worth P500.00. This fee is non-refundable and will be forfeited in favor of Amarabelliana if the rental is cancelled or not fulfilled.
  </p>
  <p className="text-lg mb-2">
    5. Customers are required to deposit one (1) valid ID at Amarabelliana’s physical shop and can claim it upon returning the rented garment on the specific deadline given.
  </p>
  <p className="text-lg mb-2">
    6. Rented garments must be returned in the condition they were received. ANY RIPS, TEARS, or DAMAGE to the rented garment will result in a hold of the ID until the rented garment is repaired at the customer’s expense. If the damage is severe and the garment cannot be cleaned or repaired to its original state, the customer is required to pay the full retail price of the rented garment.
  </p>
  <p className="text-lg mb-2">
    7. The payment is considered NON-REFUNDABLE unless Amarabelliana has given their approval upon the request.
  </p>
</div>


      <div className="flex justify-center">
        <button 
          onClick={() => setShowTermsModal(false)} 
          className="bg-[#ffd000] text-white py-2 px-4 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}




  <Toaster />
    </div>
  );
};
export default Collections;