import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import SalesChart from './SalesChart';
import logo from '../assets/logo.png';
import Sidebar from './Sidebar';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../pages/firebase';
import { format, subDays } from 'date-fns';

const SalesReport = () => {
  const [rentals, setRentals] = useState(null);
  const [totalIncome, setTotalIncome] = useState(null);
  const [topRentedAttire, setTopRentedAttire] = useState(null);
  const [uniqueVisitors, setUniqueVisitors] = useState(null);
  const [yesterdayVisitors, setYesterdayVisitors] = useState(null);
  const [allRentals, setAllRentals] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchRentals = async () => {
      const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString();

      const rentalsQuery = query(
        collection(db, 'rentals'),
        where('created_at', '>=', sevenDaysAgo)
      );

      try {
        const querySnapshot = await getDocs(rentalsQuery);
        let rentalsDocs = [];
        let totalIncome = 0;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // Filter out rentals with status 'pending' or 'denied'
          if (data.status !== 'pending' && data.status !== 'denied') {
            rentalsDocs.push(data);
            totalIncome += data.price;
          }
        });

        setRentals(rentalsDocs.length);
        setTotalIncome(totalIncome);
      } catch (error) {
        console.error('Error fetching rentals:', error);
      }
    };

    fetchRentals();
  }, []); // Empty dependency array to run on mount

  useEffect(() => {
    const fetchAllRentals = async () => {
      const rentalsQuery = query(
        collection(db, 'rentals')
        // Add a date filter here if needed in the future
      );

      try {
        const querySnapshot = await getDocs(rentalsQuery);
        let rentalsDocs = [];
        const nameCounts = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          // Filter out rentals with status 'pending' or 'denied'
          if (data.status !== 'pending' && data.status !== 'denied') {
            rentalsDocs.push(data);
            
            // Count attire occurrences by name in imageUrls
            data.imageUrls.forEach((image) => {
              const attireName = image.name;
              nameCounts[attireName] = (nameCounts[attireName] || 0) + 1;
            });
          }
        });

        // Find the most repeated attire name
        let topAttire = null;
        let maxCount = 0;
        for (const [name, count] of Object.entries(nameCounts)) {
          if (count > maxCount) {
            maxCount = count;
            topAttire = name;
          }
        }

        setTopRentedAttire(topAttire);
        setAllRentals(rentalsDocs);
      } catch (error) {
        console.error('Error fetching rentals:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllRentals();
  }, []);

  useEffect(() => {
    const yesterday = format(subDays(new Date(), 1), 'yyyy-MM-dd');
      async function getDailyVisitors(date) {
          const visitDocRef = doc(db, 'visits', date);
        
          try {
            const visitDoc = await getDoc(visitDocRef);
            if (visitDoc.exists()) {
              const data = visitDoc.data();
              return data.users.length; // Number of unique users
            } else {
              return 0; // No visits recorded for this date
            }
          } catch (error) {
            console.error('Error retrieving visitors:', error);
            return 0;
          }
        }
        getDailyVisitors(format(new Date(), 'yyyy-MM-dd')).then((visitors) => {
            setUniqueVisitors(visitors);
          getDailyVisitors(yesterday).then((visitors) => {
            setYesterdayVisitors(visitors);
            setLoading(false);
          });
       });
  }, []);

  const exportToPDF = () => {
    const doc = new jsPDF();
  
    // Add title to the PDF
    doc.setFontSize(18);
    doc.text("Amarabelliana's Boutique - Sales Report", 14, 22);
  
    // Table headers
    const tableColumn = ["Name", "Email", "Date", "Time", "Payment Method", "Price (PHP)", "Status"];
  
    // Map over allRentals and replace the peso sign with "PHP" in the price field
    const tableRows = allRentals.map((sale) => [
      sale.name,
      sale.email,
      format(new Date(sale.date), 'MM-dd-yyyy'),
      new Date(`1970-01-01T${sale.time}:00`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }),
      sale.paymentMethod,
      `PHP ${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sale.price)}`,
      sale.status,
    ]);
  
    // Add autoTable to the PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });
  
    // Save the PDF with a file name
    doc.save('sales_report.pdf');
  };
  

  return (
    <div className="flex min-h-screen bg-gray-400">
      <Sidebar />
      <div className="flex-1 p-8">
        <header className="flex items-center mb-8 ms-12">
          <img src={logo} alt="Amarabelliana's Boutique Logo" className="w-16 h-16 mr-4 rounded-full border border-gray-300" />
          <h1 className="text-4xl font-bold text-gray-800">Amarabelliana's Boutique</h1>
        </header>
        <div className="flex flex-col lg:flex-row shadow-md p-4 space-y-4 lg:space-y-0 lg:space-x-4 bg-white rounded-lg mb-3">
          {/* Statistics Panels */}
          <div className="flex-1 p-4 border border-gray-200 rounded-lg bg-gray-50">
            <div className="text-sm font-medium text-gray-500">Rentals</div>
            <div className="text-2xl font-bold text-gray-900">{loading ? 'Loading...' : rentals}</div>
            <div className="text-xs text-gray-400">In last 7 days</div>
          </div>
          <div className="flex-1 p-4 border border-gray-200 rounded-lg bg-gray-50">
            <div className="text-sm font-medium text-gray-500">Total Income</div>
            <div className="text-2xl font-bold text-gray-900">{loading ? 'Loading...' : `₱${totalIncome}`}</div>
            <div className="text-xs text-gray-400">In last 7 days</div>
          </div>
          <div className="flex-1 p-4 border border-gray-200 rounded-lg bg-gray-50">
            <div className="text-sm font-medium text-gray-500">Top Rented Attire</div>
            <div className="text-2xl font-bold text-gray-900">{loading ? 'Loading...' : topRentedAttire}</div>
            <div className="text-xs text-gray-400">All Time</div>
          </div>
          <div className="flex-1 p-4 border border-gray-200 rounded-lg bg-gray-50">
            <div className="text-sm font-medium text-gray-500">Unique Visitors Today</div>
            <div className="text-2xl font-bold text-gray-900">{loading ? 'Loading...' : uniqueVisitors}</div>
            <div className="text-xs text-gray-400">Yesterday: {loading ? 'Loading...' : yesterdayVisitors}</div>
          </div>
        </div>
        <div className="mb-8 p-6 bg-white rounded-lg shadow">
          <div className='flex justify-between items-center mb-6'>
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Sales Report</h2>
            <button onClick={exportToPDF} className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition">Export to PDF</button>
          </div>
          <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
            <thead>
              <tr className="border-b border-gray-300">
                <th className="p-4 text-left text-gray-600">Name</th>
                <th className="p-4 text-left text-gray-600">Email</th>
                <th className="p-4 text-left text-gray-600">Date</th>
                <th className="p-4 text-left text-gray-600">Time</th>
                <th className="p-4 text-left text-gray-600">Payment Method</th>
                <th className="p-4 text-left text-gray-600">Price (₱)</th>
                <th className="p-4 text-left text-gray-600">Status</th>
              </tr>
            </thead>
            <tbody>
              {allRentals?.map((sale, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-50 transition">
                  <td className="p-4 text-gray-800">{sale.name}</td>
                  <td className="p-4 text-gray-800">{sale.email}</td>
                  <td className="p-4 text-gray-800">{format(new Date(sale.date), 'MM-dd-yyyy')}</td>
                  <td className="p-4 text-gray-800">
                    {new Date(`1970-01-01T${sale.time}:00`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}
                  </td>
                  <td className="p-4 text-gray-800">{sale.paymentMethod}</td>
                  <td className="p-4 text-gray-800">
                    {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(sale.price)}`}
                  </td>
                  <td className="p-4 text-gray-800">{sale.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {allRentals && allRentals.length !== 0 && <SalesChart mockData={allRentals} />}
      </div>
    </div>
  );
};

export default SalesReport;