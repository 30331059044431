import React, { useEffect, useState, useRef } from 'react';
import { auth, storage } from '../pages/firebase';
import Messages from './Messages';
import { db } from '../pages/firebase';
import { collection, getDoc, onSnapshot, doc, setDoc, updateDoc, arrayUnion, query } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { AiOutlinePaperClip, AiOutlinePicture, AiOutlineSend } from 'react-icons/ai'; // Updated send icon

function Chat() {
  const [user, setUser] = useState(null);
  const [isStaff, setIsStaff] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [allMessages, setAllMessages] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState([]);
  const [currentChattingUser, setCurrentChattingUser] = useState(null);

  const scroll = useRef();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log(`Welcome, ${currentUser.displayName || currentUser.email}!`);
      } else {
        setUser(null);
        console.log('Please log in to use the chat.');
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!isStaff) return;
    const q = query(collection(db, "messages"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({ id: doc.id, ...doc.data() });
      });
      setAllMessages(messages);
    });
    return () => unsubscribe();
  }, [isStaff]);

  useEffect(() => {
    if (!allMessages || !currentChattingUser) return;
    const chatMessages = allMessages.find(chat => chat.id === currentChattingUser)?.messages || [];
    setCurrentChat(chatMessages);
  }, [allMessages, currentChattingUser]);

  useEffect(() => {
    if (!user) return;
    const subscriber = onSnapshot(doc(collection(db, 'users'), user.uid), (snapshot) => {
      const userData = snapshot.data();
      setIsStaff(userData?.role !== 'user');
    });
    return () => subscriber();
  }, [user]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!user) {
      alert("Please Login First");
      return;
    }
    if (messageText.trim() !== '') {
      const newMessage = {
        id: uuidv4(),
        text: messageText,
        uid: user.uid,
        name: user.displayName || user.email,
        photoURL: user.photoURL || '',
        timestamp: new Date(),
        isImage: false,
        isFile: false,
      };
      const docRef = doc(db, 'messages', currentChattingUser || user.uid);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, { messages: arrayUnion(newMessage) });
        } else {
          await setDoc(docRef, { messages: [newMessage] });
        }
      } catch (error) {
        console.error("Error updating document:", error);
      }
      setMessageText('');
    }
  };

  const sendImage = async (e) => {
    e.preventDefault();
    if (!user) return alert("Please Login First");

    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => console.error("Error uploading file:", error),
      async () => {
        const imageLink = await getDownloadURL(uploadTask.snapshot.ref);
        if (imageLink) {
          const newMessage = {
            id: uuidv4(),
            text: imageLink,
            uid: user.uid,
            name: user.displayName || user.email,
            photoURL: user.photoURL || '',
            timestamp: new Date(),
            isImage: true,
            isFile: false,
          };
          const docRef = doc(db, 'messages', currentChattingUser || user.uid);
          try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              await updateDoc(docRef, { messages: arrayUnion(newMessage) });
            } else {
              await setDoc(docRef, { messages: [newMessage] });
            }
          } catch (error) {
            console.error("Error updating document:", error);
          }
        }
      }
    );
  };

  const sendFile = async (e) => {
    e.preventDefault();
    if (!user) return alert("Please Login First");

    const file = e.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => console.error("Error uploading file:", error),
      async () => {
        const fileLink = await getDownloadURL(uploadTask.snapshot.ref);
        if (fileLink) {
          const newMessage = {
            id: uuidv4(),
            text: fileLink,
            uid: user.uid,
            name: user.displayName || user.email,
            photoURL: user.photoURL || '',
            timestamp: new Date(),
            isImage: false,
            isFile: true,
          };
          const docRef = doc(db, 'messages', currentChattingUser || user.uid);
          try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              await updateDoc(docRef, { messages: arrayUnion(newMessage) });
            } else {
              await setDoc(docRef, { messages: [newMessage] });
            }
          } catch (error) {
            console.error("Error updating document:", error);
          }
        }
      }
    );
  };

  useEffect(() => {
    if (!user) return;
    const unsubscribe = onSnapshot(doc(collection(db, 'messages'), user.uid), (snapshot) => {
      const docData = snapshot.data();
      if (docData) {
        setMessages(docData.messages);
      }
      if (scroll.current) {
        scroll.current.scrollIntoView({ behavior: 'smooth' });
      }
    });
    return () => unsubscribe();
  }, [user]);

  return (
    <div className="w-full h-full flex flex-col bg-white border border-gray-300 shadow-lg rounded-lg overflow-hidden">
      {isStaff && allMessages && allMessages.length > 0 && (
        <div className="flex overflow-x-auto space-x-4 p-4 bg-gray-50 border-b border-gray-200">
          {allMessages.map((chat) => (
            <div
              key={chat.id}
              className="flex-shrink-0 w-16 h-16 rounded-full overflow-hidden border-2 border-gray-300 cursor-pointer"
              onClick={() => {
                setCurrentChat(chat.messages);
                setCurrentChattingUser(chat.id);
              }}
            >
              <img
                src={chat.messages[0]?.photoURL}
                alt={chat.messages[0]?.name}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex-grow overflow-y-auto p-4 bg-gray-100">
        <Messages user={user} messages={isStaff ? currentChat : messages} scroll={scroll} />
        <div ref={scroll}></div>
      </div>
      {(!isStaff || (isStaff && currentChattingUser)) && (
        <form onSubmit={sendMessage} className="flex items-center p-4 bg-white border-t border-gray-300 space-x-4">
          {/* Attachment buttons on the left side */}
          <div className="flex space-x-2">
            <label htmlFor="fileInput" className="cursor-pointer p-2 rounded-full bg-blue-100 hover:bg-blue-200">
              <AiOutlinePaperClip size={20} />
              <input
                type="file"
                id="fileInput"
                accept="image/*,.pdf,.doc,.ppt"
                className="hidden"
                onChange={sendFile}
              />
            </label>
            <label htmlFor="imageInput" className="cursor-pointer p-2 rounded-full bg-blue-100 hover:bg-blue-200">
              <AiOutlinePicture size={20} />
              <input
                type="file"
                id="imageInput"
                accept="image/*"
                className="hidden"
                onChange={sendImage}
              />
            </label>
          </div>

          {/* Message Input */}
          <input
            type="text"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            placeholder="Type a message"
            className="flex-grow p-2 border border-gray-300 rounded-lg"
          />

          {/* Send button as icon */}
          <button
            type="submit"
            className="pl-4 rounded w-12 h-11 bg-blue-500 text-white hover:bg-blue-600 -mt-[1px]"
          >
            <AiOutlineSend size={20} />
          </button>
        </form>
      )}
    </div>
  );
}

export default Chat;
