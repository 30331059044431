import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon
import Navbar2 from './Navbar2';
import Footer from './Footer';

const Cart = () => {
  const [rentedItems, setRentedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [itemToCancel, setItemToCancel] = useState(null);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchRentedItems = async (user) => {
      if (user) {
        try {
          const rentedItemsRef = collection(db, 'rentals');
          const q = query(rentedItemsRef, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);

          const items = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          setRentedItems(items);
        } catch (error) {
          console.error("Error fetching rented items:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      fetchRentedItems(user);
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleCancel = async () => {
    if (itemToCancel) {
      try {
        await deleteDoc(doc(db, 'rentals', itemToCancel.id));
        setRentedItems(prevItems => prevItems.filter(item => item.id !== itemToCancel.id));
        setShowModal(false);
      } catch (error) {
        console.error("Error canceling item:", error);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-center text-gray-600">Loading your rented items...</p>
      </div>
    );
  }

  if (rentedItems.length === 0) {
    return (
      <div className="flex flex-col min-h-screen">
        <Navbar2 />
        <div className="flex-grow flex items-center justify-center">
          <p className="text-center text-gray-600">No rented items found.</p>
        </div>
      </div>
    );
  }
  

  return (
    <div className="mt-40">
      <Navbar2 />
      <h1 className="text-3xl font-bold text-gray-800 mb-6 mt-14 ml-[310px]">Your Rented Items</h1>
      <div className="flex justify-center items-center mb-72">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-7xl">
          {rentedItems.map(item => (
            <div key={item.id} className="border border-gray-300 p-6 rounded-lg shadow-lg bg-white">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">{item.itemName}</h2>

              {item.imageUrls && item.imageUrls.length > 0 && (
                <div className="mb-4">
                  <p className="text-gray-600"><strong>Attire Images:</strong></p>
                  <div className="grid grid-cols-2 gap-2">
                    {item.imageUrls.map((image, index) => (
                      <img
                        key={index}
                        src={image.image} // Adjusted to access the `image` field
                        alt={`Rented Attire ${index + 1}`}
                        className="w-full h-auto rounded"
                      />
                    ))}
                  </div>
                </div>
              )}

              <div className="text-gray-600 mb-4">
                <p><strong>Renter's Name:</strong> {item.name}</p>
                <p><strong>Email:</strong> {item.email}</p>
                <p><strong>Rent Date:</strong> {new Date(item.date).toLocaleDateString()}</p>
                <p><strong>Price:</strong> ₱{new Intl.NumberFormat('en-PH').format(item.price)}</p>
                <p><strong>Payment Method:</strong> {item.paymentMethod}</p>
                <p><strong>Status:</strong> <span className={`font-semibold ${item.status === 'confirmed' ? 'text-green-600' : 'text-yellow-500'}`}>{item.status}</span></p>
                <p><strong>Time:</strong> {new Date(`1970-01-01T${item.time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-lg font-bold mb-4">Confirm Cancellation</h2>
            <p>Are you sure you want to cancel the rental for "{itemToCancel?.itemName}"?</p>
            <div className="mt-4 flex justify-between">
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded"
                onClick={() => setShowModal(false)}
              >
                No, Keep it
              </button>
              <button
                className="bg-red-500 text-white py-2 px-4 rounded"
                onClick={handleCancel}
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default Cart;
