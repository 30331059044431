import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon

const OnsitePayments = () => {
  const [showForm, setShowForm] = useState(false);
  const [payments, setPayments] = useState([]);

  const handleAddPayment = () => setShowForm(true);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newPayment = {
      customerName: formData.get("customerName"),
      rentedItem: formData.get("rentedItem"),
      amountPaid: formData.get("amountPaid"),
      rentalDate: formData.get("rentalDate"),
    };

    setPayments([...payments, newPayment]);
    setShowForm(false);
    e.target.reset();
  };

  return (
    <div className="p-4 flex mt-8 justify-center">
      <Link to="/staff-dashboard" className="absolute top-4 left-4 text-black hover:text-gray-600">
        <FaArrowLeft className="w-6 h-6" /> {/* Back icon */}
      </Link>
      {/* Left Side - Form */}
      <div className="w-1/2 p-4 flex flex-col items-center">
        <h2 className="text-xl font-bold mb-4">Onsite Payments</h2>
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
          onClick={handleAddPayment}
        >
          Add Onsite Payment
        </button>

        {showForm && (
          <form onSubmit={handleFormSubmit} className="bg-gray-100 p-4 rounded shadow-md w-full max-w-md">
            <div>
              <label>Customer Name:</label>
              <input name="customerName" type="text" required className="border p-2 rounded w-full" />
            </div>
            <div>
              <label>Rented Item:</label>
              <input name="rentedItem" type="text" required className="border p-2 rounded w-full" />
            </div>
            <div>
              <label>Amount Paid:</label>
              <input name="amountPaid" type="number" required className="border p-2 rounded w-full" />
            </div>
            <div>
              <label>Rental Date:</label>
              <input name="rentalDate" type="date" required className="border p-2 rounded w-full" />
            </div>
            <button type="submit" className="bg-green-500 text-white px-4 py-2 mt-4 rounded">
              Save Payment
            </button>
          </form>
        )}
      </div>

      {/* Right Side - Payment Records */}
      <div className="w-1/2 p-4 bg-gray-50 rounded shadow-md flex flex-col items-center">
        <h3 className="text-xl font-bold mb-4">Payment Records</h3>
        {payments.length === 0 ? (
          <p>No onsite payments recorded.</p>
        ) : (
          <ul className="space-y-4 w-full max-w-md">
            {payments.map((payment, index) => (
              <li key={index} className="border p-4 rounded bg-white shadow">
                <p><strong>Customer Name:</strong> {payment.customerName}</p>
                <p><strong>Rented Item:</strong> {payment.rentedItem}</p>
                <p><strong>Amount Paid:</strong> {payment.amountPaid}</p>
                <p><strong>Order ID:</strong> {payment.orderId}</p>
                <p><strong>Rental Date:</strong> {payment.rentalDate}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default OnsitePayments;
