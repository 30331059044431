import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import App from './App';
import StaffDashboard from './components/StaffDashboard';
import Collections from './pages/Collections';
import SignUp from './components/SignUp';
import UserDashboard from './components/UserDashboard';
import AdminDashboard from './components/AdminDashboard';
import AdminProfile from './components/AdminProfile';
import InventoryManagement from './components/InventoryManagement';
import StaffManagement from './components/StaffManagement';
import UserManagement from './components/ManagementDashboard';
import SalesReport from './components/SalesReport';
import MyProfile from './components/MyProfile';
import ProductReviews from './components/ProductReviews';
import RentalManagement from './components/RentalManagement';
import ImageGenerator from './components/ImageGenerator/ImageGenerator';
import ForgotPassword from './components/ForgotPassword';
import Archive from './components/Archive';
import Chat from './components/Chat';
import Collections2 from './components/Collections2';
import MannequinSelector from './components/MannequinSelector';
import RentedAttires from './components/RentedAttires';
import Packages from './components/Packages';
import Cart from './components/cart';
import NewArrivals from './components/NewArrivals';
import PopularChoice from './components/PopularChoice';
import Invoice from './components/Invoice';
import PaymentForm from './components/PaymentForm';
import GcashPayments from './components/GcashPayments';
import RefundForm from './components/RefundForm'; // Import the RefundForm component
import RefundRequestDashboard from './components/RefundRequestDashboard';
import AttireFeedback from './components/AttireFeedback'; // Import the AttireFeedback component
import OnsitePayments from './components/OnsitePayments';
import SuperAdminDashboard from './components/SuperadminDashboard';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import StaffProfile from './components/StaffProfile';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/staff-profile",
        element: <StaffProfile />,
    },
    {
        path: "/terms",
        element: <TermsAndConditions />,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
    },
    {
        path: "/payments-gcash",
        element: <GcashPayments />,
    },
    {
        path: "/payment-form",
        element: <PaymentForm />,
    },
    {
        path: "/invoice",
        element: <Invoice />,
    },
    {
        path: "/new-arrivals",
        element: <NewArrivals />,
    },
    {
        path: "/popular-choices",
        element: <PopularChoice />,
    },
    {
        path: "/customize-model",
        element: <MannequinSelector />,
    },
    {
        path: "/collections2",
        element: <Collections2 />,
    },
    {
        path: "/messages",
        element: <Chat />,
    },
    {
        path: "/ai-generator",
        element: <ImageGenerator />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "/myprofile",
        element: <MyProfile />,
    },
    {
        path: "/collections",
        element: <Collections />,
    },
    {
        path: "/signup",
        element: <SignUp />,
    },
    {
        path: "/user-dashboard",
        element: <UserDashboard />,
    },
    {
        path: "/admin-dashboard",
        element: <AdminDashboard />,
    },
    {
        path: "/staff-dashboard",
        element: <StaffDashboard />,
    },
    {
        path: "/inventory-management",
        element: <InventoryManagement />,
    },
    {
        path: "/staff-management",
        element: <StaffManagement />,
    },
    {
        path: "/user-management",
        element: <UserManagement />,
    },
    {
        path: "/sales-report",
        element: <SalesReport />,
    },
    {
        path: "/product-reviews",
        element: <ProductReviews />,
    },
    {
        path: "/rental-management",
        element: <RentalManagement />,
    },
    {
        path: "/rental-management/edit/:id",
        element: <RentalManagement />,
    },
    {
        path: "/rental-management/add",
        element: <RentalManagement />,
    },
    {
        path: "/archive",
        element: <Archive />,
    },
    {
        path: "/admin-profile",
        element: <AdminProfile />,
    },
    {
        path: "/rented-attires",
        element: <RentedAttires />,
    },
    {
        path: "/package",
        element: <Packages />,
    },
    {
        path: "/cart",
        element: <Cart />,
    },
    {
        path: "/refund", // New route for the RefundForm
        element: <RefundForm />,
    },
    {
        path: "/refund-request", // New route for the RefundRequestDashboard
        element: <RefundRequestDashboard />,
    },
    {
        path: "/feedback", // New route for the AttireFeedback
        element: <AttireFeedback />,
    },
    {
        path: "/payments-onsite", // New route for the AttireFeedback
        element: <OnsitePayments />,
    },
    {
        path: "/superadmin-dashboard", // New route for the AttireFeedback
        element: <SuperAdminDashboard />,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);
