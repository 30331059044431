import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUser, faBell, faSignOutAlt, faBars, faTimes, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.png';
import { getAuth } from 'firebase/auth';
import toast, { Toaster } from 'react-hot-toast';
import { addDoc, collection, doc, getFirestore, updateDoc } from 'firebase/firestore';

import { db } from '../pages/firebase';
import { getDocs } from 'firebase/firestore';

import Chat from './Chat';

import { AiOutlineClose } from 'react-icons/ai';

const StaffDashboard = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const location = useLocation();

  const ballGownsRef = useRef(null);
  const eveningGownsRef = useRef(null);
  const kidsSectionRef = useRef(null);
  const singleBreastedRef = useRef(null);
  const doubleBreastedRef = useRef(null); // Ref for double-breasted suits section

  const [suits, setSuits] = useState([]);
  const [kids, setKids] = useState([]);
  const [evenings, setEvenings] = useState([]);
  const [cocktails, setCocktails] = useState([]);
  const [balls, setBalls] = useState([]);
  const [barongs, setBarongs] = useState([]);

  const [isRenting, setIsRenting] = useState(false);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleLogout = () => {
    // Perform logout logic here (e.g., clearing session, etc.)
    console.log('Logging out...');
    setShowModal(false); // Close the modal

    // Redirect to home page after logging out
    navigate('/'); // Redirect to home page
  };

 
  const fetchItems = async () => {
    console.log("Fetching items...");
    try {
      const eveningsSnapshot = await getDocs(collection(db, 'evenings'));
      setEvenings(eveningsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const cocktailsSnapshot = await getDocs(collection(db, 'cocktails'));
      setCocktails(cocktailsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const ballsSnapshot = await getDocs(collection(db, 'balls'));
      setBalls(ballsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const suitsSnapshot = await getDocs(collection(db, 'suits'));
      setSuits(suitsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const kidsSnapshot = await getDocs(collection(db, 'kids'));
      setKids(kidsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      const barongsSnapshot = await getDocs(collection(db, 'barongs'));
      setBarongs(barongsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    setIsSidebarOpen(true);
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const togglePayments = () => {
    setIsPaymentsOpen(!isPaymentsOpen);
  };

  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };
  const [filter, setFilter] = useState('all');

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const selectedDate = new Date(e.target.date.value);
    const selectedTime = e.target.time.value;

    const currentDate = new Date();
    if (selectedDate < currentDate) {
      toast.error('Please select a future date for the rental.')
      return;
    }

    const rentalInfo = {
      name: e.target.name.value,
      email: "Walk-in Customer",
      date: selectedDate.toISOString(),
      time: selectedTime,
      branch: e.target.branch.value,
      paymentMethod: e.target.paymentMethod.value,
      userId: getAuth().currentUser?.uid,
      status: 'approved', // Set initial status as pending
      imageUrls: [selectedItem], // Filter out any undefined values
      price: selectedItem.price, // Include the selected price from state here
      created_at: new Date().toISOString(),
    };


    console.log('Rental Info:', rentalInfo);

    try {
      await addDoc(collection(getFirestore(), "rentals"), rentalInfo);
      // Handle success (e.g., show a success message or redirect)

      toast.success('Rental submitted successfully!');

      const collectionName = selectedItem.category.includes('Evening') ? 'evenings' :
      selectedItem.category.includes('Cocktail') ? 'cocktails' :
      selectedItem.category.includes('Ball') ? 'balls' :
      selectedItem.category.includes('Suit') ? 'suits' :
      selectedItem.category.includes('Kids') ? 'kids' :
          'barongs';

      updateDoc(doc(getFirestore(), collectionName, selectedItem.id), {
        isRented: true,
      });

      setIsRenting(false);     

    } catch (error) {
      console.error("Error adding rental: ", error);
      toast.error('There was an error submitting your rental information. Please try again later.');
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 flex">
      {/* Sidebar */}
      <div className={`fixed top-0 left-0 z-20 h-screen bg-black text-white p-6 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out shadow-lg rounded-r-lg`}>
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="w-12 h-12 mr-3" />
            <h2 className="text-2xl font-bold">Staff Dashboard</h2>
          </div>
          <FontAwesomeIcon icon={faTimes} className="text-white text-2xl cursor-pointer" onClick={toggleSidebar} />
        </div>
        <nav>
          <ul>
            <li className="mb-4">
              <Link to="/rented-attires" className="flex items-center text-white hover:bg-gray-800 p-2 rounded-md transition ease-in-out">Rented Attires</Link>
            </li>
            <li className="mb-4">
              <Link to="/refund-request" className="flex items-center text-white hover:bg-gray-800 p-2 rounded-md transition ease-in-out">Refund Requests</Link>
            </li>
            <li className="mb-4">
              <button onClick={togglePayments} className="flex items-center justify-between w-full text-white hover:bg-gray-800 p-2 rounded-md transition ease-in-out">
                Payments
                <FontAwesomeIcon icon={isPaymentsOpen ? faChevronUp : faChevronDown} />
              </button>
              {isPaymentsOpen && (
                <div className="ml-4 mt-2 space-y-2">
                  <Link to="/payments-onsite" className="block text-white hover:bg-gray-700 p-2 rounded-md">On Site</Link>
                  <Link to="/payments-gcash" className="block text-white hover:bg-gray-700 p-2 rounded-md">GCash</Link>
                </div>
              )}
            </li>
          </ul>
        </nav>
      </div>

      {/* Modal */}
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-30">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full transform transition-all duration-300 ease-in-out scale-95 hover:scale-100">
              <h3 className="text-2xl font-semibold text-center text-gray-700 mb-6">Are you sure you want to logout?</h3>

              {/* Modal Buttons */}
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleLogout}
                  className="bg-red-500 text-white py-3 px-8 rounded-lg text-lg font-medium hover:bg-red-600 transition duration-300"
                >
                  Yes
                </button>
                <button
                  onClick={() => setShowModal(false)} // Close the modal without logging out
                  className="bg-gray-500 text-white py-3 px-8 rounded-lg text-lg font-medium hover:bg-gray-600 transition duration-300"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        )}


      {/* Chat Modal */}
      {showMessageModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-end z-50 transition-all">
          <div className="bg-white w-[700px] h-[97vh] shadow-xl p-6 flex flex-col rounded-lg mr-4 overflow-hidden">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold">Chat</h2>
              <button
                className="text-gray-600 hover:text-gray-800"
                onClick={() => setShowMessageModal(false)}
              >
                <AiOutlineClose size={24} />
              </button>
            </div>

            {/* Main scrollable area for chat messages */}
            <div className="flex-grow overflow-y-auto">
              {/* Render the Chat component without adding overflow on individual messages */}
              <Chat />
            </div>
          </div>
        </div>
      )}

      {/* Overlay to close sidebar */}
      {isSidebarOpen && (
        <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={toggleSidebar}></div>
      )}

      {/* Main Content Area */}
      <div className={`flex-1 transition-transform duration-300 ease-in-out transform ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        {/* Top Bar with Icons and Dashboard Text */}
        <div className="flex flex-col">
          <div className="flex justify-between items-center p-4 bg-white shadow-md rounded-b-lg">
            <FontAwesomeIcon icon={faBars} className="text-2xl cursor-pointer" onClick={toggleSidebar} />
            <div className="flex items-center space-x-6">
              <button 
                className="text-black focus:outline-none hover:text-gray-100 mt-2"
                onClick={() => setShowMessageModal(!showMessageModal)}
              >
                <i className="fas fa-envelope"></i>
              </button>
              <FontAwesomeIcon icon={faBell} className="text-gold text-xl cursor-pointer" title="Notifications" />
              <Link to="/staff-profile">
                <FontAwesomeIcon icon={faUser} className="text-gold text-xl cursor-pointer" title="Profile" />
              </Link>
              <Link
                to="/"
                className="text-gold text-xl cursor-pointer"
                title="Logout"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default link behavior
                  setShowModal(true); // Show the modal
                }}
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
              </Link>
            </div>
          </div>
          <h1 className="text-3xl font-bold text-gold mb-4 text-center">Staff Dashboard</h1>
        </div>
          

        <div className="flex-grow w-full grid grid-cols-1 p-6">
      {/* Stock Viewing Header */}
      <h2 className="text-2xl font-bold mb-6 uppercase text-center">INVENTORY MONITORING</h2>
      <div className="flex space-x-2">
            {['all', 'evenings', 'cocktails', 'balls', 'suits', 'kids','barongs'].map((category) => (
              <button
                key={category}
                className={`p-2 rounded ${
                  filter === category
                    ? 'bg-blue-700 text-white' // Darker background for selected button
                    : 'bg-blue-500 text-white' // Default background for unselected buttons
                }`}
                onClick={() => setFilter(category)}
              >
                {category === 'all'
                ? 'All'
                : category === 'evenings'
                ? 'Evening Gowns'
                : category === 'cocktails'
                ? 'Cocktail Gowns'
                : category === 'balls'
                ? 'Ball Gowns'
                : category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>
        <div className="flex-grow w-full grid grid-cols-1 p-6">
          {/* Inventory Viewing Section */}
          {(filter === 'all' || filter === 'suits') &&
            <div className="bg-white p-4 shadow-md rounded-lg text-gold w-full h-full">
            {/* Removed the "Suits Collection" header */}
            <h2 className="text-2xl font-semibold mb-4 uppercase">Suits</h2>
            <div className="relative flex items-center mb-8">
              <button 
                onClick={() => scrollLeft(singleBreastedRef)} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &lt;
              </button>
              <div 
                ref={singleBreastedRef} 
                className="flex space-x-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
              >

              {suits.map((suit) => (
                  <div className='relative w-64 min-w-64'>
                    <img src={suit.image} alt={suit.name} className="w-64 h-full object-cover rounded-lg shadow-md cursor-pointer" 
                      onClick={() => {
                    setSelectedItem(suit);
                    setShowDetailsModal(true);
                  }}
                    />
                    {suit.price && (
                      <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold px-2 py-1 rounded-lg">
                      {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(suit.price)}`}
                      </span>
                    )}

                    {suit.isRented ? 
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Rented
                      </span> :
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Available
                      </span>
                    }
                  </div>
                ))}
              </div>
              <button 
                onClick={() => scrollRight(singleBreastedRef)} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &gt;
              </button>
            </div>
          </div>}

          {/* Double Breasted Suits Section */}
          {(filter === 'all' || filter === 'barongs') &&
            <div className="bg-white p-4 shadow-md rounded-lg text-gold w-full h-full">
            <h2 className="text-2xl font-semibold mb-4 uppercase">Barong</h2>
            <div className="relative flex items-center mb-8">
              <button 
                onClick={() => scrollLeft(doubleBreastedRef)} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &lt;
              </button>
              <div 
                ref={doubleBreastedRef} 
                className="flex space-x-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
              >
               {barongs.map((barong) => (
                  <div className='relative w-64 min-w-64'>
                    <img src={barong.image} alt={barong.name} className="w-64 h-full object-cover rounded-lg shadow-md cursor-pointer"
                      onClick={() => {
                        setSelectedItem(barong);
                        setShowDetailsModal(true);
                      }}
                    />
                    {barong.price && (
                      <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold px-2 py-1 rounded-lg">
                      {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(barong.price)}`}
                      </span>
                    )}
                    {barong.isRented ?
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Rented
                      </span> :
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Available
                      </span>
                    }
                    </div>
                ))}
                    
              </div>
              <button 
                onClick={() => scrollRight(doubleBreastedRef)} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &gt;
              </button>
            </div>
          </div>}

        
          {/* Kids Attire Section */}
          {(filter === 'all' || filter === 'kids') &&
            <div className="bg-white p-4 shadow-md rounded-lg text-gold w-full h-full">
            <h2 className="text-2xl font-semibold mb-4 uppercase">Kids Attire</h2>
            <div className="relative flex items-center mb-8">
              <button 
                onClick={() => scrollLeft(kidsSectionRef)} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &lt;
              </button>
              <div 
                ref={kidsSectionRef} 
                className="flex space-x-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
              >
                {kids.map((kid) => (
                  <div className='relative w-72 min-w-72'>
                    <img src={kid.image} alt={kid.name} className="w-72 h-full object-cover rounded-lg shadow-md cursor-pointer"
                      onClick={() => {
                        setSelectedItem(kid);
                        setShowDetailsModal(true);
                      }}
                    />
                    {kid.price && (
                      <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold px-2 py-1 rounded-lg">
                      {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(kid.price)}`}
                      </span>
                    )}
                    {kid.isRented ? 
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Rented
                      </span> :
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Available
                      </span>
                    }
                  </div>
                ))}
              </div>
              <button 
                onClick={() => scrollRight(kidsSectionRef)} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &gt;
              </button>
            </div>
          </div>}

          {/* Evening Gowns Section */}
          {(filter === 'all' || filter === 'evenings') &&
            <div className="bg-white p-4 shadow-md rounded-lg text-gold w-full h-full">
            <h2 className="text-2xl font-semibold mb-4 uppercase">Evening Gowns</h2>
            <div className="relative flex items-center mb-8">
              <button 
                onClick={() => scrollLeft(eveningGownsRef)} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &lt;
              </button>
              <div 
                ref={eveningGownsRef} 
                className="flex space-x-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
              >
                {evenings.map((evening) => (
                  <div className='relative w-96 min-w-96'>
                    <img src={evening.image} alt={evening.name} className="w-96 min-w-96 h-full object-cover rounded-lg shadow-md cursor-pointer"
                      onClick={() => {
                        setSelectedItem(evening);
                        setShowDetailsModal(true);
                      }}
                    />
                    {evening.price && (
                      <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold px-2 py-1 rounded-lg">
                      {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(evening.price)}`}
                      </span>
                    )}
                    
                    {evening.isRented ? 
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Rented
                      </span> :
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Available
                      </span>
                    }
                  </div>
                ))}
              </div>
              <button 
                onClick={() => scrollRight(eveningGownsRef)} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &gt;
              </button>
            </div>
          </div>}

          {/* Ball Gowns Section */}
          {(filter === 'all' || filter === 'balls') &&
            <div className="bg-white p-4 shadow-md rounded-lg text-gold w-full h-full">
            <h2 className="text-2xl font-semibold mb-4 uppercase">Ball Gowns</h2>
            <div className="relative flex items-center mb-8">
              <button 
                onClick={() => scrollLeft(ballGownsRef)} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &lt;
              </button>
              <div 
                ref={ballGownsRef} 
                className="flex space-x-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
              >
                {balls.map((ball) => (
                  <div className='relative w-72 min-w-72'>
                    <img src={ball.image} alt={ball.name} className="w-72 h-full object-cover rounded-lg shadow-md cursor-pointer"
                      onClick={() => {
                        setSelectedItem(ball);
                        setShowDetailsModal(true);
                      }}
                    />
                    {ball.price && (
                      <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold px-2 py-1 rounded-lg">
                      {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(ball.price)}`}
                      </span>
                    )}
                    
                    {ball.isRented ? 
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Rented
                      </span> :
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Available
                      </span>
                    }
                  </div>
                ))}
              </div>
              <button 
                onClick={() => scrollRight(ballGownsRef)} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &gt;
              </button>
            </div>
           
          </div>}
          {(filter ==='all' || filter === 'cocktails') &&
            <div className="bg-white p-4 shadow-md rounded-lg text-gold w-full h-full">
            <h2 className="text-2xl font-semibold mb-4 uppercase">Cocktail Gowns</h2>
            <div className="relative flex items-center mb-8">
              <button 
                onClick={() => scrollLeft(eveningGownsRef)} 
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &lt;
              </button>
              <div 
                ref={eveningGownsRef} 
                className="flex space-x-4 overflow-x-auto pb-4 w-full snap-x snap-mandatory"
              >
                {cocktails.map((cocktail) => (
                  <div className='relative w-96 min-w-96'>
                    <img src={cocktail.image} alt={cocktail.name} className="w-96 min-w-96 h-full object-cover rounded-lg shadow-md cursor-pointer"
                      onClick={() => {
                        setSelectedItem(cocktail);
                        setShowDetailsModal(true);
                      }}
                    />
                    {cocktail.price && (
                      <span className="absolute top-2 left-2 bg-white text-black text-xs font-semibold px-2 py-1 rounded-lg">
                      {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cocktail.price)}`}
                      </span>
                    )}
                    
                    {cocktail.isRented ? 
                      <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Rented
                      </span> :
                      <span className="absolute top-2 right-2 bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded-lg">
                        Available
                      </span>
                    }
                    </div>
                ))}
              </div>
              <button 
                onClick={() => scrollRight(eveningGownsRef)} 
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-yellow-500 text-white p-4 rounded-full z-10 shadow-lg"
              >
                &gt;
              </button>
            </div>
          </div>}
        </div>
        
      </div>
      {showDetailsModal && (
  <div className="fixed inset-0 flex items-center justify-center z-30 bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full h-[90%] flex flex-col overflow-hidden">
      
      {/* Header Section */}
      <h2 className="text-2xl font-semibold mb-4 text-center border-b pb-2 border-gray-300">Item Details</h2>

      {/* Scrollable Content Area */}
      <div className="flex-1 overflow-y-auto">
        <img src={selectedItem.image} alt={selectedItem.name} className="w-full h-96 object-cover rounded-lg shadow-md mb-4" />
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold">{selectedItem.name}</h3>
          <span className="text-xl font-semibold">
            {`₱${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(selectedItem.price)}`}
          </span>
        </div>
        <p className="text-gray-600 mb-4">{selectedItem.description}</p>

        {/* Rent Form */}
        {isRenting && (
          <form className="flex flex-col space-y-4 -ml-[1px]" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium">Renter Name:</label>
              <input type="text" name="name" className="border p-2 w-full" required />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Date:</label>
              <input type="date" name="date" className="border p-2 w-full" required />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Time:</label>
              <input type="time" name="time" className="border p-2 w-full" required />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium">Branch:</label>
              <select name="branch" className="border p-2 w-full" required>
                <option value="">Select Branch</option>
                <option value="B6 Lot 27 P1 Northgate Park Exclusive Subdivision barangay Santo Cristo">B6 Lot 27 P1 Northgate Park Exclusive Subdivision barangay Santo Cristo</option>
                <option value="B3 Lot 6 Pillar Village HOA Barangay Santo Cristo">B3 Lot 6 Pillar Village HOA Barangay Santo Cristo</option>
              </select>
            </div>
            {/* Adjusted margin for Payment Method */}
            <div className="mb-4">
              <label className="block text-sm font-medium">Payment Method:</label>
              <select name="paymentMethod" className="border p-2 w-full" required>
                <option value="">Select payment method</option>
                <option value="gcash">Gcash</option>
                <option value="on-site">On site</option>
              </select>
            </div>
            <button type="submit" className="bg-gold text-black font-semibold py-2 rounded-lg hover:bg-green-600 transition duration-300">
              Rent Item
            </button>
          </form>
        )}

        {/* Availability Message or Rent Button */}
        {selectedItem.isRented ? (
          <span className="text-red-500 text-sm font-semibold text-center">This item is currently rented out.</span>
        ) : (
          <button 
            onClick={() => setIsRenting(!isRenting)} 
            className="bg-gold text-black font-semibold py-2 rounded-lg hover:bg-green-600 transition duration-300 w-full text-center border border-black" // Added border
          >
            {isRenting ? 'Cancel' : 'Mark as Rented'}
          </button>
        )}

      </div>

      {/* Footer Section */}
      <div className="flex justify-center mt-4 space-x-4 w-full">
        {!isRenting && (
          <button 
            onClick={() => setShowDetailsModal(false)} 
            className="bg-transparent border border-gray-600 border-solid text-black font-semibold py-2 px-40 hover:text-white rounded-lg hover:bg-gray-600 transition duration-300 w-full sm:w-auto"
          >
            Close
          </button>
        )}
      </div>
    </div>
    <Toaster />
  </div>
)}


    </div>
  );
};

export default StaffDashboard;
