import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import DebutPackageImage from '../assets/Debut Package.jpg';
import SilverPackageImage from '../assets/Silver Package.jpg';
import GoldPackageImage from '../assets/Gold Package.jpg';
import DiamondPackageImage from '../assets/Diamond Package.jpg';
import Chat from './Chat';
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon
import Navbar2 from './Navbar2';
import Footer from './Footer';

const Packages = () => {
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);

  const openChatModal = () => {
    setIsChatModalOpen(true);
  };

  const closeChatModal = () => {
    setIsChatModalOpen(false);
  };

  const packages = [
    {
      id: 1,
      title: 'Debut Package',
      image: DebutPackageImage,
      details: [
        '2 Luxury Ball Gowns',
        'All accessories: ',
        'Earrings, Necklace, Crown, Shoes',
        'Debut Robe',
        'Petticoat',
        'Mannequin',
      ]
    },
    {
      id: 2,
      title: 'Silver Wedding Package',
      image: SilverPackageImage,
      details: [
        '1 Luxury Wedding Gown with Bouquet',
        '1 Luxury Suit with Boutonniere',
        'Includes: Vest, Suspender, Bowtie/Necktie',
        'Prenuptial Dress and Suit:',
        'Wedding robe, Accessories set, Wedding Shoes, Wedding Essentials',
        '1 Mannequin (free to use)',
      ]
    },
    {
      id: 3,
      title: 'Gold Wedding Package',
      image: GoldPackageImage,
      details: [
        '1 Luxury Wedding Gown with Bouquet',
        '1 Luxury Suit with Boutonniere',
        'Includes: Vest, Suspender, Bowtie/Necktie',
        'Prenuptial Dress and Suit:',
        'Wedding robe, Accessories set, Wedding Shoes, Wedding Essentials',
        '1 Mannequin (free to use)',
        'Entourage:',
        '2 Father\'s Suit with Boutonniere (with Long sleeve, Bowtie/Necktie)',
        '2 Mother\'s Dress with Bouquet',
      ]
    },
    {
      id: 4,
      title: 'All-in Diamond Wedding Package',
      image: DiamondPackageImage,
      details: [
        '1 Luxury Wedding Gown with Bouquet',
        '1 Luxury Suit with Boutonniere',
        'Includes: Vest, Suspender, Bowtie/Necktie',
        'Prenuptial Dress and Suit:',
        'Wedding robe, Accessories set, Wedding Shoes, Wedding Essentials',
        '1 Mannequin (free to use)',
        'Entourage:',
        '2 Father\'s Suit with Boutonniere (with Long sleeve, Bowtie/Necktie)',
        '2 Mother\'s Dress with Bouquet',
        '6 Groomsmen with Boutonniere (with long sleeve, Bowtie/Necktie, Suspender)',
        '6 Bridesmaid Dress (Special Dress)',
        '3 Flower Girls Dress',
        '3 Boys\' Suit',
        '1 Maid of Honor Special Dress',
        '1 Best Man\'s Special Suit',
      ]
    },
  ];

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (pkg) => {
    setSelectedPackage(pkg);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setSelectedPackage(null);
  };

  const handleInquireNow = () => {
    alert('Thank you for your inquiry! We will get back to you shortly.');
  };

  return (
    <div className="bg-gray-50 text-center">
      {/* Modified Navbar2 position to left side */}
      <div className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
        <Navbar2 />
      </div>
      
      <h2 className="mb-20 text-5xl font-bold text-gray-800 mt-40">Exclusive Boutique Packages</h2>
      <div className="flex flex-wrap justify-center">
        {packages.map((pkg) => (
          <div
            className="bg-white rounded-lg shadow-lg w-80 m-4 p-6 transition-transform transform hover:scale-105 cursor-pointer border border-black border-solid"
            key={pkg.id}
            onClick={() => openModal(pkg)}
          >
            <img src={pkg.image} alt={pkg.title} className="w-full h-40 object-cover rounded-lg mb-4" />
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">{pkg.title}</h3>
            <p className="text-gray-700">Click for details</p>
          </div>
        ))}
      </div>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-11/12 max-w-lg max-h-[80vh] overflow-y-auto relative">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 transition duration-200 text-3xl"
              onClick={closeModal}
            >
              &times; {/* This is the X symbol */}
            </button>
            <h3 className="text-3xl font-semibold mb-4">{selectedPackage.title}</h3>
            <img src={selectedPackage.image} alt={selectedPackage.title} className="w-full h-48 object-cover rounded-lg mb-4" />
            <div className="text-left">
              <ul className="list-disc pl-6 text-gray-700">
                {selectedPackage.details.map((detail, index) => (
                  <li key={index} className="mb-1">{detail}</li>
                ))}
              </ul>
            </div>
            <div className="flex justify-center mt-4">
              <button
                className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
                onClick={openChatModal}
              >
                Inquire Now
              </button>

              {isChatModalOpen && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
                  <div className="bg-white max-w-[700px] w-full max-h-[90%] shadow-xl p-6 flex flex-col overflow-hidden rounded-lg relative">
                    <button 
                      onClick={closeChatModal} 
                      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-3xl"
                    >
                      &times; {/* This is the X symbol */}
                    </button>
                    <Chat />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mt-24 p-6 bg-white shadow-lg rounded-lg mb-24 mx-40 border border-black border-solid">
        <h3 className="text-4xl font-semibold text-gray-800 mb-4">Why Choose Us?</h3>
        <p className="text-gray-600 mb-2">
          At our boutique, we are committed to making your special day truly unforgettable. Our carefully curated packages provide everything you need to create a magical experience.
        </p>
        <p className="text-gray-600 mb-2">
          Our team of dedicated professionals is here to ensure every detail is perfect, from stunning attire to elegant accessories. We pride ourselves on our exceptional service and attention to detail.
        </p>
        <p className="text-gray-600">
          Let us help you bring your vision to life. Book your package today and discover the elegance and sophistication we offer!
        </p>
      </div>
      
      {/* Fixed Footer at the bottom of the screen */}
      <Footer className="fixed bottom-0 left-0 w-full bg-gray-800 text-white" />
    </div>
  );
};

export default Packages;
