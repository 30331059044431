import React, { useRef, useState, useEffect } from 'react';
import { doc, getDoc, getFirestore, addDoc, collection } from 'firebase/firestore'; // Import Firestore functions
import { getAuth } from 'firebase/auth';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

import new1 from '../assets/new1.jpg';
import new2 from '../assets/new2.jpg';
import new3 from '../assets/new3.jpg';
import new4 from '../assets/new4.jpg';
import new5 from '../assets/new5.jpg';
import new6 from '../assets/new6.jpg';
import ballgown7 from '../assets/ballgown7.jpg';

import eveninggown1 from '../assets/eveninggown1.jpg';
import eveninggown2 from '../assets/eveninggown2.jpg';
import eveninggown3 from '../assets/eveninggown3.jpg';
import eveninggown4 from '../assets/eveninggown4.jpg';
import eveninggown5 from '../assets/eveninggown5.jpg';
import eveninggown6 from '../assets/eveninggown6.jpg';

import kids1 from '../assets/kids1.png';
import kids2 from '../assets/kids2.png';

import singleBreasted1 from '../assets/singleBreasted1.jpg';
import singleBreasted2 from '../assets/singleBreasted2.jpg';
import singleBreasted3 from '../assets/singleBreasted3.jpg';
import singleBreasted4 from '../assets/singleBreasted4.jpg';
import singleBreasted5 from '../assets/singleBreasted5.jpg';
import singleBreasted6 from '../assets/singleBreasted6.jpg';

import doubleBreasted1 from '../assets/doubleBreasted1.jpg';
import doubleBreasted2 from '../assets/doubleBreasted2.jpg';
import doubleBreasted3 from '../assets/doubleBreasted3.jpg';
import doubleBreasted4 from '../assets/doubleBreasted4.jpg';
import doubleBreasted5 from '../assets/doubleBreasted5.jpg';
import doubleBreasted6 from '../assets/doubleBreasted6.jpg';

import barong1 from '../assets/barong1.png';
import barong2 from '../assets/barong2.png';
import barong3 from '../assets/barong3.png';

import ct1 from '../assets/ct1.jpg';
import ct2 from '../assets/ct2.jpg';
import ct3 from '../assets/ct3.jpg';



import '../pages/Collections.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { FaArrowLeft } from 'react-icons/fa'; // Import the icon

const Collections = () => {
  const ballGownsRef = useRef(null);
  const eveningGownsRef = useRef(null);
  const singleBreastedRef = useRef(null);
  const doubleBreastedRef = useRef(null);
  const CTRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showRentModal, setShowRentModal] = useState(false);
  const [showRentalInfoModal, setShowRentalInfoModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [userStatus, setUserStatus] = useState(null); // Update state to use 'status'
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedAttireIds, setSelectedAttireIds] = useState([]);

  const attireMap = {
    // Ball Gowns
    'attireId1': { src: new1, price: 150, name: 'Ball Gown 1' },
    'attireId2': { src: new2, price: 200, name: 'Ball Gown 2' },
    'attireId3': { src: new3, price: 170, name: 'Ball Gown 3' },
    'attireId4': { src: new4, price: 180, name: 'Ball Gown 4' },
    'attireId5': { src: new5, price: 190, name: 'Ball Gown 5' },
    'attireId6': { src: new6, price: 200, name: 'Ball Gown 6' },
    
    // Evening Gowns
    'attireId8': { src: eveninggown1, price: 220, name: 'Evening Gown 1' },
    'attireId9': { src: eveninggown2, price: 250, name: 'Evening Gown 2' },
    'attireId10': { src: eveninggown3, price: 230, name: 'Evening Gown 3' },
    'attireId11': { src: eveninggown4, price: 240, name: 'Evening Gown 4' },
    'attireId12': { src: eveninggown5, price: 260, name: 'Evening Gown 5' },
    'attireId13': { src: eveninggown6, price: 270, name: 'Evening Gown 6' },
  
    // Kids' Outfits
    'attireId14': { src: kids1, price: 100, name: 'Kids Outfit 1' },
    'attireId15': { src: kids2, price: 120, name: 'Kids Outfit 2' },
  
    // Single Breasted Suits
    'attireId16': { src: singleBreasted1, price: 300, name: 'Single Breasted Suit 1' },
    'attireId17': { src: singleBreasted2, price: 320, name: 'Single Breasted Suit 2' },
    'attireId18': { src: singleBreasted3, price: 310, name: 'Single Breasted Suit 3' },
    'attireId19': { src: singleBreasted4, price: 340, name: 'Single Breasted Suit 4' },
    'attireId20': { src: singleBreasted5, price: 350, name: 'Single Breasted Suit 5' },
    'attireId21': { src: singleBreasted6, price: 360, name: 'Single Breasted Suit 6' },
  
    // Double Breasted Suits
    'attireId22': { src: doubleBreasted1, price: 400, name: 'Double Breasted Suit 1' },
    'attireId23': { src: doubleBreasted2, price: 420, name: 'Double Breasted Suit 2' },
    'attireId24': { src: doubleBreasted3, price: 410, name: 'Double Breasted Suit 3' },
    'attireId25': { src: doubleBreasted4, price: 440, name: 'Double Breasted Suit 4' },
    'attireId26': { src: doubleBreasted5, price: 450, name: 'Double Breasted Suit 5' },
    'attireId27': { src: doubleBreasted6, price: 460, name: 'Double Breasted Suit 6' },
  
    // Barongs
    'attireId28': { src: barong1, price: 180, name: 'Barong 1' },
    'attireId29': { src: barong2, price: 200, name: 'Barong 2' },
    'attireId30': { src: barong3, price: 220, name: 'Barong 3' },

    // Cocktail
    'attireId31': { src: ct1, price: 180, name: 'ct 1' },
    'attireId32': { src: ct2, price: 200, name: 'ct 2' },
    'attireId33': { src: ct3, price: 220, name: 'ct 3' },
  };
  
  

  useEffect(() => {
    const fetchUserStatus = async () => {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;

      if (!userId) {
        console.error("No user is logged in.");
        return;
      }

      const userDocRef = doc(getFirestore(), 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const status = userDocSnap.data().status; // Fetch the 'status' field
        console.log("User Status:", status); // Log the status
        setUserStatus(status);
      } else {
        console.log("No such document!");
      }
    };

    fetchUserStatus();
  }, []);

  const handleAttireSelect = (attireId) => {
    setSelectedAttireIds(prevIds => {
      if (prevIds.includes(attireId)) {
        return prevIds.filter(id => id !== attireId); // Deselect if already selected
      } else {
        return [...prevIds, attireId]; // Select if not selected
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const selectedDate = new Date(e.target.date.value);
    const selectedTime = e.target.time.value;

    setSubmitAttempted(true);

    if (!termsAccepted) {
      return; // Do not proceed if terms are not accepted
    }

    if (selectedDate < currentDate) {
      setErrorMessage('Please select a date that is today or in the future.');
      return;
    }

    const [selectedHours, selectedMinutes] = selectedTime.split(':');
    const selectedDateTime = new Date(selectedDate);
    selectedDateTime.setHours(selectedHours);
    selectedDateTime.setMinutes(selectedMinutes);

    if (selectedDateTime <= currentDate) {
      setErrorMessage('Please select a time that is in the future.');
      return;
    }

    setErrorMessage(''); // Clear previous errors
    setLoading(true); // Show loading spinner

    const attireIds = selectedAttireIds; // Get the selected attire IDs from state
    
    // Use attireMap to get the corresponding sources
    const selectedImageUrls = attireIds.map(id => attireMap[id]); // Map selected IDs to their sources

    const rentalInfo = {
      name: e.target.name.value,
      email: e.target.email.value,
      date: selectedDate.toISOString(),
      time: selectedTime,
      branch: e.target.branch.value,
      paymentMethod: e.target.paymentMethod.value,
      userId: getAuth().currentUser?.uid,
      status: 'pending', // Set initial status as pending
      imageUrls: selectedImageUrls.filter(url => url !== undefined), // Filter out any undefined values
      price: selectedPrice, // Include the selected price from state here
    };

    // Save rentalInfo to Firestore
    try {
      await addDoc(collection(getFirestore(), "rentals"), rentalInfo);
      // Handle success (e.g., show a success message or redirect)

      setTimeout(() => {
        setLoading(false); // Hide loading spinner
        Swal.fire({
          title: 'Success!',
          text: 'Your rental information has been submitted.',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: 'green',
        });
        setShowRentalInfoModal(false);
      }, 5000);

    } catch (error) {
      console.error("Error adding rental: ", error);
      setErrorMessage('There was an error submitting your rental information. Please try again later.');
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -ref.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: ref.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  const handleViewDetails = (src, price, id) => {
    handleAttireSelect(id); // Call this function to select the attire
    setSelectedImage(src);
    setSelectedPrice(price); // Store the selected price
    setShowModal(true);
  };

  const handleRentClick = () => {
    console.log("Current User Status:", userStatus); // Log current user status

    if (userStatus === 'approved') {
      setShowRentModal(true); // Open rent modal if approved
    } else if (userStatus === 'pending') {
      Swal.fire({
        title: 'Renting Not Allowed',
        text: 'Your account is currently pending approval. Please check back later or contact support.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red',
      });
    } else {
      Swal.fire({
        title: 'Renting Not Allowed',
        text: 'Your account is not approved for rentals. Please contact support.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red',
      });
    }
  };


  return (
    <div className="w-full -mt-14 min-h-screen bg-gray-200">
      <Link to="/user-dashboard" className="absolute top-4 left-4 text-black hover:text-gray-600">
        <FaArrowLeft className="w-6 h-6" /> {/* Back icon */}
      </Link>
    <div className="p-8 bg-gray-100">
      <div className="bg-[#ffd000] mt-20 mb-6 w-full h-20 flex items-center justify-center relative">
        <h1 className="text-4xl font-bold">NEW ARRIVALS</h1>
      </div>
      <div className="mb-12">
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 p-6 bg-gray-100">
    {[
      { src: new1, price: 3000, id: 'attireId1' },
      { src: new2, price: 3200, id: 'attireId2' },
      { src: new3, price: 4000, id: 'attireId3' },
      { src: new4, price: 3800, id: 'attireId4' },
      { src: new5, price: 3300, id: 'attireId5' },
      { src: new6, price: 3500, id: 'attireId6' },
    ].map((item, index) => (
      <div key={index} className="relative w-full h-96 p-4 bg-white shadow-lg rounded-lg overflow-hidden">
        <img 
          src={item.src} 
          alt={`Ball Gown ${index + 1}`} 
          className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105" 
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300 ease-in-out flex items-center justify-center">
          <button 
            onClick={() => handleViewDetails(item.src, item.price, item.id)}
            className="text-white text-lg font-semibold"
          >
            View Details
          </button>
        </div>
        <div className="absolute bottom-4 left-4 bg-white text-black p-2 rounded-lg shadow-md">
          Price: {item.price} PHP
        </div>
      </div>
    ))}
  </div>
</div>


      </div>
      {showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
    <div className="bg-white p-8 rounded-lg w-1/3 relative">
      <button 
        onClick={() => setShowModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <img 
        src={selectedImage} 
        alt="Selected Gown" 
        className="w-full h-auto max-w-md mx-auto"
      />
      <div className="mt-4 flex space-x-4">
        <button 
          onClick={handleRentClick}
          className="w-full border border-black bg-transparent text-black py-2 rounded-lg hover:bg-[#ffd000] transition"
        >
          RENT
        </button>
      </div>
    </div>
  </div>
)}

{showRentModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
    <div className="bg-white p-8 rounded-lg w-2/5 relative">
      <button 
        onClick={() => setShowRentModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <div className="flex items-center justify-center mb-4">
        <i className="fas fa-question-circle text-3xl text-blue-500 mr-2"></i>
        <p className="text-lg text-center">
          Would you like to rent this gown?
        </p>
      </div>
      <div className="flex justify-center space-x-4 mt-4">
        <button
          onClick={() => {/* Add functionality to reserve the gown */}}
          className="bg-blue-500 text-white py-2 px-4 rounded-lg"
        >
          Rent Now!
        </button>
      </div>
    </div>
  </div>
)}

{showRentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30">
          <div className="bg-white p-8 rounded-lg w-2/5 relative">
            <button 
              onClick={() => setShowRentModal(false)} 
              className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
            >
              &times;
            </button>
            <div className="flex items-center justify-center mb-4">
              <i className="fas fa-question-circle text-3xl text-blue-500 mr-2"></i>
              <p className="text-lg text-center">
                Would you like to rent this gown?
              </p>
            </div>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={() => {
                  setShowRentModal(false);
                  setShowRentalInfoModal(true); // Show the rental info modal
                }}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg"
              >
                Rent Now!
              </button>
            </div>
          </div>
        </div>
      )}
{showRentalInfoModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-30 mt-20">
    <div className="bg-white p-8 rounded-lg w-2/5 relative">
      <button 
        onClick={() => setShowRentalInfoModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <h2 className="text-lg text-center mb-4">Rental Information</h2>
      {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}
      <form className='ml-12' onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium">Name:</label>
          <input type="text" name="name" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Email:</label>
          <input type="email" name="email" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Date:</label>
          <input type="date" name="date" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Time:</label>
          <input type="time" name="time" className="border p-2 w-full" required />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium">Branch:</label>
          <select name="branch" className="border p-2 w-full" required>
            <option value="">Select Branch</option>
            <option value="B6 Lot 27 P1 Northgate Park Exclusive Subdivision barangay Santo Cristo">B6 Lot 27 P1 Northgate Park Exclusive Subdivision barangay Santo Cristo</option>
            <option value="B3 Lot 6 Pillar Village HOA Barangay Santo Cristo">B3 Lot 6 Pillar Village HOA Barangay Santo Cristo</option>
          </select>
        </div>
        <div className="mb-8">
          <label className="block text-sm font-medium">Payment Method:</label>
          <select name="paymentMethod" className="border p-2 w-full" required>
            <option value="">Select payment method</option>
            <option value="gcash">Gcash</option>
            <option value="on-site">On site</option>
          </select>
        </div>
        {/* Terms and Conditions */}
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              className="mr-2"
            />
            I accept the <span className="text-blue-500 cursor-pointer ml-1" onClick={() => setShowTermsModal(true)}>Terms and Conditions</span>.
          </label>
          {submitAttempted && !termsAccepted && <p className="text-red-500 text-sm mt-2">You must accept the terms and conditions to proceed.</p>}
        </div>
        <div className="flex justify-center">
          <button type="submit" className="bg-[#ffd000] text-white py-2 px-4 rounded-lg flex items-center justify-center" disabled={loading}>
            {loading ? (
              <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
              </svg>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </div>
  </div>
)}


{/* Terms and Conditions Modal */}
{showTermsModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
    <div className="bg-white p-8 rounded-lg w-3/5 relative">
      <button 
        onClick={() => setShowTermsModal(false)} 
        className="absolute top-4 right-4 text-black text-4xl font-bold p-2"
      >
        &times;
      </button>
      <h2 className="text-2xl text-center mb-6">Terms and Conditions</h2>
<div className="overflow-y-auto max-h-96 mb-4">
  <p className="text-lg mb-2">
    1. The rented attire should be picked up two (2) days before use and should be returned within two (2) days after use.
  </p>
  <p className="text-lg mb-2">
    2. The rental period is for three (3) days ONLY for non-wedding gowns and five (5) days ONLY for wedding gowns, including the day it was taken.
  </p>
  <p className="text-lg mb-2">
    3. Late returns will be charged a penalty on a per day basis. This charge will continue until the gown is returned.
    <ul className="list-disc list-inside ml-5">
      <li>Five Hundred Pesos (P500.00) for Wedding Gown</li>
      <li>Four Hundred Pesos (P400.00) for Long Gown</li>
      <li>Three Hundred Pesos (P300.00) for Cocktail Dress and others</li>
    </ul>
  </p>
  <p className="text-lg mb-2">
    4. The reservation fee for the attires is worth P500.00. This fee is non-refundable and will be forfeited in favor of Amarabelliana if the rental is cancelled or not fulfilled.
  </p>
  <p className="text-lg mb-2">
    5. Customers are required to deposit one (1) valid ID at Amarabelliana’s physical shop and can claim it upon returning the rented garment on the specific deadline given.
  </p>
  <p className="text-lg mb-2">
    6. Rented garments must be returned in the condition they were received. ANY RIPS, TEARS, or DAMAGE to the rented garment will result in a hold of the ID until the rented garment is repaired at the customer’s expense. If the damage is severe and the garment cannot be cleaned or repaired to its original state, the customer is required to pay the full retail price of the rented garment.
  </p>
  <p className="text-lg mb-2">
    7. The payment is considered NON-REFUNDABLE unless Amarabelliana has given their approval upon the request.
  </p>
</div>


      <div className="flex justify-center">
        <button 
          onClick={() => setShowTermsModal(false)} 
          className="bg-[#ffd000] text-white py-2 px-4 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}





    </div>
  );
};
export default Collections;