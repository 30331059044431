import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SuperAdminLoginModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Hardcoded super admin credentials
  const superAdminCredentials = {
    email: 'superadmin@gmail.com',
    password: 'superadmin123', // Use a secure password in a real application
  };

  const handleLogin = (e) => {
    e.preventDefault();

    // Check if the entered credentials match the super admin credentials
    if (email === superAdminCredentials.email && password === superAdminCredentials.password) {
      onClose(); // Close the modal
      navigate('/superadmin-dashboard'); // Redirect to the super admin dashboard
    } else {
      setErrorMessage('Invalid email or password. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-center mb-4">Super Admin Login</h2>
        
        {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}
        
        <form onSubmit={handleLogin} className='-ml-[1px]'>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            />
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            />
          </div>
          
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => onClose(false)}
              className="px-4 py-2 mr-2 bg-gray-300 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SuperAdminLoginModal;
