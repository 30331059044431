import React, { useState, useRef,useEffect } from 'react';
import 'tailwindcss/tailwind.css'; // Ensure Tailwind CSS is imported
import { Link } from 'react-router-dom';
import { db } from '../pages/firebase';
import { auth, storage } from '../pages/firebase';
import { ref, uploadBytesResumable, getDownloadURL,listAll } from 'firebase/storage';
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import Navbar2 from './Navbar2';
import Footer from './Footer';

const MannequinSelector = () => {
  const [mannequinType, setMannequinType] = useState("");
  const [skinTone, setSkinTone] = useState(""); 
  const [bodyShape, setBodyShape] = useState("");
  const [showGownModel, setShowGownModel] = useState(false);
  const [showRightContainer, setShowRightContainer] = useState(true);
  const [currentGownIndex, setCurrentGownIndex] = useState(0);
  const [selectedGown, setSelectedGown] = useState("");
  const [images, setImages] = useState([]); // State to store the images
  const [isModalOpen, setIsModalOpen] = useState(!!selectedGown);

  const modelViewerRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle downloading the 3D model as an image
  const handleDownloadImage = () => {
    const modelViewer = modelViewerRef.current;
    if (modelViewer) {
      const canvas = document.createElement("canvas");
      canvas.width = modelViewer.offsetWidth;
      canvas.height = modelViewer.offsetHeight;

      const context = canvas.getContext("2d");
      const img = new Image();
      img.crossOrigin = "anonymous"; // To prevent cross-origin issues
      img.src = modelViewer.toDataURL("image/png"); // Capture the model as an image
      img.onload = () => {
        context.drawImage(img, 0, 0, canvas.width, canvas.height);
        const link = document.createElement("a");
        link.download = "3D_model.png"; // File name for download
        link.href = canvas.toDataURL("image/png"); // Convert canvas to image
        link.click();
      };
    }
  };

  const handleMannequinTypeChange = (e) => {
    setMannequinType(e.target.value);
    setSkinTone("");  
    setBodyShape("");  
    setShowGownModel(false); // Reset gown display when changing mannequin type
    setShowRightContainer(true); // Show right container when type changes
    setCurrentGownIndex(0); // Reset gown index
  };

  const handleSkinToneChange = (e) => setSkinTone(e.target.value);
  const handleBodyShapeChange = (e) => {
    setBodyShape(e.target.value);
    setSkinTone("");
    setShowGownModel(false); // Reset gown display when changing body shape
    setCurrentGownIndex(0); // Reset gown index
  };

  // Reset all selections to initial state
    const refreshModel = () => {
      setMannequinType('');
      setBodyShape('');
      setSkinTone('');
      setShowGownModel(false);
      setShowRightContainer(true);
      setCurrentGownIndex(0);
      setSelectedGown('');
    };

  const baseModel = {
    women: "WOMAN MANNEQUIN.glb",
    men: "34.glb",
    "girl-kid": "GIRL MANNEQUIN.glb",
    "boy-kid": "BOY MANNEQUIN.glb"
  }[mannequinType] || "";

  const bodyShapeModel = {
    women: {
      shape1: "WOMAN MANNEQUIN.glb",
      shape2: "CURVY 6.glb",
      shape3: "STRAIGHT 10.glb",
      shape4: "CURVY 14.glb",
      shape5: "PLUS STRAIGHT 14.glb",
    },
    men: {
      shape1: "34.glb",
      shape2: "MAN MANNEQUIN.glb",
      shape3: "40.glb",
      shape4: "42.glb",
      shape5: "46.glb",
    }
  }[mannequinType] || {};

  const skinToneOptions = {
    women: {
      shape1: [
        { label: "Pale, Ivory White Skin", file: "LIGHTEST.glb" },
        { label: "Beige Skin", file: "2ND LIGHTEST.glb" },
        { label: "Light to Medium Skin", file: "3RD LIGHTEST.glb" },
        { label: "Medium Brown Skin", file: "PINKISH.glb" },
        { label: "Deep Brown Skin", file: "DARKEST (3).glb" },
      ],
      shape2: [
        { label: "Pale, Ivory White Skin", file: "CURVY 6_LIGHTEST.glb" },
        { label: "Beige Skin", file: "CURVY 6_2ND LIGHTEST.glb" },
        { label: "Light to Medium Skin", file: "CURVY 6_3RD COLOR.glb" },
        { label: "Medium Brown Skin", file: "CURVY 6_2ND DARKEST.glb" },
        { label: "Deep Brown Skin", file: "CURVY 6_DARKEST.glb" },
      ],
      shape3: [
        { label: "Pale, Ivory White Skin", file: "STRAIGHT 10_LIGHTEST.glb" },
        { label: "Beige Skin", file: "STRAIGHT 10_2ND LIGHTEST.glb" },
        { label: "Light to Medium Skin", file: "STRAIGHT 10_3RD DARKEST.glb" },
        { label: "Medium Brown Skin", file: "STRAIGHT 10_2ND DARKEST.glb" },
        { label: "Deep Brown Skin", file: "STRAIGHT 10_DARKEST.glb" },
      ],
      shape4: [
        { label: "Pale, Ivory White Skin", file: "CURVY 14_LIGHTEST.glb" },
        { label: "Beige Skin", file: "CURVY 14_2ND LIGHTEST.glb" },
        { label: "Light to Medium Skin", file: "CURVY 14_3RD DARKEST.glb" },
        { label: "Medium Brown Skin", file: "CURVY 14_2ND DARKEST.glb" },
        { label: "Deep Brown Skin", file: "CURVY 14_DARKEST.glb" },
      ],
      shape5: [
        { label: "Pale, Ivory White Skin", file: "PLUS14_LIGHTEST.glb" },
        { label: "Beige Skin", file: "PLUS14_2ND LIGHTEST.glb" },
        { label: "Light to Medium Skin", file: "PLUS14_3RD DARKEST.glb" },
        { label: "Medium Brown Skin", file: "PLUS14_2ND DARKEST.glb" },
        { label: "Deep Brown Skin", file: "PLUS14_DARKEST.glb" },
      ],
    },
    men: {
    shape1: [
      { label: "Pale, Ivory White Skin", file: "men34_LIGHTEST.glb" },
      { label: "Beige Skin", file: "men34_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "men34_3RD DARKEST.glb" },
      { label: "Medium Brown Skin", file: "men34_2ND DARKEST.glb" },
      { label: "Deep Brown Skin", file: "men34_DARKEST.glb" },
    ],
    shape2: [
      { label: "Pale, Ivory White Skin", file: "skiniest2nd_LIGHTEST.glb" },
      { label: "Beige Skin", file: "skiniest2nd_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "skiniest2nd_3RD LIGHTEST.glb" },
      { label: "Medium Brown Skin", file: "skiniest2nd_PINKISH.glb" },
      { label: "Deep Brown Skin", file: "skiniest2nd_KAYUMANGGI.glb" },
    ],
    shape3: [
      { label: "Pale, Ivory White Skin", file: "men40_LIGHTEST.glb" },
      { label: "Beige Skin", file: "men40_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "men40_3RD DARKEST.glb" },
      { label: "Medium Brown Skin", file: "men40_2ND DARKEST.glb" },
      { label: "Deep Brown Skin", file: "men40_DARKEST.glb" },
    ],
    shape4: [
      { label: "Pale, Ivory White Skin", file: "men42_LIGHTEST.glb" },
      { label: "Beige Skin", file: "men42_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "men42_3RD DARKEST.glb" },
      { label: "Medium Brown Skin", file: "men42_2ND DARKEST.glb" },
      { label: "Deep Brown Skin", file: "men42_DARKEST.glb" },
    ],
    shape5: [
      { label: "Pale, Ivory White Skin", file: "men46_LIGHTEST.glb" },
      { label: "Beige Skin", file: "men46_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "men46_3RD DARKEST.glb" },
      { label: "Medium Brown Skin", file: "men46_2ND DARKEST.glb" },
      { label: "Deep Brown Skin", file: "men46_DARKEST.glb" },
    ],
  },
    "girl-kid": {
    shape1: [
      { label: "Beige Skin", file: "girlkid_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "girlkid_3RD LIGHTEST.glb" },
      { label: "Medium Brown Skin", file: "girlkid_PINKISH.glb" },
      { label: "Deep Brown Skin", file: "girlkid_KAYUMANGGI.glb" },
    ],
  },
    "boy-kid": {
    shape1: [
      { label: "Beige Skin", file: "boykid_2ND LIGHTEST.glb" },
      { label: "Light to Medium Skin", file: "boykid_3RD LIGHTEST.glb" },
      { label: "Medium Brown Skin", file: "boykid_PINKISH.glb" },
      { label: "Deep Brown Skin", file: "boykid_KAYUMANGGI.glb" },
    ],
  },
  }[mannequinType] || {};

  const availableSkinTones = (mannequinType === "girl-kid" || mannequinType === "boy-kid") 
    ? skinToneOptions["shape1"] || [] 
    : skinToneOptions[bodyShape] || [];

  const selectedBodyShape = bodyShapeModel[bodyShape] || baseModel;

  // New model for left container based on selections
  const gownModel = {
    women: {
      shape1: {
        "LIGHTEST.glb": [
          "skiniest_lightest_LONG BLACK GOWN.glb",
          "skiniest_lightest_GREEN BALL GOWN.glb",
          "skiniest_lightest_CRISHA LONG GOWN.glb",
          "skiniest_lightest_BLUE COCKTAIL.glb",
          "skiniest_lightest_BLUE BALL GOWN.glb",
          "skiniest_lightest_BEIGE GOWN.glb"
        ],
        "2ND LIGHTEST.glb": [
          "skiniest2_BEIGE GOWN NORMAL 2ND LIGHTEST.glb",
          "skiniest2_BLACK LONG GOWN NORMAL 2ND LIGHTEST.glb",
          "skiniest2_BLUE BALL GOWN 2nd lightest skiniest.glb",
          "skiniest2_blue cocktail 2nd lightest color skinniest.glb",
          "skiniest2_CRISHA NORMAL 2ND LIGHT.glb",
          "skiniest2_GREEN BALLGOWN NORMAL 2ND LIGHT 2.glb"
        ],
        "3RD LIGHTEST.glb": [
          "skiniest3_BLUE BALL GOWN.glb",
          "skiniest3_BLACK LONG GOWN.glb",
          "skiniest3_BEIGE GOWN.glb",
          "skiniest3_CRISHA LONG GOWN.glb",
          "skiniest3_BLUE COCKTAIL.glb",
          "skiniest3_GREEN BALL GOWN.glb"
        ],
        "PINKISH.glb": [
          "skiniest4_GREEN BALL GOWN.glb",
          "skiniest4_BLACK LONG GOWN.glb",
          "skiniest4_BLUE BALL GOWN.glb",
          "skiniest4_CRISHA LONG GOWN.glb",
          "skiniest4_BEIGE GOWN.glb",
          "skiniest4_BLUE COCKTAIL.glb"
        ],
        "DARKEST (3).glb": [
          "skiniest5_BEIGE GOWN.glb",
          "skiniest5_BLACK LONG GOWN.glb",
          "skiniest5_BLUE BALL GOWN.glb",
          "skiniest5_BLUE COCKTAIL.glb",
          "skiniest5_CRISHA LONG GOWN.glb",
          "skiniest5_GREEN BALL GOWN.glb"
        ],
      },
      shape2: {
        "CURVY 6_LIGHTEST.glb": [
          "curvy6_lightest_BEIGE GOWN.glb",
          "curvy6_lightest_BLUE BALL GOWN.glb",
          "curvy6_lightest_BLUE COCKTAIL.glb",
          "curvy6_lightest_CRISHA LONG GOWN.glb",
          "curvy6_lightest_GREEN BALL GOWN.glb"
        ],
        "CURVY 6_2ND LIGHTEST.glb": [
          "curvy6_2GREEN BALL GOWN.glb",
          "curvy6_2CRISHA LONG GOWN.glb",
          "curvy6_2BLUE COCKTAIL.glb",
          "curvy6_2BLUE BALL GOWN.glb",
          "curvy6_2BEIGE GOWN.glb"
        ],
        "CURVY 6_3RD COLOR.glb": [
          "curvy6_3BLUE COCKTAIL.glb",
          "curvy6_3BEIGE GOWN.glb",
          "curvy6_3GREEN BALL GOWN.glb",
          "curvy6_3BLUE BALL GOWN.glb",
          "curvy6_3CRISHA LONG GOWN.glb"
        ],
        "CURVY 6_2ND DARKEST.glb": [
          "curvy6_4BLUE COCKTAIL.glb",
          "curvy6_4GREEN BALL GOWN.glb",
          "curvy6_4BLUE BALL GOWN.glb",
          "curvy6_4BEIGE GOWN.glb",
          "curvy6_4CRISHA LONG GOWN.glb"
        ],
        "CURVY 6_DARKEST.glb": [
          "curvy6_5BLUE BALL GOWN.glb",
          "curvy6_5GREEN BALL GOWN.glb",
          "curvy6_5BEIGE GOWN.glb",
          "curvy6_5CRISHA LONG GOWN.glb",
          "curvy6_5BLUE COCKTAIL.glb"
        ]
      },
      shape3: {
        "STRAIGHT 10_LIGHTEST.glb": [
          "straight10_1BLUE COCKTAIL.glb",
          "straight10_1GREEN BALL GOWN.glb",
          "straight10_1CRISHA LONG GOWN.glb",
          "straight10_1BEIGE GOWN.glb",
          "straight10_1BLUE BALL GOWN.glb"
        ],
        "STRAIGHT 10_2ND LIGHTEST.glb": [
          "straight10_2BLUE COCKTAIL.glb",
          "straight10_2CRISHA LONG GOWN.glb",
          "straight10_2GREEN BALL GOWN.glb",
          "straight10_2BEIGE GOWN.glb"
        ],
        "STRAIGHT 10_3RD DARKEST.glb": [
          "straight10_3CRISHA LONG GOWN.glb",
          "straight10_3BEIGE GOWN.glb",
          "straight10_3GREEN GOWN.glb",
          "straight10_3BLUE COCKTAIL.glb"
        ],
        "STRAIGHT 10_2ND DARKEST.glb": [
          "straight10_4BEIGE GOWN.glb",
          "straight10_4BLUE COCKTAIL.glb",
          "straight10_4CRISHA LONG GOWN.glb",
          "straight10_4GREEN BALL GOWN.glb"
        ],
        "STRAIGHT 10_DARKEST.glb": [
          "straight10_5BLUE COCKTAIL.glb",
          "straight10_5BEIGE GOWN.glb",
          "straight10_5GREEN BALL GOWN.glb",
          "straight10_5CRISHA LONG GOWN.glb"
        ],
      },
      shape4: {
        "CURVY 14_LIGHTEST.glb": [
          "curvy14_1GREEN BALLGOWN.glb",
          "curvy14_1CRISHA LONG GOWN.glb",
          "curvy14_1BLUE COCKTAIL.glb",
          "curvy14_1BLUE BALL GOWN.glb",
          "curvy14_1BEIGE GOWN.glb"
        ],
        "CURVY 14_2ND LIGHTEST.glb": [
          "curvy14_2CRISHA LONG GOWN.glb",
          "curvy14_2BLUE BALL GOWN.glb",
          "curvy14_2GREEN BALL GOWN.glb",
          "curvy14_2BEIGE GOWN.glb",
          "curvy14_2BLUE COCKTAIL.glb"
        ],
        "CURVY 14_3RD DARKEST.glb": [
          "curvy14_3BEIGE GOWN.glb",
          "curvy14_3BLUE BALL GOWN.glb",
          "curvy14_3BLUE COCKTAIL.glb",
          "curvy14_3CRISHA LONG GOWN.glb",
          "curvy14_3GREEN BALL GOWN.glb"
        ],
        "CURVY 14_2ND DARKEST.glb": [
          "curvy14_4GREEN BALL GOWN.glb",
          "curvy14_4CRISHA BALL GOWN.glb",
          "curvy14_4BLUE COCKTAIL.glb",
          "curvy14_4BLUE BALL GOWN.glb",
          "curvy14_4BEIGE GOWN.glb"
        ],
        "CURVY 14_DARKEST.glb": [
          "curvy14_5CRISHA LONG GOWN.glb",
          "curvy14_5BLUE BALL GOWN.glb",
          "curvy14_5GREEN BALL GOWN.glb",
          "curvy14_5BEIGE GOWN.glb",
          "curvy14_5BLUE COCKTAIL.glb"
        ],
      },
      shape5: {
        "PLUS14_LIGHTEST.glb": [
          "plus14_1BEIGE GOWN.glb",
          "plus14_1BLUE COCKTAIL.glb",
          "plus14_1CRISHA LONG GOWN.glb",
          "plus14_1GREEN BALL GOWN.glb"
        ],
        "PLUS14_2ND LIGHTEST.glb": [
          "plus14_2BLUE COCKTAIL.glb",
          "plus14_2GREEN BALL GOWN.glb",
          "plus14_2CRISHA LONG GOWN.glb",
          "plus14_2BEIGE GOWN.glb"
        ],
        "PLUS14_3RD DARKEST.glb": [
          "plus14_3BEIGE GOWN.glb",
          "plus14_3GREEN BALL GOWN.glb",
          "plus14_3BLUE COCKTAIL.glb",
          "plus14_3CRISHA LONG GOWN.glb"
        ],
        "PLUS14_2ND DARKEST.glb": [
          "plus14_4BLUE COCKTAIL.glb",
          "plus14_4GREEN BALL GOWN.glb",
          "plus14_4BEIGE GOWN.glb",
          "plus14_4CRISHA LONG GOWN.glb"
        ],
        "PLUS14_DARKEST.glb": [
          "plus14_5BLUE COCKTAIL.glb",
          "plus14_5CRISHA LONG GOWN.glb",
          "plus14_5GREEN BALL GOWN.glb",
          "plus14_5BEIGE GOWN.glb"
        ],
      },
    },

    "girl-kid": {
        shape1: {
          "girlkid_2ND LIGHTEST.glb": [
            "girlblue_1LIGHTEST (1).glb"
          ],
          "girlkid_3RD LIGHTEST.glb": [
            "girlblue_23RD DARKEST (1).glb"
          ],
          "girlkid_PINKISH.glb": [
            "girlblue_32ND DARKEST (1).glb"
          ],
          "girlkid_KAYUMANGGI.glb": [
            "girlblue_4DARKEST (1).glb"
          ],
        }
      },

      "boy-kid": {
        shape1: {
          "boykid_2ND LIGHTEST.glb": [
            "boy1_LIGHTEST (2).glb"
          ],
          "boykid_3RD LIGHTEST.glb": [
            "boy2_3RD DARKEST (2).glb"
          ],
          "boykid_PINKISH.glb": [
            "boy3_2ND DARKEST (2).glb"
          ],
          "boykid_KAYUMANGGI.glb": [
            "boy4_DARKEST (2).glb"
          ]
        }
      },

      men: {
        shape1: {
          "men34_LIGHTEST.glb": [
            "men34_1BEIGE SUIT.glb",
            "men34_1BLUE SUIT.glb",
            "men34_1BROWN SUIT.glb",
            "men34_1GREY BARONG.glb",
            "men34_1GREY SUIT.glb",
            "men34_1PURPLE BARONG.glb"
          ],
          "men34_2ND LIGHTEST.glb": [
            "men34_2BEIGE COAT.glb",
            "men34_2BLUE COAT.glb",
            "men34_2BROWN SUIT.glb",
            "men34_2GREY BARONG.glb",
            "men34_2GREY COAT.glb",
            "men34_2PURPLE BARONG.glb"
          ],
          "men34_3RD DARKEST.glb": [
            "men34_3BEIGE COAT.glb",
            "men34_3BLUE COAT.glb",
            "men34_3BROWN SUIT.glb",
            "men34_3GREY BARONG.glb",
            "men34_3GREY SUIT.glb",
            "men34_3PURPLE BARONG.glb"
          ],
          "men34_2ND DARKEST.glb": [
            "men34_4BEIGE COAT.glb",
            "men34_4BLUE COAT.glb",
            "men34_4BROWN SUIT.glb",
            "men34_4GREY BARONG.glb",
            "men34_4GREY COAT.glb",
            "men34_4PURPLE BARONG.glb"
          ],
          "men34_DARKEST.glb": [
            "men34_5BEIGE COAT.glb",
            "men34_5BLUE COAT.glb",
            "men34_5BROWN SUIT.glb",
            "men34_5GREY BARONG.glb",
            "men34_5GREY COAT.glb",
            "men34_5PURPLE BARONG.glb"
          ],
        },
        shape2: {
          "skiniest2nd_LIGHTEST.glb": [
            "skiniest_1BLUE SUIT.glb",
            "skiniest_1BROWN SUIT.glb",
            "skiniest_1GREY BARONG.glb",
            "skiniest_1GREY SUIT.glb",
            "skiniest_1PURPLE BARONG.glb",
            "skiniest_1WHITE BARONG.glb"
          ],
          "skiniest2nd_2ND LIGHTEST.glb": [
            "skiniest_2BEIGE COAT NORMAL 2ND LIGHT SKIN2.glb",
            "skiniest_2BLUE COAT NORMAL 2ND LIGHT SKIN.glb",
            "skiniest_2BROWN COAT NORMAL 2ND LIGHT.glb",
            "skiniest_2GREY BARONG NORMAL 2ND LIGHT SKIN 2.glb",
            "skiniest_2GREY COAT NORMAL 2ND LIGHT SKIN.glb",
            "skiniest_2PURPLE BARONG NORMAL 2ND LIGHT.glb",
            "skiniest_2WHITE BARONG NORMAL 2ND LIGHT.glb"
          ],
          "skiniest2nd_3RD LIGHTEST.glb": [
            "skiniest_3BEIGE COAT.glb",
            "skiniest_3BLUE COAT.glb",
            "skiniest_3BROWN SUIT.glb",
            "skiniest_3GREY BARONG.glb",
            "skiniest_3PURPLE BARONG.glb"
          ],
          "skiniest2nd_PINKISH.glb": [
            "skiniest_4BEIGE COAT.glb",
            "skiniest_4BLUE COAT.glb",
            "skiniest_4BROWN SUIT.glb",
            "skiniest_4GREY BARONG.glb",
            "skiniest_4GREY COAT.glb",
            "skiniest_4PURPLE BARONG.glb"
          ],
          "skiniest2nd_KAYUMANGGI.glb": [
            "skiniest_5BEIGE COAT.glb",
            "skiniest_5BLUE COAT.glb",
            "skiniest_5BROWN SUIT.glb",
            "skiniest_5GREY BARONG.glb",
            "skiniest_5GREY COAT.glb",
            "skiniest_5PURPLE BARONG.glb"
          ],
        },
        shape3: {
          "men40_LIGHTEST.glb": [
            "men40_1BEIGE COAT.glb",
            "men40_1BLUE COAT.glb",
            "men40_1BROWN SUIT.glb",
            "men40_1GREY BARONG.glb",
            "men40_1GREY SUIT.glb",
            "men40_1PURPLE BARONG.glb"
          ],
          "men40_2ND LIGHTEST.glb": [
            "men40_2BEIGE COAT.glb",
            "men40_2BLUE COAT.glb",
            "men40_2BROWN SUIT.glb",
            "men40_2GREY BARONG.glb",
            "men40_2GREY COAT.glb",
            "men40_2PURPLE BARONG.glb"
          ],
          "men40_3RD DARKEST.glb": [
            "men40_3BEIGE COAT.glb",
            "men40_3BLUE COAT.glb",
            "men40_3BROWN SUIT.glb",
            "men40_3GREY BARONG.glb",
            "men40_3GREY COAT.glb",
            "men40_3PURPLE BARONG.glb"
          ],
          "men40_2ND DARKEST.glb": [
            "men40_4BEIGE BARONG.glb",
            "men40_4BLUE SUIT.glb",
            "men40_4BROWN SUIT.glb",
            "men40_4GREY BARONG.glb",
            "men40_4GREY COAT.glb",
            "men40_4PURPLE BARONG.glb"
          ],
          "men40_DARKEST.glb": [
            "men40_5BEIGE COAT.glb",
            "men40_5BLUE COAT.glb",
            "men40_5BROWN SUIT.glb",
            "men40_5GREY BARONG.glb",
            "men40_5GREY COAT.glb",
            "men40_5PURPLE BARONG.glb"
          ],
        },
        shape4: {
          "men42_LIGHTEST.glb": [
            "men42_1BEIGE COAT.glb",
            "men42_1BLUE COAT.glb",
            "men42_1BROWN SUIT.glb",
            "men42_1GREY BARONG.glb",
            "men42_1GREY SUIT.glb",
            "men42_1PURPLE BARONG.glb"
          ],
          "men42_2ND LIGHTEST.glb": [
            "men42_2BEIGE COAT.glb",
            "men42_2BLUE COAT.glb",
            "men42_2BROWN SUIT.glb",
            "men42_2GREY BARONG.glb",
            "men42_2GREY COAT.glb",
            "men42_2PURPLE BARONG.glb"
          ],
          "men42_3RD DARKEST.glb": [
            "men42_3BEIGE COAT.glb",
            "men42_3BLUE SUIT.glb",
            "men42_3BROWN SUIT.glb",
            "men42_3GREY BARONG.glb",
            "men42_3GREY COAT.glb",
            "men42_3PURPLE BARONG.glb"
          ],
          "men42_2ND DARKEST.glb": [
            "men42_4BEIGE BARONG.glb",
            "men42_4BLUE COAT.glb",
            "men42_4BROWN SUIT.glb",
            "men42_4GREY BARONG.glb",
            "men42_4GREY COAT.glb",
            "men42_4PURPLE BARONG.glb"
          ],
          "men42_DARKEST.glb": [
            "men42_5BEIGE COAT.glb",
            "men42_5BLUE COAT.glb",
            "men42_5BROWN SUIT.glb",
            "men42_5GREY BARONG.glb",
            "men42_5GREY COAT.glb",
            "men42_5PURPLE BARONG.glb"
          ],
        },
        shape5: {
          "men46_LIGHTEST.glb": [
            "men46_1BEIGE COAT.glb",
            "men46_1BLUE COAT.glb",
            "men46_1BROWN SUIT.glb",
            "men46_1GREY BARONG.glb",
            "men46_1GREY COAT.glb",
            "men46_1PURPLE BARONG.glb"
          ],
          "men46_2ND LIGHTEST.glb": [
            "men46_2BEIGE COAT.glb",
            "men46_2BLUE COAT.glb",
            "men46_2BROWN SUIT.glb",
            "men46_2GREY BARONG.glb",
            "men46_2GREY COAT.glb",
            "men46_2PURPLE BARONG.glb"
          ],
          "men46_3RD DARKEST.glb": [
            "men46_3BEIGE COAT.glb",
            "men46_3BLUE COAT.glb",
            "men46_3BROWN SUIT.glb",
            "men46_3GREY BARONG.glb",
            "men46_3GREY COAT.glb",
            "men46_3PURPLE BARONG.glb"
          ],
          "men46_2ND DARKEST.glb": [
            "men46_4BEIGE COAT.glb",
            "men46_4BLUE COAT.glb",
            "men46_4BROWN SUIT.glb",
            "men46_4GREY BARONG.glb",
            "men46_4GREY COAT.glb",
            "men46_4PURPLE BARONG.glb"
          ],
          "men46_DARKEST.glb": [
            "men46_5BEIGE COAT.glb",
            "men46_5BLUE COAT.glb",
            "men46_5BROWN SUIT.glb",
            "men46_5GREY BARONG.glb",
            "men46_5GREY COAT.glb",
            "men46_5PURPLE BARONG.glb"
          ],
        },
      },
  }
  
  [mannequinType] || {};

  const selectedGownModel = gownModel[bodyShape] ? gownModel[bodyShape][skinTone] || [] : [];
  const currentGown = selectedGownModel[currentGownIndex] || "";

  // New function to handle the select button click
  const handleSelectClick = () => {
    if (mannequinType && skinTone && bodyShape) {
      setShowGownModel(true);
      setShowRightContainer(false); // Hide right container
    }
  };

  // Function to handle next gown
  const handleNextGown = () => {
    if (currentGownIndex < selectedGownModel.length - 1) {
      setCurrentGownIndex(currentGownIndex + 1);
    }
  };

  // Function to handle previous gown
  const handlePreviousGown = () => {
    if (currentGownIndex > 0) {
      setCurrentGownIndex(currentGownIndex - 1);
    }
  };


  useEffect(() => {
    console.log("Mannequin Type: ", mannequinType);
    //fetch images from firebase storage in 3d_images/mannequinType
    let folder='';
    if(mannequinType==='women'){
      folder='WOMEN';
    }else if(mannequinType==='men'){
      folder="MEN"
    }else if(mannequinType==='girl-kid'){
      folder="GIRL KID"
    }else if(mannequinType==='boy-kid'){
      folder="BOY KID"
    }else{
      return
    }
    const storageRef = ref(storage, `3d_images/${folder}`);
    let images = [];
    listAll(storageRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            images.push({name:itemRef.name.split('.')[0],url:url});
          });
        });
        setImages(images);
      })
      .catch((error) => {
        console.log(error);
      }
      );
  }, [mannequinType])

  function getImage(gown) {
    console.log(gown);

    switch (mannequinType) {
        case 'women':
            return getWomenGownImage(gown);
        case 'men':
            return getMenGownImage(gown);
        case 'girl-kid':
        case 'boy-kid':
            return getKidGownImage();
        default:
            return null; // Handle unexpected mannequin types if necessary
    }
}

function getWomenGownImage(gown) {
    if (gown.includes('BLACK')) {
        return images.find(image => image.name === 'BLACK LONG GOWN').url;
    } else if (gown.includes('BEIGE')) {
        return images.find(image => image.name === 'BEIGE GOWN').url;
    } else if (gown.includes('GREEN')) {
        return images.find(image => image.name === 'GREEN BALL GOWN').url;
    } else if (gown.includes('CRISHA')) {
        return images.find(image => image.name === 'CRISHA LONG GOWN').url;
    } else if (gown.includes('COCKTAIL')) {
        return images.find(image => image.name === 'BLUE COCKTAIL').url;
    } else {
        return images.find(image => image.name === 'BLUE BALL GOWN').url;
    }
}

function getMenGownImage(gown) {
    if (gown.includes('BEIGE')) {
        return images.find(image => image.name === 'BEIGE SUIT').url;
    } else if (gown.includes('BLUE')) {
        return images.find(image => image.name === 'BLUE SUIT').url;
    } else if (gown.includes('WHITE')) {
        return images.find(image => image.name === 'WHITE BARONG').url;
    } else if (gown.includes('BROWN')) {
        return images.find(image => image.name === 'BROWN SUIT').url;
    } else if (gown.includes('PURPLE')) {
        return images.find(image => image.name === 'PURPLE BARONG').url;
    } else if (gown.includes('GREY SUIT')) {
        console.log("DAWg", gown);
        return images.find(image => image.name === 'GREY SUIT').url;
    } else if (gown.includes('GREY COAT')) {
        console.log("DAWg", gown);
        return images.find(image => image.name === 'GREY COAT').url;
    } else {
        return images.find(image => image.name === 'GREY BARONG').url;
    }
}

function getKidGownImage() {
    return images[0].url; // Assuming the same image for both girl-kid and boy-kid
}

// Function to map gown keywords to specific tooltip names
function getTooltipName(gown) {
  if (gown.includes('BLACK')) {
      return 'Elegant Black Long Gown';
  } else if (gown.includes('BEIGE') && gown.includes('GOWN')) {
      return 'Classic Beige Gown';
  } else if (gown.includes('GREEN')) {
      return 'Emerald Green Ball Gown';
  } else if (gown.includes('CRISHA')) {
      return 'Crisha Couture Long Gown';
  } else if (gown.includes('COCKTAIL')) {
      return 'Stylish Blue Cocktail Dress';
  } else if (gown.includes('BLUE') && gown.includes('BALL')) {
      return 'Royal Blue Ball Gown';
  } else if (gown.includes('BEIGE') && gown.includes('BEIGE')) {
      return 'Beige Gentleman Suit';
  } else if (gown.includes('BLUE') && gown.includes('BLUE')) {
      return 'Navy Blue Suit';
  } else if (gown.includes('WHITE') && gown.includes('BARONG')) {
      return 'Traditional White Barong';
  } else if (gown.includes('BROWN') && gown.includes('SUIT')) {
      return 'Classic Brown Suit';
  } else if (gown.includes('PURPLE') && gown.includes('BARONG')) {
      return 'Royal Purple Barong';
  } else if (gown.includes('GREY') && gown.includes('SUIT')) {
      return 'Sophisticated Grey Suit';
  } else if (gown.includes('GREY') && gown.includes('COAT')) {
      return 'Grey Formal Coat';
  } else if (gown.includes('GREY') && gown.includes('BARONG')) {
      return 'Elegant Grey Barong';
  } else {
      return 'Charming Kid Attire'; // For the kids’ attire
  }
}


  return (
    <div className="">
      <Navbar2 />
      <h1 className="text-black text-6xl font-bold mb-6 mt-40 text-center">Choose Your Mannequin</h1>
      <div className="flex justify-center items-center">
        <div className="bg-white border border-black border-solid p-6 rounded-lg shadow-lg w-full max-w-lg">
          <div className="mb-4">
            <label className="text-black font-bold">Select Mannequin Type: </label>
            <select
              value={mannequinType}
              onChange={(e) => setMannequinType(e.target.value)}
              className="block border border-black w-full mt-1 p-2 rounded-md bg-white text-black"
            >
              <option value="">-- Select --</option>
              <option value="women">Women</option>
              <option value="men">Men</option>
              <option value="girl-kid">Girl Kid</option>
              <option value="boy-kid">Boy Kid</option>
            </select>
          </div>

          {(mannequinType === "women" || mannequinType === "men") && (
            <div className="mb-4">
              <label className="text-black font-bold">Select Body Shape: </label>
              <select onChange={handleBodyShapeChange} className="block border border-black border-solid w-full mt-1 p-2 rounded-md bg-white text-black">
                <option value="">-- Select --</option>
                <option value="shape1" title={mannequinType === "women" ? "Extra Small = Bust 31 to 33 in - Waist 24 to 26 in - Hip 31 to 33 in" : "Extra Small = Chest 34 to 36 in - Waistline 26 to 27 in"}>Extra Small</option>
                <option value="shape2" title={mannequinType === "women" ? "Small = Bust 34 to 36 in - Waist 27 to 29 in - Hip 34 to 36" : "Small = Chest 37 to 39 in - Waistline 28 to 30 in"}>Small</option>
                <option value="shape3" title={mannequinType === "women" ? "Medium = Bust 36 to 38 in - Waist 30 to 32 in - Hip 37 to 39" : "Medium = Chest 40 to 42 in  - Waistline 31 to 34 in"}>Medium</option>
                <option value="shape4" title={mannequinType === "women" ? "Large = Bust 39 to 41 in - Waist 33 to 36 in - Hip 38 to 40" : "Large = Chest 43 to 45 in - Waistline 35 to 38 in"}>Large</option>
                <option value="shape5" title={mannequinType === "women" ? "Extra Large = Bust 42 to 44 in - Waist 37 to 39 in - Hip 41 to 43" : "Extra Large = Chest 46 to 48 in- Waistline 39 to 41 in"}>Extra Large</option>
              </select>
            </div>
          )}

          {mannequinType === "girl-kid" && (
            <div className="mb-4">
              <label className="text-black font-bold">Select Body Shape: </label>
              <select onChange={handleBodyShapeChange} className="block border border-black border-solid w-full mt-1 p-2 rounded-md bg-white text-black">
                <option value="">-- Select --</option>
                <option value="shape1" title="5 to 8 Years old">Kids</option>
              </select>
            </div>
          )}

          {mannequinType === "boy-kid" && (
            <div className="mb-4">
              <label className="text-black font-bold">Select Body Shape: </label>
              <select onChange={handleBodyShapeChange} className="block border border-black border-solid w-full mt-1 p-2 rounded-md bg-white text-black">
                <option value="">-- Select --</option>
                <option value="shape1" title="5 to 8 Years old">Kids</option>
              </select>
            </div>
          )}

          {(bodyShape || (mannequinType === "girl-kid" || mannequinType === "boy-kid")) && (
            <div className="mb-4">
              <label className="text-black font-bold">Select Skin Tone: </label>
              <select onChange={handleSkinToneChange} className="block border border-black border-solid w-full mt-1 p-2 rounded-md bg-white text-black">
                <option value="">-- Select --</option>
                {availableSkinTones.map((tone, index) => (
                  <option key={index} value={tone.file}>
                    {`SKIN TONE ${index + 1} : ${tone.label}`}
                  </option>
                ))}
              </select>
            </div>
          )}


          {/* Centered Refresh button */}
          <div className="flex justify-center mt-4">
            <button
              onClick={refreshModel}
              className="bg-[#ffd000] hover:bg-black border border-solid border-black text-black hover:text-white transition py-2 px-4 rounded-full flex items-center"
              title="Refresh Model"
            >
              <ArrowPathIcon className="h-5 w-5 mr-2" />
              Refresh
            </button>
          </div>
        </div>
      </div>


      <div className="mt-6 flex justify-center gap-4 w-full max-w-lg mx-auto"> {/* Flex container centered for two model sections */}
        {/* Right container */}
        {showRightContainer && (
          <div className="p-4 bg-gray-200 border border-black border-solid rounded-lg shadow-lg w-full aspect-[1] h-96 flex items-center justify-center"> 
            {mannequinType && skinTone && (
              <model-viewer
                src={`/assets2/all skin tones/${skinTone}`}
                alt="Skin tone"
                ar
                auto-rotate
                camera-controls
                style={{ width: "100%", height: "100%" }} // Fill the container
              ></model-viewer>
            )}
            {mannequinType && !skinTone && (
              <model-viewer
                src={`/assets2/Base_Model/${selectedBodyShape}`}
                alt="3D mannequin"
                ar
                auto-rotate
                camera-controls
                style={{ width: "100%", height: "100%" }} // Fill the container
              ></model-viewer>
            )}
          </div>
        )}

      {/* Left container, only show when selections are made and button is clicked */}
        {showGownModel && mannequinType && bodyShape && skinTone && (
          <div className="p-4 bg-gray-200 border border-black border-solid rounded-lg shadow-lg w-full aspect-[1] h-96 flex items-center justify-center"> 
            <model-viewer
              src={`/assets2/GownsAndSuits/${currentGown}`} // Update the path as needed
              alt="Gown mannequin"
              ar
              auto-rotate
              camera-controls
              style={{ width: "100%", height: "100%" }} // Fill the container
            ></model-viewer>
          </div>
        )}
      </div>


      {/* Button to select and show the left container, only show when gown model is not displayed */}
      {!showGownModel && (
        <div className="flex justify-center mt-4 mb-40"> {/* Flex container to center the button */}
          <button 
            onClick={handleSelectClick} 
            className="pt-2 pb-2 pr-5 pl-5 border border-black border-solid bg-white hover:bg-[#ffd000] transition text-black rounded-md"
            disabled={!mannequinType || !skinTone || !bodyShape} // Disable button if selections are not made
          >
            Select
          </button>
        </div>
      )}


      {/* Next and Previous buttons for gown selection */}
      {showGownModel && (
        <div className="flex justify-between w-full max-w-lg mt-4 ml-[694px]">
          <button 
            onClick={handlePreviousGown} 
            className="p-2 border border-black border-solid bg-white hover:bg-[#ffd000] text-black rounded-md"
            disabled={currentGownIndex === 0} // Disable if on the first gown
          >
          <i className="fas fa-chevron-left"></i> {/* Left arrow icon */}
          </button>
          <button 
            onClick={() => {
            setSelectedGown(currentGown);
            openModal(); // Open the modal immediately after confirming
            }}
            className="mt-2 pt-2 pb-2 pl-7 pr-7 border border-black border-solid hover:bg-black hover:text-white bg-[#ffd000] first-letter: text-black rounded-md"
            >
            CONFIRM
          </button>
          <button 
            onClick={handleNextGown} 
            className="p-2 bg-white border border-black border-solid hover:bg-[#ffd000] text-black rounded-md"
            disabled={currentGownIndex >= selectedGownModel.length - 1} // Disable if on the last gown
          >
          <i className="fas fa-chevron-right"></i> {/* Right arrow icon */}
          </button>
        </div>
      )}

      {showGownModel && (
        <div className="w-full flex justify-center items-center mt-10 mb-40">
          <div className="w-full max-w-lg">
            <h2 className="text-black text-2xl font-bold mb-4 text-center">Available Attires</h2>
            <div className="grid grid-cols-3 gap-4">
              {selectedGownModel.map((gown, index) => (
                <div
                  key={index}
                  className="bg-white border border-black border-solid p-4 rounded-lg text-white text-center cursor-pointer transition-transform transform hover:scale-105"
                  onClick={() => setCurrentGownIndex(index)}
                >
                  <img
                    src={getImage(gown)}
                    alt="attire"
                    title={getTooltipName(gown)} // Custom tooltip name here
                    className="w-full h-32 object-cover rounded-md mb-2"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}



      {isModalOpen && selectedGown && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-w-5xl w-full relative">
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>

            <model-viewer
              src={`/assets2/GownsAndSuits/${selectedGown}`}
              alt="Selected gown"
              ar
              auto-rotate
              camera-controls
              ref={modelViewerRef} // Ref to the modal model viewer
              style={{ width: "100%", height: "600px" }}
            ></model-viewer>

            {/* Download Button */}
            <button
              onClick={handleDownloadImage}
              className="absolute bottom-4 right-4 p-2 bg-blue-600 text-white rounded-md"
            >
              Download <i className="fas fa-download"></i>
            </button>
          </div>
        </div>
      )}

      <Footer/>

    </div>
  );
};

export default MannequinSelector;