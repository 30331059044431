import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar';
import logo from '../assets/logo.png';
import { collection, getDocs, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../pages/firebase'; // Import Firebase Auth
import { createUserWithEmailAndPassword } from 'firebase/auth'; // Import Firebase Auth for user creation
import UserDetailModal from './UserDetailModal';
import StaffDetailModal from './StaffDetailModal'; // Import StaffDetailModal

const ManagementDashboard = () => {
  const [userList, setUserList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [pendingAccounts, setPendingAccounts] = useState([]); // State for pending accounts
  const [newStaff, setNewStaff] = useState({ name: '', email: '', password: '' });
  const [userError, setUserError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null); // New state for selected staff
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchUsersAndStaff = async () => {
      setLoading(true);
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
    
        // Fetching approved users
        const usersData = usersSnapshot.docs
          .map(doc => ({ ...doc.data(), id: doc.id }))
          .filter(user => user.role === 'user' && user.status === 'approved');
        setUserList(usersData);
    
        // Fetching pending accounts
        const pendingData = usersSnapshot.docs
          .map(doc => ({ ...doc.data(), id: doc.id }))
          .filter(user => user.role === 'user' && user.status === 'pending');
        setPendingAccounts(pendingData);
    
        // Fetching staff
        const staffData = usersSnapshot.docs
          .map(doc => ({ ...doc.data(), id: doc.id }))
          .filter(user => user.role === 'staff');
        setStaffList(staffData);
    
      } catch (error) {
        console.error('Error fetching data:', error);
        setUserError('Failed to load users or staff.');
      } finally {
        setLoading(false);
      }
    };
    fetchUsersAndStaff();
  }, []);

  const handleAddStaff = async () => {
    const { name, email, password } = newStaff;

    if (!name || !email || !password) {
      setUserError('Name, email, and password are required.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        displayName: name,
        email: user.email,
        role: 'staff',
      });

      setStaffList([...staffList, { displayName: name, email, id: user.uid }]);
      setNewStaff({ name: '', email: '', password: '' });
      setUserError('');

    } catch (error) {
      console.error('Error adding staff:', error);
      setUserError('Failed to add staff.');
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteDoc(doc(db, 'users', id));
      setUserList(userList.filter(user => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
      setUserError('Failed to delete user.');
    }
  };

  const handleDeleteStaff = async (id) => {
    try {
      await deleteDoc(doc(db, 'users', id));
      setStaffList(staffList.filter(staff => staff.id !== id));
    } catch (error) {
      console.error('Error deleting staff:', error);
      setUserError('Failed to delete staff.');
    }
  };

  // Updated handleApproveUser function
  const handleApproveUser = async (id) => {
    try {
      // Directly update the user status to approved
      await setDoc(doc(db, 'users', id), { 
        status: 'approved' 
      }, { merge: true });
      
      const approvedUser = pendingAccounts.find(account => account.id === id);
      setUserList([...userList, { 
        ...approvedUser, 
        status: 'approved' 
      }]);
      setPendingAccounts(pendingAccounts.filter(account => account.id !== id));
    } catch (error) {
      console.error('Error approving user:', error);
      setUserError('Failed to approve user.');
    }
  };

  const handleDenyUser = async (id) => {
    try {
      await deleteDoc(doc(db, 'users', id));
      setPendingAccounts(pendingAccounts.filter(account => account.id !== id));
    } catch (error) {
      console.error('Error denying user:', error);
      setUserError('Failed to deny user.');
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const handleStaffClick = (staff) => {
    setSelectedStaff(staff); // Set selected staff
  };

  const closeModal = () => {
    console.log("Modal close button clicked."); // This should log when the button is clicked
    setSelectedUser(null);
    setSelectedStaff(null);
  };
  
  return (
    <div className="flex min-h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-8 ml-64">
        <header className="flex items-center mb-8">
          <img src={logo} alt="Amarabelliana's Boutique Logo" className="w-16 h-16 mr-4 rounded-full border border-gray-300" />
          <h1 className="text-4xl font-bold text-gray-800">Amarabelliana's Boutique</h1>
        </header>

        {loading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <>
            {/* Pending Accounts Management */}
            <div className="bg-white p-6 rounded-lg shadow mb-8">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">Pending Accounts</h2>
              <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
                <thead>
                  <tr className="border-b border-gray-300">
                    <th className="p-4 text-left text-gray-600">Name</th>
                    <th className="p-4 text-left text-gray-600">Email</th>
                    <th className="p-4 text-left text-gray-600">Valid ID</th>
                    <th className="p-4 text-left text-gray-600">Remarks</th>
                    <th className="p-4 text-left text-gray-600">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingAccounts.map(account => (
                    <tr key={account.id} className="border-b border-gray-200 hover:bg-gray-50 transition">
                      <td className="p-4 text-gray-800" onClick={() => handleUserClick(account)}>
                        {account.firstName} {account.displayName}
                      </td>
                      <td className="p-4 text-gray-800">{account.email}</td>
                      <td className="p-4 text-gray-800">
                        {account.idImageUrl ? (
                          <a href={account.idImageUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                            View ID
                          </a>
                        ) : (
                          <span className="text-red-500">No ID Uploaded</span>
                        )}
                      </td>
                      <td className="p-4 text-gray-800">{account.remarks}</td>
                      <td className="p-4 flex space-x-3">
                        <button
                          onClick={() => handleApproveUser(account.id)}
                          className="text-green-500 hover:text-green-700 transition"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleDenyUser(account.id)}
                          className="text-red-500 hover:text-red-700 transition"
                        >
                          Deny
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

                    {/* User Management */}
          <div className="bg-white p-6 rounded-lg shadow mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">View Customer Details</h2>
            <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
              <thead>
                <tr className="border-b border-gray-300">
                  <th className="p-4 text-left text-gray-600">Name</th>
                  <th className="p-4 text-left text-gray-600">Email</th>
                  <th className="p-4 text-left text-gray-600">Actions</th>
                </tr>
              </thead>
              <tbody>
                {userList.map(user => (
                  <tr key={user.id} className="border-b border-gray-200 hover:bg-gray-50 transition">
                    <td className="p-4 text-gray-800" onClick={() => handleUserClick(user)}>
                      {user.firstName} {user.displayName}
                    </td>
                    <td className="p-4 text-gray-800">{user.email}</td>
                    <td className="p-4 flex space-x-3">
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="text-red-500 hover:text-red-700 transition"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

            {/* Staff Management */}
            <div className="bg-white p-6 rounded-lg shadow">
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">Manage Staff</h2>
              <table className="min-w-full bg-white border border-gray-300 rounded-lg overflow-hidden">
                <thead>
                  <tr className="border-b border-gray-300">
                    <th className="p-4 text-left text-gray-600">Name</th>
                    <th className="p-4 text-left text-gray-600">Email</th>
                    <th className="p-4 text-left text-gray-600">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {staffList.map(staff => (
                    <tr key={staff.id} className="border-b border-gray-200 hover:bg-gray-50 transition">
                      <td className="p-4 text-gray-800" onClick={() => handleStaffClick(staff)}>
                        {staff.displayName}
                      </td>
                      <td className="p-4 text-gray-800">{staff.email}</td>
                      <td className="p-4 flex space-x-3">
                        <button
                          onClick={() => handleDeleteStaff(staff.id)}
                          className="text-red-500 hover:text-red-700 transition"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="mt-6">
                <h3 className="text-lg font-semibold text-gray-700 mb-2">Add Staff</h3>
                <input
                  type="text"
                  placeholder="Name"
                  value={newStaff.name}
                  onChange={(e) => setNewStaff({ ...newStaff, name: e.target.value })}
                  className="border p-2 mb-2 w-full"
                />
                <input
                  type="email"
                  placeholder="Email"
                  value={newStaff.email}
                  onChange={(e) => setNewStaff({ ...newStaff, email: e.target.value })}
                  className="border p-2 mb-2 w-full"
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={newStaff.password}
                  onChange={(e) => setNewStaff({ ...newStaff, password: e.target.value })}
                  className="border p-2 mb-2 w-full"
                />
                {userError && <p className="text-red-500">{userError}</p>}
                <button
                  onClick={handleAddStaff}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Add Staff
                </button>
              </div>
            </div>
          </>
        )}

        {selectedUser && <UserDetailModal user={selectedUser} onClose={closeModal} />}
        {selectedStaff && <StaffDetailModal staff={selectedStaff} onClose={closeModal} />}
      </div>
    </div>
  );
};

export default ManagementDashboard;
