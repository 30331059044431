import React, { useState } from 'react';
import { questions } from './questions';
import SingleQuestion from './SingleQuestion';

const Faqs = () => {
  const [cards] = useState(questions);

  return (
    <div name='faqs' className='w-full bg-white px-4 text-black'>
      <section className='max-w-4xl mx-auto py-36 px-4'>
        <h1 className='text-center uppercase tracking-widest font-bold mb-12 text-4xl'>FAQs</h1>

        <section className='grid grid-cols-1 gap-12'>
          {cards.map((card, index) => (
            <div key={index} className='bg-gray-100 p-8 rounded-lg shadow-lg'>
              <SingleQuestion {...card} />
            </div>
          ))}
        </section>
      </section>
    </div>
  );
};

export default Faqs;
