import React, { useState, useEffect,  } from 'react';
import Message from './Message';
import { collection, onSnapshot, orderBy, query,doc } from 'firebase/firestore';
import { db,auth } from '../pages/firebase';


function Messages({ user,messages,scroll }) {

  return (
    
        <main className='flex flex-col p-4 overflow-y-auto h-[70vh]'>
        {messages && <div className='flex flex-col'>
            {messages.map((message) => (
              <Message key={message.id} message={message} currentUserId={user.uid} />
            ))}
            {/* This span will ensure the latest message is always visible at the bottom */}
            <span ref={scroll}></span>
          </div>}
        </main>
    
  );
}

export default Messages;
