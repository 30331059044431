import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../pages/firebase'; // Adjust the import based on your Firebase config
import { collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon
import Footer from './Footer';
import Navbar2 from './Navbar2';

const RefundForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [refundReason, setRefundReason] = useState('');
  const [proofOfPurchase, setProofOfPurchase] = useState(null);
  const [rentedAttireName, setRentedAttireName] = useState(''); // New state for rented attire name
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      let proofOfPurchaseUrl = '';
      if (proofOfPurchase) {
        const storage = getStorage();
        const storageRef = ref(storage, `refunds/${proofOfPurchase.name}`);
        await uploadBytes(storageRef, proofOfPurchase);
        proofOfPurchaseUrl = await getDownloadURL(storageRef);
      }

      const formData = {
        fullName,
        email,
        refundReason,
        proofOfPurchase: proofOfPurchaseUrl,
        rentedAttireName, // Include rented attire name in the form data
        dateSubmitted: new Date().toISOString(),
      };

      await addDoc(collection(db, 'refundRequests'), formData);
      setShowModal(true);
      resetForm();
    } catch (e) {
      console.error('Error submitting refund request: ', e);
      setError('Failed to submit refund request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = () => {
    setFullName('');
    setEmail('');
    setRefundReason('');
    setRentedAttireName(''); // Reset rented attire name
    setProofOfPurchase(null);
    document.getElementById('proofOfPurchase').value = '';
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col min-h-screen">
  <Navbar2 />
  <div className="flex items-center justify-center flex-grow bg-white mt-[150px] mb-[200px]">
    <div className="max-w-lg mx-auto p-6 bg-white border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center text-blue-600">Refund Request Form</h2>
      <form onSubmit={handleSubmit} className="space-y-4 -ml-[1px]">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="fullName">
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400 transition duration-200"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400 transition duration-200"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="refundReason">
            Reason for Refund
          </label>
          <textarea
            id="refundReason"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400 transition duration-200"
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            required
            rows="4" // Set rows for better visibility
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="rentedAttireName">
            Rented Attire Name
          </label>
          <input
            type="text"
            id="rentedAttireName"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400 transition duration-200"
            value={rentedAttireName}
            onChange={(e) => setRentedAttireName(e.target.value)}
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="proofOfPurchase">
            Proof of Purchase
          </label>
          <input
            type="file"
            id="proofOfPurchase"
            className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400 transition duration-200"
            onChange={(e) => setProofOfPurchase(e.target.files[0])}
          />
        </div>

        <button
          type="submit"
          className="w-full p-3 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition duration-300"
          disabled={isLoading}
        >
          {isLoading ? 'Submitting...' : 'Submit Refund Request'}
        </button>
      </form>

      {error && <div className="text-red-600 mt-4">{error}</div>}

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg">
            <h3 className="text-lg font-bold mb-4">Refund Request Submitted!</h3>
            <p>Thank you for your request, {fullName}!</p>
            <button
              className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
  <Footer />
</div>

  );
};

export default RefundForm;
