import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";
import Logo from '../assets/logo.png';
import Chat from './Chat'; // Import the Chat component
import { eventEmitter } from './EventEmitter';
import { db } from '../pages/firebase';
import { collection, query, where, getDocs,doc,getDoc,orderBy } from 'firebase/firestore'; // Import necessary Firestore functions
import { AiOutlineClose } from 'react-icons/ai';

const Navbar2 = () => {
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [userIcon, setUserIcon] = useState('');
  const [userInitial, setUserInitial] = useState('');
  const [showNotificationDropdown, setShowNotificationDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationCount, setShowNotificationCount] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false); // State for mobile menu visibility

  const auth = getAuth();

  useEffect(() => {
    const fetchUserInfo = async (user) => {
      if (user) {
        const initial = user.displayName?.charAt(0).toUpperCase();
        setUserInitial(initial);
        setUserIcon(user.photoURL || '');
      }
    };

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      await fetchUserInfo(user);
    });

    eventEmitter.on('rentalSubmitted', () => {
      setNotifications((prevNotifications) => [...prevNotifications, 'Rental information submitted successfully']);
      setShowNotificationCount(true);
    });

    return () => {
      unsubscribeAuth();
      eventEmitter.removeListener('rentalSubmitted', () => {});
    };
  }, [auth]);

  useEffect(() => {
    const fetchNotification = async () => {
      if (!auth.currentUser) return;
      const notificationQuery = query(
        collection(db, 'notifications'),
        where('userFrom', '==', auth.currentUser.uid),
        where('user', '==', 'staff'),
      );
  
      try {
        const querySnapshot = await getDocs(notificationQuery);
        let notifs = [];
        querySnapshot.forEach((doc) => {
          notifs.push(doc.data());
        });
        setNotifications(notifs);
        console.log('Notifications:', notifs);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
  
    fetchNotification();
  }, [auth]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Logout error: ', error);
      });
  };

  return (
    <div className="bg-yellow-400 p-4 flex justify-between items-center fixed top-0 w-full z-50">
      <div className="flex items-center ml-10">
        <img src={Logo} alt="Logo" style={{ width: '60px' }} />
        <div className="hidden md:flex space-x-8 pl-10"> {/* Hide on mobile */}
          <Link to="/user-dashboard" className="text-sm text-black px-4 py-2 hover:text-white transition">HOME</Link>
          <Link to="/collections2" className="text-sm text-black px-4 py-2 hover:text-white transition">COLLECTIONS</Link>
          <Link to="/package" className="text-sm text-black px-4 py-2 hover:text-white transition">PACKAGE</Link>
          <Link to="/customize-model" className="text-sm text-black px-4 py-2 hover:text-white transition">CUSTOMIZE 3D MODEL</Link>
          <Link to="/ai-generator" className="text-sm text-black px-4 py-2 hover:text-white transition">AI GENERATOR</Link>
        </div>

        {/* Hamburger Icon */}
        <button
          className="md:hidden ml-4 text-black focus:outline-none"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <i className={`fas fa-${showMobileMenu ? 'times' : 'bars'}`}></i>
        </button>

        {/* Mobile Menu */}
        {showMobileMenu && (
          <div className="absolute top-16 left-0 w-full bg-yellow-400 z-50 shadow-lg">
            <div className="flex flex-col space-y-2 p-4">
              <Link to="/user-dashboard" className="text-sm text-black hover:text-white transition">HOME</Link>
              <Link to="/collections2" className="text-sm text-black hover:text-white transition">COLLECTIONS</Link>
              <Link to="/package" className="text-sm text-black hover:text-white transition">PACKAGE</Link>
              <Link to="/customize-model" className="text-sm text-black hover:text-white transition">CUSTOMIZE 3D MODEL</Link>
              <Link to="/ai-generator" className="text-sm text-black hover:text-white transition">AI GENERATOR</Link>

              {/* Additional Links for Mobile Menu */}
              <button 
                className="text-sm text-black hover:text-white transition"
                onClick={() => setShowMessageModal(!showMessageModal)} // Toggle message modal
              >
                <i className="fas fa-envelope"></i> MESSAGES
              </button>

              <div className="relative">
                <button 
                  className="text-sm text-black hover:text-white transition"
                  onClick={() => setShowNotificationDropdown(!showNotificationDropdown)} // Toggle dropdown
                >
                  <i className="fas fa-bell"></i> NOTIFICATIONS
                  {showNotificationCount && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center w-4 h-4 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{notifications.length}</span>
                  )}
                </button>

                {/* Notification Dropdown */}
                {showNotificationDropdown && (
                  <div className="absolute right-0 mt-2 w-[300px] bg-white rounded-lg shadow-lg z-50">
                    <div className="absolute right-4 top-0 transform translate-y-[-50%] rotate-45 bg-white w-4 h-4"></div> {/* Arrow */}
                    <div className="py-2 px-4">
                      <h3 className="text-sm font-semibold">Notifications</h3>
                      <ul className="space-y-3">
                        {notifications.map((notification, index) => (
                          <li
                            key={index}
                            className={`p-3 rounded-lg shadow flex flex-col space-y-1 cursor-pointer ${
                              notification.status === 'pending'
                                ? 'bg-gray-100'
                                : notification.status === 'denied'
                                ? 'bg-red-100'
                                : 'bg-green-100'
                            }`}
                          
                          >
                            <p className="text-base font-medium text-gray-800">{notification.title}</p>
                            <p className="text-sm text-gray-600">{notification.message}</p>
                            <p className="text-xs text-gray-500">
                              {new Date(notification.created_at).toLocaleString()}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>

              <Link to="/myprofile" className="text-sm text-black hover:text-white transition">
                <i className="fas fa-user-circle mr-2"></i> MY PROFILE
              </Link>

              <button
                onClick={() => setShowLogoutModal(true)}
                className="text-sm text-black hover:text-white transition w-full text-left"
              >
                <i className="fas fa-sign-out-alt mr-2"></i> LOGOUT
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="flex space-x-6 mr-10">
        {/* Envelope Icon Link */}
        <button 
          className="text-black focus:outline-none hover:text-gray-100 mt-2"
          onClick={() => setShowMessageModal(!showMessageModal)} // Toggle message modal
        >
          <i className="fas fa-envelope"></i>
        </button>

        {/* Chat Modal */}
        {showMessageModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-end z-50 transition-all">
            <div className="bg-white max-w-[700px] w-full h-[97vh] shadow-xl p-6 flex flex-col rounded-lg mt-4 mr-4 overflow-hidden">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">Chat</h2>
                <button
                  className="text-gray-600 hover:text-gray-800"
                  onClick={() => setShowMessageModal(false)}
                >
                  <AiOutlineClose size={20} />
                </button>
              </div>
              
              {/* Single scrollable container for all chat content */}
              <div className="flex-grow overflow-y-auto">
                <Chat /> {/* Ensures Chat component doesn't have its own scroll */}
              </div>
            </div>
          </div>
        )}



        {/* Notification Icon */}
        <div className="relative mt-[8px]">
          {showNotificationCount && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{notifications.length}</span>
          )}
          <button 
            className="text-black focus:outline-none hover:text-gray-100"
            onClick={() => setShowNotificationDropdown(!showNotificationDropdown)} // Toggle dropdown
          >
            <i className="fas fa-bell"></i>
          </button>
        </div>

        {/* Notification Dropdown */}
        {showNotificationDropdown && (
          <div className="absolute right-[102px] mt-12 w-[300px] bg-white rounded-lg shadow-lg z-50">
            <div className="absolute right-4 top-0 transform translate-y-[-50%] rotate-45 bg-white w-4 h-4"></div> {/* Arrow */}
            <div className="py-2 px-4">
              <h3 className="text-sm font-semibold">Notifications</h3>
              <ul className="space-y-3">
              {notifications.map((notification, index) => (
                <li
                  key={index}
                  className={`p-3 rounded-lg shadow flex flex-col space-y-1 cursor-pointer ${
                    notification.status === 'pending'
                      ? 'bg-gray-100'
                      : notification.status === 'denied'
                      ? 'bg-red-100'
                      : 'bg-green-100'
                  }`}
                 
                >
                  <p className="text-base font-medium text-gray-800">{notification.title}</p>
                  <p className="text-sm text-gray-600">{notification.message}</p>
                  <p className="text-xs text-gray-500">
                    {new Date(notification.created_at).toLocaleString()}
                  </p>
                </li>
              ))}
            </ul>
            </div>
          </div>
        )}

        {/* User Icon */}
        <div className="flex items-center">
          <div className="relative">
            {userIcon ? (
              <img
                src={userIcon}
                alt="User"
                className="w-10 h-10 rounded-full border border-black cursor-pointer"
                onClick={() => setShowLogoutPopup(!showLogoutPopup)}
              />
            ) : (
              <div
                className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-300 cursor-pointer"
                onClick={() => setShowLogoutPopup(!showLogoutPopup)}
              >
                <span className="text-lg font-bold">{userInitial}</span>
              </div>
            )}
                      {showLogoutPopup && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50">
                <Link to="/myprofile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  <i className="fas fa-user-circle mr-2"></i>
                  My profile
                </Link>

                <Link to="/cart" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  <i className="fas fa-shopping-cart mr-2"></i>
                  My Cart
                </Link>

                <Link to="/payment-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  <i className="fas fa-credit-card mr-2"></i>
                  Payment Form
                </Link>
                <Link to="/feedback" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    <i className="fas fa-comment-alt mr-2"></i>
                     Feedback
                </Link>
                <Link to="/Refund" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
    <i className="fas fa-exchange-alt mr-2"></i>
    Refund Request
</Link>

                


                <button
                  onClick={() => setShowLogoutModal(true)}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  <i className="fas fa-sign-out-alt mr-2"></i>
                  Logout
                </button>
              </div>
            )}
        </div>
      </div>
      </div>

      {/* Logout Modal */}
      {showLogoutModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white max-w-[400px] w-full max-h-[200px] shadow-xl p-6 rounded-lg">
            <h2 className="text-lg font-bold mb-4">Logout Confirmation</h2>
            <p>Are you sure you want to logout?</p>
            <div className="mt-4 flex justify-end">
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition mr-2"
                onClick={() => setShowLogoutModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar2;
