// RefundRequestDashboard.js
import React, { useState, useEffect } from 'react';
import { db } from '../pages/firebase'; // Adjust import based on your Firebase config location
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Import the icon

const RefundRequestDashboard = () => {
  const [refundRequests, setRefundRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchRefundRequests = async () => {
      setLoading(true);
      setError('');
      try {
        const querySnapshot = await getDocs(collection(db, 'refundRequests'));
        const requests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRefundRequests(requests);
      } catch (error) {
        console.error('Error fetching refund requests:', error);
        setError('Failed to load refund requests. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRefundRequests();
  }, []);

  const handleApprove = async (requestId) => {
    try {
      await updateDoc(doc(db, 'refundRequests', requestId), { status: 'approved' });
      setRefundRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === requestId ? { ...request, status: 'approved' } : request
        )
      );
    } catch (error) {
      console.error('Error approving refund request:', error);
      setError('Failed to approve the refund request.');
    }
  };

  const handleReject = async (requestId) => {
    try {
      await updateDoc(doc(db, 'refundRequests', requestId), { status: 'rejected' });
      setRefundRequests((prevRequests) =>
        prevRequests.map((request) =>
          request.id === requestId ? { ...request, status: 'rejected' } : request
        )
      );
    } catch (error) {
      console.error('Error rejecting refund request:', error);
      setError('Failed to reject the refund request.');
    }
  };

  return (
    <div className="container mx-auto mt-6 p-6 bg-white border border-gray-300 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-center text-blue-700 mb-6">Refund Request Dashboard</h2>
      
      {error && <div className="text-red-600 mb-4 text-center">{error}</div>}

      {loading ? (
        <p className="text-center">Loading refund requests...</p>
      ) : (
        <div className="overflow-x-auto">
        <Link to="/staff-dashboard" className="absolute top-4 left-4 text-black hover:text-gray-600">
        <FaArrowLeft className="w-6 h-6" /> {/* Back icon */}
      </Link>
          <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg">
            <thead>
              <tr className="bg-blue-100 text-blue-800">
                <th className="py-4 px-6 border-b">Full Name</th>
                <th className="py-4 px-6 border-b">Email</th>
                <th className="py-4 px-6 border-b">Order ID</th>
                <th className="py-4 px-6 border-b">Refund Reason</th>
                <th className="py-4 px-6 border-b">Proof of Purchase</th>
                <th className="py-4 px-6 border-b">Status</th>
                <th className="py-4 px-6 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {refundRequests.map((request) => (
                <tr key={request.id} className="hover:bg-gray-100">
                  <td className="py-4 px-6 border-b">{request.fullName}</td>
                  <td className="py-4 px-6 border-b">{request.email}</td>
                  <td className="py-4 px-6 border-b">{request.orderId}</td>
                  <td className="py-4 px-6 border-b">{request.refundReason}</td>
                  <td className="py-4 px-6 border-b">
                    {request.proofOfPurchase ? (
                      <a
                        href={request.proofOfPurchase}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        View Proof
                      </a>
                    ) : (
                      'No Proof'
                    )}
                  </td>
                  <td className="py-4 px-6 border-b">{request.status || 'Pending'}</td>
                  <td className="py-4 px-6 border-b flex space-x-3">
                    <button
                      onClick={() => handleApprove(request.id)}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                      disabled={request.status === 'approved'}
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleReject(request.id)}
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      disabled={request.status === 'rejected'}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RefundRequestDashboard;
